import { translateTerm } from "../../../../../library/translateTerm";
import { FormDivider } from "./ViewItem/Form/FormDivider";

export const ViewEmpty: React.FC<{ id: string, title?: string }> = ({ id, title }) => {
    return <div id={`view_${id}`} className="position-relative w-100 my-2">
        {
            title
                ? <FormDivider {...{
                    id: `view_${id}_title`,
                    name: "",
                    size: "12",
                    __component: "",
                    template: "",
                    bpSizes: {},
                    type: "divider",
                    weight: 0,
                    title,
                    border: true,
                }} />
                : null
        }
        <div className="text-center my-5">{translateTerm("there's nothing here yet", true)}...</div>
    </div>
}
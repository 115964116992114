import { useState } from "react";
import { Col, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { TFormSchemeValue } from "../../../../../../../store/slices/sliceIfSettings";
import { TFormInputSimple, TFormInputValue } from "../../../../../../../types/typesForm";

export const InputColor: React.FC<{
    input: TFormInputSimple,
    schemeValue: TFormSchemeValue,
    handler: (value: TFormInputValue | undefined, noTimeout?: boolean) => void
}> = ({ input, handler, schemeValue }) => {
    const [newValue, setNewValue] = useState<string | undefined>(schemeValue.value as string)

    const control = <>
        <FormControl
            className="avatar-sm rounded-circle"
            id={input.id}
            required
            value={schemeValue.value as string || "#3A414D"}
            isValid={schemeValue.validation === "valid"}
            isInvalid={schemeValue.validation === "invalid"}
            onChange={(e) => setNewValue(e.target.value)}
            onBlur={() => handler(newValue, true)}
            disabled={input.disabled}
            type={input.type}
            maxLength={input.maxLength}
            placeholder={input.placeholder + (input.required ? " *" : "")} />

        {
            schemeValue.feedback && schemeValue.validation === "invalid"
                ? <FormControl.Feedback className="position-absolute px-2 start-0 text-center mt-0 lh-sm fs-7 text-truncate w-100" type="invalid" >
                    {schemeValue.feedback}
                </FormControl.Feedback>
                : null
        }
    </>



    return <FormGroup as={Col}
        title={input.label ? `${input.label} ${input.required ? "*" : ""}` : undefined}
        xs={input.size.replace("col-", "")}
        sm={input.bpSizes?.sm?.replace("col-sm-", "")}
        md={input.bpSizes?.md?.replace("col-md-", "")}
        lg={input.bpSizes?.lg?.replace("col-lg-", "")}
        xl={input.bpSizes?.xl?.replace("col-xl-", "")}
        xxl={input.bpSizes?.xxl?.replace("col-xxl-", "")}
        className="position-relative px-1 mb-3 d-flex align-items-center" >
        {control}
        {
            input.label
                ? <FormLabel htmlFor={input.id} className="mb-0 ms-2 text-truncate">
                    {input.label} {input.required ? <span className="text-danger">*</span> : null}
                </FormLabel>
                : null
        }
    </FormGroup>

}
import { TFormInput, TFormInputValue } from "../../types/typesForm";

export const getFormInputValue = (input: TFormInput): TFormInputValue | undefined => {
    switch (input.type) {
        case "radio":
        case "checkbox": return input.checked;
        case "file":
        case "image": return input.file;
        case "divider": return;
        default: return input.value;
    }
}
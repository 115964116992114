import { collectionFetching } from "../../store/actions/actionsExtra";
import { setCollectionStatus } from "../../store/slices/sliceData";
import { store } from "../../store/store";
import { TApiQuery } from "../../types/typesBase";
import { TApiRoute } from "../../types/typesStructure";

export const actionFetchCollection = ({ apiName, query }: { apiName: string, query: TApiQuery }) => {
    const fetchUrl = (Object.values(store.getState().sliceData.collections.apiRoutes || {}) as TApiRoute[])
        .find(item => item.storageName === apiName)?.url
    if (fetchUrl) {
        store.dispatch(setCollectionStatus({ collection: apiName, status: "init" }));
        store.dispatch(collectionFetching({
            name: apiName,
            url: fetchUrl,
            query: query,
        }))
    }
}
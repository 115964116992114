import { configureStore } from "@reduxjs/toolkit"
import createSagaMiddleware from 'redux-saga'
import sliceIfSettings from "./slices/sliceIfSettings";
import sliceData from "./slices/sliceData";

import { sagaRoot } from "./sagas/sagaRoot";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: {
        sliceIfSettings,
        sliceData,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
    devTools: process.env.NODE_ENV !== 'production'
})

sagaMiddleware.run(sagaRoot);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
import { schema } from "normalizr";
import { schemaNormalizeUserCard } from "./schemaNormalizeUserCard";
import { schemaNormalizeUserPersonals } from "./schemaNormalizeUserPersonals";
import { schemaNormalizeUserRole } from "./schemaNormalizeUserRole";
import { schemaNormalizeWorkgroup } from "./schemaNormalizeWorkgroup";

export const schemaNormalizeUser = () => {
    const userCard = schemaNormalizeUserCard();
    const userPersonals = schemaNormalizeUserPersonals();
    const userRole = schemaNormalizeUserRole();
    const workgroup = schemaNormalizeWorkgroup();

    return new schema.Entity('users', {
        userCard: userCard,
        userPersonals: userPersonals,
        role: userRole,
        workgroups: [workgroup],
        ownWorkgroups: [workgroup],
    })
}
import { PageDefault } from "./Page/PageDefault";

type TProps = {
    page: { [a: string]: React.FC }
}

export const template: TProps = {
    page: {
        default: PageDefault
    }
}
import _, { compact } from "lodash"
import { TActiveObjects } from "../../template/library/Mutation/expExec"
import { mutateDataItem } from "../../template/library/Mutation/mutateDataItem"
import { TForm, TFormInput } from "../../types/typesForm"
import { TDataItem } from "../../types/typesStoreData"
import { TFormSchemeItem, TFormSchemeValue } from "../slices/sliceIfSettings"
import { store } from "../store"
import { getFormInputValue } from "./getFormInputValue"

export const constructFormResult = (
    form: TForm,
    active: TActiveObjects,
    srcObject?: TDataItem,
    srcPath?: string): object | undefined => {

    // const active = getActives()
    // const active = {
    //     id: store.getState().sliceData.activeItems,
    //     object: _.reduce(store.getState().sliceData.activeItems, (res, val, key) => ({
    //         ...res,
    //         [key]: (() => {
    //             const item = store.getState().sliceData.collections[key]?.[val];
    //             if (!item) return
    //             const schema = schemaNormalize(`${key}Single`);
    //             return schema ? denormalize(item, schema(), store.getState().sliceData.collections) as TDataItem : undefined;
    //         })()
    //     } as { [a: string]: TDataItem }), {} as { [a: string]: TDataItem })
    // }

    const formSet = store.getState().sliceIfSettings.formSchemes[form.id]

    const getObject = (group: TFormSchemeItem[], path?: string): object | undefined => {
        const object = _.reduce(group, (gr, gv, gk) => {
            switch (gv.type) {
                case "group":
                    if (typeof gv.items === "object" && Array.isArray(gv.items)) {
                        return {
                            ...gr,
                            [gv.name]: (() => {
                                if (gv.asArray) {
                                    return compact((gv.items as TFormSchemeItem[][])
                                        .map((item, i) => getObject(item, `${path ? path + "." : ""}${gv.name}.[${i}]`)))
                                } else {
                                    return getObject(gv.items as TFormSchemeItem[], `${path ? path + "." : ""}${gv.name}`)
                                }
                            })()
                        }
                    } else {
                        return { ...gr }
                    }
                case "divider": return { ...gr }
                default:
                    const srcInput = _.get(form, gv.formPath) as TFormInput
                    const mutantInput = mutateDataItem(srcInput, active) as TFormInput;
                    if (!srcInput || !mutantInput.forSubmit) return { ...gr };
                    const valueItem = formSet.values.find(item => item.id === gv.id && item.type === gv.type) as TFormSchemeValue | undefined;
                    const value = valueItem?.value
                    const oldValue = _.get(srcObject, `${path ? path + "." : ""}${gv.name}`)
                    const mutantInputValue = getFormInputValue(mutantInput);
                    if (mutantInput.disabled) {
                        if (oldValue) {
                            return { ...gr, [gv.name]: null };
                        } else {
                            return { ...gr }
                        }
                    } else {
                        if (value) {
                            return { ...gr, [gv.name]: value };
                        } else {
                            if (mutantInputValue) {
                                return { ...gr, [gv.name]: mutantInputValue };
                            } else {
                                if (!oldValue) {
                                    return { ...gr }
                                } else {
                                    if (mutantInput.readonly) {
                                        return { ...gr, [gv.name]: oldValue };
                                    } else {
                                        return { ...gr, [gv.name]: null };
                                    }
                                }
                            }
                        }
                    }
            }
        }, {})
        if (!object || !Object.values(object).length) return;
        return object
    }

    const result = getObject(formSet.scheme, srcPath);
    if (!result || !Object.values(result).length) return;
    return result
}
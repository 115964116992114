import logoFull from "../../../assets/images/logo.svg"
import logoSmall from "../../../assets/images/logo-small.svg"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentLocale, getShowBurgerMenu, getShowSidebar, getShowUserMenu, setShowBurgerMenu, setShowSidebar } from "../../../../store/slices/sliceIfSettings";
import { MouseEventHandler } from "react";
import { HeaderLocaleMenu } from "./HeaderLocaleMenu";
import { HeaderMainMenu } from "./HeaderMainMenu";
import { RootState } from "../../../../store/store";
import { getDataItemByAttr } from "../../../../store/storeGetters";
import { TSiteProperties } from "../../../../types/typesStructure";
import { Link } from "react-router-dom";

export const SectionHeader: React.FC = () => {
    const dispatch = useDispatch();
    const showBurgerMenu = useSelector(getShowBurgerMenu);
    const showSideBar = useSelector(getShowSidebar);
    const locale = useSelector(getCurrentLocale);
    const props = useSelector((state: RootState) => getDataItemByAttr("siteProperties", "locale", locale, state)) as TSiteProperties

    const switchBurger: MouseEventHandler = (e) => {
        dispatch(setShowBurgerMenu(!showBurgerMenu));
    }
    const switchSidebar: MouseEventHandler = (e) => {
        dispatch(setShowSidebar(!showSideBar));
    }
    return <header className="bg-white border-bottom position-sticky shadow-sm sticky-top">
        <div className="container-lg d-flex position-relative">
            <div className="d-flex py-2 me-2">
                <div className="d-flex align-items-center me-auto">
                    <button
                        type="button"
                        onClick={switchBurger}
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title="Показать боковое меню"
                        className="btn text-reset text-hover-secondary ps-0 display-6 d-lg-none border-0">
                        <span className="d-flex fs-5 icon-action-menu text-secondary" />
                    </button>
                    <button
                        type="button"
                        onClick={switchSidebar}
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title="Показать боковое меню"
                        className="btn text-reset text-hover-secondary ps-0 display-6 d-none d-lg-block border-0">
                        <span className="d-flex fs-5 icon-action-menu text-secondary" />
                    </button>

                    <Link to="/" className="display-5 lh-1 text-secondary" target="_self">
                        <div className="d-md-none d-flex" data-bs-toggle="tooltip" data-bs-placement="top" style={{ height: "2rem" }}>
                            <img src={logoSmall} style={{ height: "1.5rem" }} className="my-auto" />
                        </div>
                        <div className="d-md-flex d-none" data-bs-toggle="tooltip" data-bs-placement="top" style={{ height: "3.2rem" }}>
                            <img src={logoFull} style={{ height: "2.4rem" }} className="my-auto" />
                        </div>
                    </Link>
                </div>
            </div>
            <HeaderLocaleMenu />
            {
                props?.mainMenuItems ? <div className="ms-auto d-flex"><HeaderMainMenu menuItems={props?.mainMenuItems} /></div> : null
            }
        </div>
    </header>
}
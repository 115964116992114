import { compact } from "lodash";
import { translateTerm } from "../../library/translateTerm";
import { TFormInputSelectOption } from "../../types/typesForm";

export const getTitleByValue = (selectorValue: string | number | (string | number)[], selectorOptions: TFormInputSelectOption[]) => {
    const valuesCollection = Array.isArray(selectorValue)
        ? selectorValue
        : typeof selectorValue === "number"
            ? [selectorValue]
            : selectorValue.split(";");

    const checkOptions = (options: TFormInputSelectOption[]): string[] => {
        return options.reduce((res, val, key) => {
            const title = valuesCollection.findIndex(item => val.value === item) !== -1 ? val.label : undefined
            const subTitles = val.subOptions ? checkOptions(val.subOptions) : undefined
            return [...res, title, ...(subTitles || [])] as string[]
        }, [] as string[])
    }
    return compact(checkOptions(selectorOptions)).join("; ");
}
import { getAction } from "../../../../../../../actions/getAction"
import { translateTerm } from "../../../../../../../library/translateTerm"
import { TContentItem } from "../../../../../../../types/typesStoreData"
import { TUser, TUserCard } from "../../../../../../../types/typesUser"
import { TWGCard, TWorkGroup } from "../../../../../../../types/typesWorkGroup"
import { getImageUrl } from "../../../../../../library/getImageUrl"
import { TPageHeaderViewItem } from "./ItemPageHeader"

export const getItemPageHeader = (contentItem?: TContentItem, type?: string): TPageHeaderViewItem => {
    const placeHolder: TPageHeaderViewItem = {
        id: "item_header_placeholder",
        title: "Header placeholder title",
        subTitle: "Header placeholder subtitle",
        description: undefined,
        logo: undefined,
        logoBgColor: undefined,
        banner: undefined,
        bannerBgColor: undefined,
        logoShape: "square",
        isPlaceholder: true,
        disabled: false,
        backUrl: undefined,
        backAction: undefined,
    }

    switch (type) {
        case "users":
            const user = contentItem as TUser;
            return {
                ...placeHolder,
                id: user.id,
                title: user.username,
                subTitle: translateTerm("new user", true),
                isPlaceholder: false,
                logoShape: "circle",
                backAction: getAction("navigateBack")
            }

        case "userCards":
            const userCard = contentItem as TUserCard;
            return {
                ...placeHolder,
                id: userCard.id,
                title: userCard.displayName,
                subTitle: userCard.slogan,
                description: userCard.description,
                logo: userCard.avatar?.fileData,
                logoBgColor: userCard.avatarBgColor,
                banner: userCard.banner?.fileData,
                bannerBgColor: userCard.bannerBgColor,
                isPlaceholder: false,
                logoShape: "circle",
                backAction: getAction("navigateBack")
            }

        case "workgroups":
            const wg = contentItem as TWorkGroup;
            return {
                ...placeHolder,
                id: wg?.id || placeHolder.id,
                title: wg?.label || translateTerm("new group", true),
                subTitle: translateTerm("new group", true),
                isPlaceholder: false,
                backAction: getAction("navigateBack"),
            }
        case "workgroupCards":
            const wgCard = contentItem as TWGCard;
            return {
                ...placeHolder,
                id: wgCard.id,
                title: wgCard.title,
                subTitle: wgCard.slogan,
                description: wgCard.description,
                logo: wgCard.logo?.fileData,
                logoBgColor: wgCard.logoBgColor,
                banner: wgCard.banner?.fileData,
                bannerBgColor: wgCard.color,
                isPlaceholder: false,
                backAction: getAction("navigateBack")
            }

        default: return placeHolder
    }
}
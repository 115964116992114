import { TRequestMethod } from "../../types/typesBase";
import { store } from "../store";


export type TRequestProps = {
    url: string,
    method?: TRequestMethod,
    headers?: { [a: string]: string },
    body?: object | string,
    responseFormat?: "json" | "blob"
}

export const apiFetchData = async (props: TRequestProps) => {
    const jwt = store.getState().sliceIfSettings.jwt
    if (jwt) props.headers = { ...props.headers, Authorization: `Bearer ${jwt}` }

    return fetch(props.url, { method: props.method, headers: props.headers, body: JSON.stringify(props.body) })
        .then((res) => {
            const contentType = res.headers.get("content-type")?.split("; ")[0]
            switch (contentType) {
                case "text/html": return res.text();
                case "application/json": return res.json();
                default: return {
                    error: {
                        status: "",
                        name: "",
                        message: "Incorrect server response format",
                        details: {}
                    }
                }
            }
        })
}

export const requestFile = async (url: string) => {
    const response = await fetch(url);
    if (response.status === 401) {
        throw new Error('401')
    }
    return await response.blob()
}
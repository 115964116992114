import { schema } from "normalizr"

export const schemaNormalizeForm = () => {
    const formSubSubGroup = new schema.Entity("formGroups")
    const formSubGroup = new schema.Entity("formGroups", {
        formGroups: [formSubSubGroup]
    })
    const formGroup = new schema.Entity("formGroups", {
        formGroups: [formSubGroup]
    })
    return new schema.Entity('forms', {
        formGroups: [formGroup]
    })
}
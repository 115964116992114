import _ from "lodash";
import { TFrontendBaseObject } from "../types/typesBase";

type TValue = string | number | boolean | object;


export const IDsToString = (source: object | object[]) => {

    const fixItem = (item: object): object => {
        return _.reduce(item, (res, val, key) => {
            const newVal = key === "id" && typeof val === "number"
                ? (val as number).toString()
                : val === null ? null
                    : typeof val !== "object" ? val
                        : fixIDs(val)
            return { ...res, [key]: newVal }
        }, {}) as object
    }

    const fixIDs = (payload: TValue) => {
        if (typeof payload === "object") {
            if (!Array.isArray(payload)) {
                return fixItem(payload)
            } else {
                return payload.map(item => {
                    if (typeof item !== "object") return item
                    return fixItem(item)
                })
            }

        }
        return payload
    }

    return fixIDs(source)
}

export const fixIDsToNumber = (source: object | object[]) => {

    const fixItem = (item: object): object => {
        return _.reduce(item, (res, val, key) => {
            const newVal = key === "id"
                && typeof val === "string"
                && Number(val).toString() === val
                ? Number(val)
                : val === null
                    ? null
                    : typeof val !== "object"
                        ? val
                        : fixIDs(val)
            return { ...res, [key]: newVal }
        }, {}) as object
    }

    const fixIDs = (payload: TValue) => {
        if (typeof payload === "object") {
            if (!Array.isArray(payload)) {
                return fixItem(payload)
            } else {
                return payload.map(item => {
                    if (typeof item !== "object") return item
                    return fixItem(item)
                })
            }

        }
        return payload
    }

    return fixIDs(source)
}
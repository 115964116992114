import _ from "lodash";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";
import { TContentItem } from "../../../../../types/typesStoreData";
import { TPageView } from "../../../../../types/typesStructure";
import { ViewAccordion } from "./ViewAccordion";
import { ViewDefault } from "./ViewDefault";
import { ViewEmpty } from "./ViewEmpty";
import { ViewRow } from "./ViewRow";
import { ViewSlider } from "./ViewSlider";

export const ViewStatic: React.FC<{ view: TPageView, className?: string }> = ({ view, className }) => {
    const viewItemsNum = _.reduce(view.viewItems, (res, val) => [...res, ...(val || [])], [] as TContentItem[]).length;
    if (view.content) return <ReactMarkdown children={view.content} rehypePlugins={[rehypeRaw]} className="pb-3" />;
    if ((!viewItemsNum && !view.isPlaceholder) || view.hidden) return view.showEmpty ? <ViewEmpty id={view.id} title={view.title} /> : null

    // Делю виды по макетам
    switch (view.viewTemplate) {
        case "accordion": return <ViewAccordion view={view} className={className} />;
        case "slider": return <ViewSlider view={view} className={className} />;
        case "row": return <ViewRow view={view} className={className} titleClassName="border-bottom text-secondary pb-2 mb-2 h5" />;
        case "row-compact": return <ViewRow view={view} className={className} titleClassName="mb-0 text-secondary fs-7 fw-bold" />;
        default: return <ViewDefault view={view} className={className} />;
    }
}

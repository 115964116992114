import { localeFetching } from "../../store/actions/actionsExtra";
import { setSiteLocale } from "../../store/slices/sliceIfSettings"
import { store } from "../../store/store"
import { TSiteLocale } from "../../types/typesStructure";

export const actionSwitchSiteLocale = (locale: string) => {
    const currentLocale = store.getState().sliceIfSettings.locale;
    if (currentLocale !== locale) {
        store.dispatch(setSiteLocale(locale));
        const newLocale = (Object.values(store.getState().sliceData.collections.locales || {}) as unknown as TSiteLocale[]).find(item => item.code === locale)
        if (newLocale && newLocale.fetch !== "success") store.dispatch(localeFetching())
    }
} 
import _, { compact, slice } from "lodash";
import { TExpObj, TFieldMutationCondition, TFieldMutationMultiCondition } from "../../../types/typesBase";
import { TDataItem } from "../../../types/typesStoreData";
import { generate } from "../generate";

export type TActiveObjects = {
    [a: string]: Object | undefined,
}

export const stringExpFind = (text: string, active: TActiveObjects): string | number | boolean => {
    const words = text.split(" ");
    return words.reduce((a, b) => {
        if (b[0] === "{" && b[b.length - 1] === "}") {
            const bObj = JSON.parse(b);
            if (typeof bObj == "object" && !Array.isArray(bObj)) {
                return [
                    ...a,
                    getStringVariableValue(bObj, active) || "Unknown"
                ]
            } else {
                return [...a, b]
            }
        } else {
            return [...a, b]
        }
    }, [] as string[]).join(" ")

}

const getObjVariableValue = (variable: object, active: TActiveObjects) => {

    const checkItem = (item: object): object => {
        return _.reduce(item, (res, val, key) => {
            switch (typeof val) {
                case "object": return { ...res, [key]: checkItems(val, active) };
                case "string": return { ...res, [key]: getStringVariableValue(val, active) };
                default: return { ...res, [key]: val }
            }
        }, {})
    }

    const checkItems = (payload: object, active: TActiveObjects) => {
        if (!Array.isArray(payload)) {
            return checkItem(payload)
        } else {
            return payload.map(item => {
                switch (typeof item) {
                    case "object": return checkItem(item);
                    case "string": return getStringVariableValue(item, active);
                    default: return item
                }
            })
        }

    }

    return checkItems(variable, active)
}

const getStringVariableValue = (variable: string | number | boolean, active: TActiveObjects) => {
    if (typeof variable !== "string") return variable;
    const newValues = compact(variable.split("<").reduce((r, v, k) => {
        const newValueArray = v.split(">")
        if (newValueArray[0][0] === "%" && newValueArray[0][newValueArray[0].length - 1] === "%" && newValueArray[0].indexOf(" ") === -1) {
            const paramString = newValueArray[0].slice(1, newValueArray[0].length - 1);
            const newValue = _.get({ active, generate }, paramString)
            newValueArray.splice(0, 1, newValue)
        }
        return [
            ...r,
            ...newValueArray
        ]
    }, [] as any[]))

    if (newValues.length > 1) {
        return newValues.map(value => typeof value === "object" ? JSON.stringify(value) : value.toString()).join("");
    } else {
        return newValues[0]
    }
}

const compare = (exp: TFieldMutationCondition, active: TActiveObjects) => {
    const valA = getStringVariableValue(exp.a, active);
    const valB = exp.b ? getStringVariableValue(exp.b, active) : undefined;
    const res = typeof exp.res !== "object" ? getStringVariableValue(exp.res, active) : getObjVariableValue(exp.res, active);
    const elsRes = typeof exp.elsRes !== "object" ? getStringVariableValue(exp.elsRes, active) : getObjVariableValue(exp.elsRes, active);
    // console.log(`A: ${valA} B:${valB}`)

    if (valA && valB) {
        switch (exp.comp) {
            case "$eq":
                return valA === valB ? res : elsRes;
            case "$nEq":
                return valA !== valB ? res : elsRes;
            default: return "Unknown 2 items compare operator!"
        }
    } else {
        switch (exp.comp) {
            case "$nl":
                return valA === null ? res : elsRes;
            case "$nNl":
                return valA !== null ? res : elsRes;
            case "$und":
                return !valA ? res : elsRes;
            default: return "Unknown item check operator!"
        }
    }
}

const multiCompare = (exp: TFieldMutationMultiCondition, active: TActiveObjects) => {
    const results = exp.conditions.reduce((res, val, key) => {
        const result = compare(val, active);
        return [...res, result ? true : false]
    }, [] as boolean[])
    switch (exp.comp) {
        case "$all": return results.findIndex(item => item === false) === -1 ? exp.res : exp.elsRes;
        case "$nAll": return results.findIndex(item => item === true) === -1 ? exp.res : exp.elsRes;
        case "$any": return results.findIndex(item => item === true) !== -1 ? exp.res : exp.elsRes;
        default: return "Unknown multi compare operator!"
    }
}

export const expExec = (srcObj: TExpObj, active: TActiveObjects): object | string | number | boolean | undefined => {
    if (srcObj.expression) {
        switch (typeof srcObj.expression) {
            case "string": return getStringVariableValue(srcObj.expression, active);
            case "object": return getObjVariableValue(srcObj.expression, active)
            default: return srcObj.expression
        }
    } else if (srcObj.condition) {
        if ((srcObj.condition as TFieldMutationMultiCondition).conditions) {
            return multiCompare(srcObj.condition as TFieldMutationMultiCondition, active)
        } else {
            return compare(srcObj.condition as TFieldMutationCondition, active)
        }
    }
}


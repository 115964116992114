import { compact } from "lodash";
import { store } from "../../../../../../store/store";
import { TContentItem, TFaqItem, TMediaItem, TLink, TNewsItem } from "../../../../../../types/typesStoreData";
import { TSitePageNormalized } from "../../../../../../types/typesStructure";
import { TWGCard, TWGCardFolder } from "../../../../../../types/typesWorkGroup";
import { getImageUrl } from "../../../../../library/getImageUrl";
import { TViewItem } from "../../../../../typesTemplate";

export const getViewItemData = (type: string, contentItem?: TContentItem): TViewItem => {
    const sitePages = Object.values(store.getState().sliceData.collections.pages) as TSitePageNormalized[];
    const params = new URLSearchParams(document.location.search);

    let viewItem: TViewItem = {
        contentItemId: contentItem?.id || "placeHolder",
        title: "Placeholder title",
        subtitle: "Placeholder subtitle",
        description: "Placeholder description text",
        content: [...Array(4)].map(i => "Some placeholder content text.".split(" ").map(item => `<span class="placeholder rounded-1 fs-7 my-1">${item}</span>`).join(" ")).join(" "),
        isPlaceholder: true
    }

    if (!contentItem) return viewItem;

    switch (type) {
        case "faqItems":
            const faqItem = contentItem as TFaqItem;
            viewItem.title = faqItem.question;
            viewItem.content = faqItem.answer;
            viewItem.isPlaceholder = faqItem.isPlaceholder
            return viewItem;

        case "newsItems":
            const newsItem = contentItem as TNewsItem;
            viewItem.title = newsItem.title;
            viewItem.content = newsItem.announcement;
            viewItem.image = getImageUrl(newsItem.image, "th");
            viewItem.date = newsItem.date;
            viewItem.isPlaceholder = newsItem.isPlaceholder
            return viewItem

        case "workgroupCards":
            const WGCard = contentItem as TWGCard;
            params.set("workgroupCards", WGCard.id);
            const WGcardPageUrl = sitePages.find(item => item.name === "workgroupCardPage")?.url || document.location.pathname
            const WGcardUrl = `${WGcardPageUrl}${params ? `?${params.toString()}` : ""}`
            viewItem.title = WGCard.title;
            viewItem.subtitle = WGCard.slogan;
            viewItem.banner = WGCard.banner?.fileData;
            viewItem.image = WGCard.logo?.fileData;
            viewItem.link = WGcardUrl;
            viewItem.accentColor = WGCard.logoBgColor;
            viewItem.isPlaceholder = WGCard.isPlaceholder;
            return viewItem

        case "workgroupCardFolders":
            const WGCardFolder = contentItem as TWGCardFolder;
            params.set("workgroupCardFolders", WGCardFolder.id);
            const wgcfUrl = `${document.location.pathname}${params ? `?${params.toString()}` : ""}`
            viewItem.title = WGCardFolder.title;
            viewItem.banner = getImageUrl(WGCardFolder.image, "th");
            viewItem.disabled = WGCardFolder.disabled;
            viewItem.link = wgcfUrl;
            viewItem.isPlaceholder = WGCardFolder.isPlaceholder;
            return viewItem

        case "mediaItems":
            const mediaItem = contentItem as TMediaItem;
            return viewItem

        case "links":
            const linkItem = contentItem as TLink;
            viewItem.title = linkItem.title;
            viewItem.description = linkItem.description;
            viewItem.link = linkItem.url;
            viewItem.action = linkItem.action;
            viewItem.badge = linkItem.badge;
            viewItem.icons = compact([linkItem.iconNormal, linkItem.iconHover, linkItem.iconActive])
            viewItem.isPlaceholder = linkItem.isPlaceholder;
            return viewItem;
        default: return viewItem
    }
}
import { call, put } from "redux-saga/effects";
import { ifSettingsFetchingPending, ifSettingsFetchingSucceed } from "../actions/actionsExtra";
import { TInterfaceStore } from "../slices/sliceIfSettings";

export function* ifSettingsFetchWorker() {
    yield put(ifSettingsFetchingPending());
    try {
        const localSettings: TInterfaceStore = yield call(() => JSON.parse(localStorage.getItem("interfaceSettings") || ""));
        yield put(ifSettingsFetchingSucceed(localSettings));
    }
    catch {
        const defaultSettings: TInterfaceStore = {
            locale: "ru",
            showModal: false,
            showSidebar: true,
            showBurgerMenu: false,
            showHints: true,
            status: "success",
            showUserMenu: {},
            localized: [{ showCookieAlert: true, locale: "ru" }],
            toasts: [],
            toastsStatus: "notReceived",
            formSchemes: {},
        }
        yield put(ifSettingsFetchingSucceed(defaultSettings));
    }
}

import { schema } from "normalizr";
import { schemaNormalizeImage } from "./schemaNormalizeImage";

export const schemaNormalizeBanner = () => {
    const image = schemaNormalizeImage();
    return new schema.Entity('banners', {
        varDesktop: {
            image: image,
            bgImage: image
        },
        varMobile: {
            image: image,
            bgImage: image
        },
    })

}
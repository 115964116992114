import { call, take } from "redux-saga/effects";
import { INITIAL_FETCH } from "../../settings/constants";
import { ifSettingsFetchWorker } from "../fetches/ifSettingsFetch";
import { collectionFetchWorker } from "../fetches/collectionFetch";
import { store } from "../store";
import { TApiRoute } from "../../types/typesStructure";

export function* sagaInitialWatch() {
    while (true) {
        yield take(INITIAL_FETCH);

        // yield call(() => collectionFetchWorker({
        //     payload: {
        //         name: "initial",
        //         url: "/api/initial",
        //         query: {
        //             locale: "ru"
        //         },
        //     },
        //     type: "initial"
        // }));


        // Получаю массив API роутов
        yield call(() => collectionFetchWorker({
            payload: {
                name: "apiRoutes",
                url: "/api/api-routes",
                query: {},
            },
            type: "apiRoutes"
        }));

        const routes = Object.values(store.getState().sliceData.collections.apiRoutes) as TApiRoute[]

        // Получаю настройки пользователя (из local storage)
        yield call(ifSettingsFetchWorker);

        // Получаю себя

        yield call(() => {
            const jwt = store.getState().sliceIfSettings.jwt;
            if (jwt) {
                return collectionFetchWorker({
                    payload: {
                        name: "me",
                        url: routes.find(item => item.storageName === "me")?.url || "/api/routes",
                        query: {
                            populate: [
                                "userCard.contactPhones",
                                "userCard.contactEmails",
                                "userCard.contactSites",
                                "userCard.contactSocials",
                                "userPersonals.contactPhones",
                                "userPersonals.contactEmails",
                                "userPersonals.contactSites",
                                "userPersonals.contactSocials",
                                // "workgroups.card.logo",
                                // "workgroups.card.banner",
                                "workgroups.card.contactPhones",
                                "workgroups.card.contactEmails",
                                "workgroups.card.contactSites",
                                "workgroups.card.contactSocials",
                                "workgroups.card.folders",
                                "ownWorkgroups.details",
                                "ownWorkgroups.address",
                                "ownWorkgroups.bankRequisites",
                                // "ownWorkgroups.card.logo",
                                // "ownWorkgroups.card.banner",
                                "ownWorkgroups.card.contactPhones",
                                "ownWorkgroups.card.contactEmails",
                                "ownWorkgroups.card.contactSites",
                                "ownWorkgroups.card.contactSocials",
                                "ownWorkgroups.card.folders",
                                "role"
                            ]
                        },
                        errorAction: { name: "logout" }
                    }, type: "users"
                })
            }
        })

        // Получаю массив локалей
        yield call(() => collectionFetchWorker({
            payload: {
                name: "locales",
                url: routes.find(item => item.storageName === "locales")?.url || "/api/routes",
                query: {},
            },
            type: "locales"
        }));

        // Получаю набор параметров (по текущей локали)
        yield call(() => {
            const apiName = "siteProperties"
            return collectionFetchWorker({
                payload: {
                    name: apiName,
                    url: routes.find(item => item.storageName === apiName)?.url || "/api/routes",
                    single: true,
                    query: {
                        populate: [
                            "dummy",
                            "mainMenuItems",
                            "socialsMenuItems",
                            "contactsMenuItems",
                            "docsMenuItems",
                            "systemURLs",
                            "permanentBlocks.views"
                        ]
                    }
                },
                type: apiName
            })
        })

        // Получаю словарь (по текущей локали)
        yield call(() => {
            const apiName = "dictionaries"
            return collectionFetchWorker({
                payload: {
                    name: apiName,
                    url: routes.find(item => item.storageName === apiName)?.url || "/api/routes",
                    single: true,
                    query: {
                        populate: ["terms"],
                        pagination: {
                            page: 1,
                            pageSize: 1000
                        }
                    },

                },
                type: apiName
            })
        })

        // Получаю страницы со структурой (без контента в представлениях), атрибутом placeHolder для представлений
        yield call(() => {
            const apiName = "pages"
            return collectionFetchWorker({
                payload: {
                    name: apiName,
                    url: routes.find(item => item.storageName === apiName)?.url || "/api/routes",
                    query: {
                        populate: [
                            "layouts.blocks.hint",
                            "layouts.blocks.prefixButton",
                            "layouts.blocks.views",
                            "blocks.hint",
                            "blocks.prefixButton",
                            "blocks.views"
                        ]
                    },
                    attributes: [
                        {
                            collectName: "views",
                            name: "isPlaceholder",
                            value: "true",
                        }
                    ]
                },
                type: apiName
            })
        })
    }
}
import { TPropsListItem } from "./PropsList";

export const PropsListTerm: React.FC<TPropsListItem> = (props) => {
    return props.description
        ? <>
            <dt className={"col-12 col-md-3 col-sm-4 px-1 lh-lg mb-0 fw-bold text-black-50 h6" + (props.isPlaceHolder ? " placeholder-wave" : "")}>
                {props.isPlaceHolder
                    ? <span className="placeholder rounded">{props.therm}</span>
                    : <>{props.therm}:</>
                }
            </dt>
            <dd className={"col-12 col-md-9 col-sm-8 px-1 lh-lg mb-0" + (props.isPlaceHolder ? " placeholder-wave" : "")}>
                {props.isPlaceHolder

                    ? <span className="placeholder rounded">{props.description}</span>
                    : props.description
                }
            </dd>
        </>
        : null
}
import { useDispatch, useSelector } from "react-redux";
import { Messenger } from "../services/Messenger";
import { toastSelector } from "../store/slices/sliceIfSettings";

export const useMessenger = (): Messenger => {

    const { toasts, toastsStatus } = useSelector(toastSelector);

    return new Messenger(useDispatch(), toasts.slice(), toastsStatus);

}
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";
import { TContentItem } from "../../../../../../types/typesStoreData";
import { Button } from "../../../../../library/Button";
import { getImageUrl } from "../../../../../library/getImageUrl";
import { useGetBannerItem } from "./convertTypes/useGetBannerItem";


export const Banner: React.FC<{ type: string, contentItem?: TContentItem, }> = ({ contentItem, type }) => {
    const banner = useGetBannerItem(type, contentItem)
    const desktop = banner.varDesktop
    const desktopRatio = desktop.ratio.replace("text-", "").replace(" / image-", "")
    const desktopTextColClass = `col-${desktopRatio[0]}`
    const desktopImgColClass = `col-${desktopRatio[1]}`
    const desktopBgImage = getImageUrl(banner.varDesktop.bgImage, "lg");
    const desktopImage = getImageUrl(banner.varDesktop.image, "md");

    const desktopBanner = <div className={"d-none d-sm-block ratio ratio-3x1 bg-img-cover rounded" + (banner.isPlaceholder ? " bg-midlight" : "")}
        style={{ backgroundImage: `url(${desktopBgImage})` }}>
        <div className="d-flex">
            <div className={`d-flex flex-column justify-content-evenly ps-3 ps-sm-4 py-4 pe-3 ${desktopImage ? desktopTextColClass : "col-12"}`}>
                <ReactMarkdown children={desktop.content} className="lh-sm" rehypePlugins={[rehypeRaw]} />
                {banner.buttons?.length ? <div className="d-none d-sm-block">
                    {banner.buttons.map(button => <Button key={`bannerButton_${button.id}`} button={button} className="me-2" />)}
                </div> : null}
            </div>
            {desktopImage ?
                <div className={desktopImgColClass}
                    style={{
                        backgroundImage: `url(${desktopImage})`,
                        backgroundPosition: desktop.imageAlign,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat"

                    }} /> : null
            }
        </div>
    </div>

    const mobile = banner.varMobile
    const mobileRatio = mobile.ratio.replace("text-", "").replace(" / image-", "")
    const mobileTextHeight = Number(mobileRatio[0])
    const mobileImgHeight = Number(mobileRatio[1])
    const mobileBgImage = getImageUrl(banner.varMobile.bgImage, "lg");
    const mobileImage = getImageUrl(banner.varMobile.image, "md");
    const mobileBanner = <div className={"d-sm-none ratio ratio-1x1 bg-img-cover rounded" + (banner.isPlaceholder ? " bg-midlight" : "")}
        style={{ backgroundImage: `url(${mobileBgImage})` }}>
        <div className="d-flex flex-column">
            <div className={`d-flex flex-column justify-content-evenly pt-4 px-3 px-sm-4 ${!mobileImage ? "pb-4" : ""}`}
                style={{ height: mobileImage ? `${(mobileTextHeight / (mobileTextHeight + mobileImgHeight)) * 100}%` : "100%" }}>
                <ReactMarkdown children={mobile.content} className="lh-sm" rehypePlugins={[rehypeRaw]} />
                {banner.buttons?.length ? <div className="d-none d-sm-block">
                    {banner.buttons.map(button => <Button key={`bannerButton_${button.id}`} button={button} className="me-2" />)}
                </div> : null}
            </div>
            {mobileImage ?
                <div style={{
                    backgroundImage: `url(${mobileImage})`,
                    height: `${(mobileImgHeight / (mobileTextHeight + mobileImgHeight)) * 100}%`,
                    backgroundPosition: mobile.imageAlign,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat"
                }} /> : null
            }

        </div>
    </div>


    return <>
        {desktopBanner}
        {mobileBanner}
    </>
}
import { getAction } from "../../../../../../../actions/getAction";
import { store } from "../../../../../../../store/store";
import { TContentItem } from "../../../../../../../types/typesStoreData";
import { TSitePageNormalized } from "../../../../../../../types/typesStructure";
import { TUserCard } from "../../../../../../../types/typesUser";
import { TWGCard, TWorkGroup } from "../../../../../../../types/typesWorkGroup";
import { getImageUrl } from "../../../../../../library/getImageUrl";
import { TCollectionCardViewItem } from "./CollectionCard";

export const getCollectionCard = (contentItem?: TContentItem, type?: string): TCollectionCardViewItem => {
    const placeHolder: TCollectionCardViewItem = {
        id: contentItem?.id || "card_placeholder",
        title: "Card placeholder title",
        subTitle: "Card placeholder subtitle",
        description: "Card placeholder description",
        logoShape: "square",
        isPlaceholder: true,
        disabled: true,
    }
    const sitePages = Object.values(store.getState().sliceData.collections.pages || {}) as TSitePageNormalized[];
    const cardPageUrl = sitePages.find(item => item.name === `${type}_item`)?.url
    const cardLink = cardPageUrl && contentItem
        ? `${cardPageUrl}?${type}=${contentItem.id}`
        : undefined

    switch (type) {

        case "workgroups":
            const workGroup = contentItem as TWorkGroup;
            return {
                ...placeHolder,
                id: workGroup.id,
                title: workGroup.card?.title || workGroup.label,
                subTitle: workGroup.card?.slogan,
                description: workGroup.card?.description,
                logo: workGroup.card?.logo?.fileData,
                logoBgColor: workGroup.card?.logoBgColor,
                banner: workGroup.card?.banner?.fileData,
                bannerBgColor: workGroup.card?.color,
                logoShape: "square",
                isPlaceholder: false,
                disabled: false,
                url: `${cardPageUrl}?workgroups=${workGroup.id}`,
            }

        case "workgroupCards":
            const wgCard = contentItem as TWGCard;
            const wgCardAction = wgCard.action
                ? () => getAction(wgCard.action || "")(wgCard.actionProps)
                : undefined
            return {
                ...placeHolder,
                id: wgCard.id,
                title: wgCard.title,
                subTitle: wgCard.slogan,
                description: wgCard.description,
                banner: wgCard.banner?.fileData,
                bannerBgColor: wgCard.color,
                logoBgColor: wgCard.logoBgColor,
                logo: wgCard.logo?.fileData,
                isPlaceholder: false,
                disabled: false,
                action: wgCardAction,
                url: cardLink
            };

        case "userCards":
            const userCard = contentItem as TUserCard;
            return {
                ...placeHolder,
                id: userCard.id,
                title: userCard.displayName,
                subTitle: userCard.slogan,
                description: userCard.description,
                banner: userCard.banner?.fileData,
                bannerBgColor: userCard.bannerBgColor,
                logoBgColor: userCard.avatarBgColor,
                logo: userCard.avatar?.fileData,
                isPlaceholder: false,
                disabled: false,
                logoShape: "circle"
            };

        default: return placeHolder;
    }
}
import { schema } from "normalizr";
import { schemaNormalizeImage } from "./schemaNormalizeImage";
import { schemaNormalizeWGCard } from "./schemaNormalizeWGCard";

export const schemaNormalizeWGCardFolder = () => {
    const image = schemaNormalizeImage();
    const WGCard = schemaNormalizeWGCard();
    const WGParentFolder = new schema.Entity('workgroupCardFolders')
    const WGChildFolder = new schema.Entity('workgroupCardFolders', {
        image: image,
        parent: WGParentFolder,
        cards: [WGCard]
    })
    return new schema.Entity('workgroupCardFolders', {
        image: image,
        children: [WGChildFolder],
        parent: WGParentFolder,
        cards: [WGCard]
    })
}
import _, { compact } from "lodash";
import { denormalize } from "normalizr";
import { TDataItem, TStoreCollectionItem } from "../types/typesStoreData";
import { schemaNormalize } from "./normalizers/schemaNormalize";
import { RootState, store } from "./store";

export type TActiveStoreItems = { id: { [a: string]: string }, object: { [a: string]: TDataItem } }

// Fetch statuses -->

export const getCollectionStatuses = (rootState?: RootState) => {
    const state = rootState || store.getState()
    return state.sliceData.fetchStatuses
}

export const getCollectionStatus = (apiName: string, rootState?: RootState) => {
    const state = rootState || store.getState()
    return state.sliceData.fetchStatuses[apiName]
}

// <-- Fetch statuses

// Collections -->  

export const getDataItems = (apiName: string, IDs?: string[], rootState?: RootState) => {
    const state = rootState || store.getState()
    return compact(_.map(state.sliceData.collections[apiName], (val, key) => (
        IDs && IDs.find(item => item === key)
            || !IDs
            ? getDataItem(apiName, key, state)
            : undefined
    )))
}

export const getDataItem = (apiName: string, ID: string, rootState?: RootState,) => {
    const state = rootState || store.getState()
    const item = state.sliceData.collections[apiName]?.[ID];
    if (!item) return
    const schema = schemaNormalize(`${apiName}Single`);
    return schema ? denormalize(item, schema(), state.sliceData.collections) as TDataItem : undefined;
}

export const getActiveDataItem = (apiName: string, rootState?: RootState,) => {
    const name = apiName === "me" ? "users" : apiName
    const state = rootState || store.getState()
    const itemID = state.sliceData.activeItems[apiName];
    if (!itemID) return;
    return getDataItem(name, itemID, state)
}

export const getDataItemByAttr = (apiName: string, attribute: string, value?: string | number | boolean, rootState?: RootState) => {
    const state = rootState || store.getState()
    const locale = state.sliceIfSettings.locale
    const item = (Object.values(state.sliceData.collections[apiName] || {}) as { [a: string]: any }[]).find(item => item[attribute] === value && (!item.locale || item.locale === locale)) as TStoreCollectionItem;
    if (!item) return
    return getDataItem(apiName, item.id, state)
}

// <-- collections

// Actives -->

export const getActiveDataItemIDs = (rootState?: RootState) => {
    const state = rootState || store.getState()
    return state.sliceData.activeItems
}

export const getActiveDataItems = (rootState?: RootState) => {
    const state = rootState || store.getState()
    return _.reduce(state.sliceData.activeItems, (res, val, key) => ({
        ...res,
        [key]: getDataItem(key === "me" ? "users" : key, val, state)
    } as { [a: string]: TDataItem }), {} as { [a: string]: TDataItem })
}

export const getActives = (rootState?: RootState): TActiveStoreItems => {
    const state = rootState || store.getState()
    return {
        id: getActiveDataItemIDs(state),
        object: getActiveDataItems(state),
    }
}

// <-- Actives

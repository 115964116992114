import _ from "lodash";
import { TFormInput } from "../../../types/typesForm";
import { TDataItem } from "../../../types/typesStoreData";
import { expExec, TActiveObjects } from "./expExec";

export const mutateDataItem = (dataItem: TDataItem | TFormInput, active: TActiveObjects, recourse?: boolean): typeof dataItem => {
    const newDataItem = {
        ...dataItem,
        ...dataItem.mutations?.reduce((res, val) => {
            const mutantValue = () => {
                switch (val.field) {
                    case "id": return `${dataItem.id}.${expExec(val.value, active)}`
                    default: return expExec(val.value, active);
                }
            }
            return {
                ...res,
                [val.field]: mutantValue()
            }

        }, {})
    }

    if (!recourse) return newDataItem

    return _.reduce(newDataItem, (ir, iv, ik) => {
        if (ik === "mutations" || !iv) return { ...ir }
        switch (typeof iv) {
            case "object":
                if (_.isArray(iv)) {
                    const mutantArray = (iv as unknown as TDataItem[]).map(item => mutateDataItem(item, active, true))
                    return { ...ir, [ik]: mutantArray }
                } else {
                    return { ...ir, [ik]: mutateDataItem(iv as unknown as TDataItem, active) }
                }
            default: return { ...ir, [ik]: iv }
        }
    }, {} as typeof dataItem)
}
import _, { cloneDeep } from "lodash";
import { validate } from "../../library/Form/validate";
import { TContact } from "../../types/typesStoreData";

//: { type: string, icon: string, url: URL, title: string }
export const getContactAttributes = (srcContact: TContact) => {
    const contact = cloneDeep(srcContact);
    const contactTypes = {
        email: validate.email,
        phone: validate.phone,
        vkontakte: validate.vkontakte,
        facebook: validate.facebook,
        instagram: validate.instagram,
        url: validate.url,
    }

    const type = _.reduce(contactTypes, (r, v, k) => {
        const valid = v(contact.url)
        if (valid) {
            return [...r, k]
        } else {
            return [...r]
        }
    }, [] as string[])[0]

    // const type = "unknown"
    if (type) {
        switch (type) {
            case "email":
                const emailUrl = `mailto://${contact.url}`
                return { type: "email", icon: "icon-contacts-mail", url: emailUrl, title: contact.url }
            case "phone":
                const phoneUrl = `tel://${contact.url}`
                return { type: "phone", icon: "icon-contacts-phone", url: phoneUrl, title: contact.url }
            case "vkontakte":
                const vkUrl = new URL(contact.url)
                return { type: "social", icon: "icon-social-vkontakte", url: vkUrl.toString(), title: vkUrl.hostname.toString() }
            case "facebook":

                const fbUrl = new URL(contact.url)
                return { type: "social", icon: "icon-social-facebook", url: fbUrl.toString(), title: fbUrl.hostname.toString() }
            case "instagram":
                const inUrl = new URL(contact.url)
                return { type: "social", icon: "icon-social-instagram", url: inUrl.toString(), title: inUrl.hostname.toString() }
            default:
                const prtIndex = contact.url.indexOf("://")
                const srcProtocol = prtIndex === -1 ? undefined : contact.url.slice(0, prtIndex + 3);
                const newUrl = new URL(`${srcProtocol || "http://"}${srcProtocol ? contact.url.replace(srcProtocol, "") : contact.url}`);
                return { type: "url", icon: "icon-contacts-url", url: newUrl.toString(), title: newUrl.hostname }
        }
    }
}
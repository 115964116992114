export const validate: { [a: string]: (b: string | number, c?: string) => boolean } = {
    email: (email: string | number) => {
        const regExp = /^(?!.*@.*@.*$)(?!.*@.*--.*\..*$)(?!.*@.*-\..*$)(?!.*@.*-$)(.+@.+\..{1,11})$/
        return regExp.test(email as string)

    },
    login: (login: string | number) => {
        const regExp = /^[a-zA-Z0-9_-]{4,24}$/
        return regExp.test(login as string)

    },
    password: (password: string | number) => {
        const regExp = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
        return regExp.test(password as string)
    },
    phone: (phone: string | number) => {
        const regExp = /^\+[0-9]{10,11}$/
        return regExp.test(phone as string)
    },
    vkontakte: (url: string | number) => {
        const regExp = /(http:\/\/|https:\/\/)?(www.)?(vk\.com|vkontakte\.ru)\/[a-zA-Z0-9_.-]+/
        return regExp.test(url as string)
    },
    facebook: (url: string | number) => {
        const regExp = /(http:\/\/|https:\/\/)?(www.)?(facebook\.com)\/[a-zA-Z0-9_.-]+/
        return regExp.test(url as string)
    },
    instagram: (url: string | number) => {
        const regExp = /(http:\/\/|https:\/\/)?(www.)?(instagram\.com)\/[a-zA-Z0-9_.-]+/
        return regExp.test(url as string)
    },

    url: (url: string | number) => {
        const regExp = /(http:\/\/|https:\/\/)?(www.)?([a-z]+\.)?([a-z]+\.)([a-z]{2,6})(\/[a-zA-Z0-9_\/-]+)?/
        return regExp.test(url as string)
    },
    custom: (value: string | number, mask?: string) => {
        if (!mask) return false
        const regExp = new RegExp(mask);
        return regExp.test(value as string)
    },
    sqlDate: (sqlDate: string | number) => {
        const regExp = /[0-9]{4}\-[0-9]{2}\-[0-9]{2}/
        return regExp.test(sqlDate as string)
    },
    sqlDateTime: (sqlDate: string | number) => {
        const regExp = /[0-9]{4}\-[0-9]{2}\-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z/
        return regExp.test(sqlDate as string)
    },
    dummy: () => { console.log("Validator not find"); return false }
}


import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getModal, getShowModal, setModal, setShowModal } from "../../../store/slices/sliceIfSettings";
import { CloseButton } from "../../Controls/CloseButton";
import { ViewDynamic } from "./Block/View/ViewDynamic";

export const SectionModal: React.FC = () => {
    const dispatch = useDispatch();
    const showModal = useSelector(getShowModal);
    const modalProps = useSelector(getModal);

    useEffect(() => {
        if (!showModal && modalProps) {
            setTimeout(() => {
                dispatch(setModal(undefined));
            }, 300);
        }
    }, [showModal])

    if (!modalProps) return null;

    const closeHandler = () => {
        dispatch(setShowModal(false))
    }

    return <Modal size={modalProps.size} centered show={showModal} onHide={closeHandler} className="overflow-hidden">
        {!modalProps.contentOnly &&
            <Modal.Header className="bg-light rounded-top">
                {!modalProps.hideCloseButton && <CloseButton handler={closeHandler} />}
                <Modal.Title className="text-truncate pe-4">{modalProps.title}</Modal.Title>
            </Modal.Header>
        }
        <Modal.Body className={"bg-white p-0 overflow-hidden" + (modalProps.contentOnly ? " rounded" : " rounded-bottom")}>
            {modalProps.contentOnly && !modalProps.hideCloseButton && <CloseButton handler={closeHandler} />}
            <ViewDynamic view={modalProps.view} />
        </Modal.Body>
    </Modal>
}
import { schema } from "normalizr";
import { schemaNormalizeHint } from "./schemaNormalizeHint";
import { schemaNormalizeView } from "./schemaNormalizeView";

export const schemaNormalizeBlock = (): schema.Entity<any> => {
    const view = schemaNormalizeView();
    const hint = schemaNormalizeHint();
    return new schema.Entity('blocks', {
        views: [view],
        hint: hint,
    })
}
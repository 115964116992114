import { nanoid } from "@reduxjs/toolkit";
import _ from "lodash";
import QueryString from "qs";
import { call, put } from "redux-saga/effects";
import { getAction } from "../../actions/getAction";
import { translateTerm } from "../../library/translateTerm";
import { Messenger } from "../../services/Messenger";
import { LOCAL_API_URL } from "../../settings/constants";
import { TBackendObject } from "../../types/typesBackendData";
import { TApiQuery } from "../../types/typesBase";
import { collectionFetching, collectionFetchingPending, collectionFetchingRejected, collectionFetchingSucceed, collectionItemFetchingPending } from "../actions/actionsExtra";
import { apiFetchData, TRequestProps } from "../requests/apiFetch";
import { store } from "../store";
import { getNormalized } from "./getNormalized";

export function* collectionFetchWorker({ payload }: ReturnType<typeof collectionFetching>) {
    const routeUrl = payload.url
    const locale = store.getState().sliceIfSettings.locale;
    const statuses = store.getState().sliceData.fetchStatuses;
    const collections = store.getState().sliceData.collections;
    const jwt = store.getState().sliceIfSettings.jwt;

    // Определяю одинли экземпляр запрашиваем
    const fetchOneID = payload.query.filters?.id && typeof payload.query.filters.id === "string"
        ? payload.query.filters.id
        : undefined;
    const fetchOneItem = fetchOneID ? collections[payload.name]?.[fetchOneID] : undefined;

    // Получаю нормализованного родителя
    const attachObj = payload.attach && store.getState().sliceData.collections[payload.attach.apiName][payload.attach.ID]

    // Проверяю статус (для коллекции - глобальный, для одного - индивидуальный) 
    const fetchStatus = fetchOneID ? fetchOneItem?.fetch : attachObj ? attachObj.fetch : statuses?.[payload.name];

    if (routeUrl && (fetchStatus !== "success" && fetchStatus !== "progress")) {

        if (!fetchOneID && !payload.attach) {
            // console.log("fetch collection")
            yield put(collectionFetchingPending(payload.name))
        }

        if (payload.attach && attachObj) {
            // console.log("fetch attachment")
            yield put(collectionItemFetchingPending({ apiName: payload.attach.apiName, ID: payload.attach.ID }))
        }
        if (fetchOneID && !payload.attach) {
            // console.log("fetch single")
            yield put(collectionItemFetchingPending({ apiName: payload.name, ID: fetchOneID }))
        }
        // if (payload.attach && payload.attach.id.indexOf(".") !== -1) console.log(`fetch mutant view ${payload.attach?.id}`)

        // Формирую строку запроса с добавлением локали
        const query = QueryString.stringify(
            {
                ...payload.query,
                locale: payload.name === "locales"
                    ? undefined
                    : locale,
                publicationState: payload.query.publicationState || "live"
            } as TApiQuery
        )

        // Формирую адрес для запроса
        const currentHostName = document.location.hostname
        const requestHostName = currentHostName === "localhost" ? LOCAL_API_URL : `${document.location.protocol}//${document.location.hostname}`

        // Формирую запрос
        const request: TRequestProps = {
            url: `${requestHostName}${routeUrl}${fetchOneID ? `/${fetchOneID}` : ""}${query ? "/?" + query : ""}`,
            method: "GET",
            responseFormat: "json",
            body: payload.body,
            headers: payload.headers,
        }
        // Запуск функции запроса
        const fetchObject: TBackendObject = yield call(() => apiFetchData(jwt ? { ...request, headers: { ...request.headers, Authorization: `Bearer ${jwt}` } } : request));

        if (fetchObject.error) {
            yield put(collectionFetchingRejected({ apiName: payload.name, response: `${fetchObject.error.message}`, ID: attachObj ? attachObj.id : fetchOneID ? fetchOneID : undefined }));

            if (payload.onErrorShowToast) {
                const messenger = new Messenger(store.dispatch, store.getState().sliceIfSettings.toasts.slice(), store.getState().sliceIfSettings.toastsStatus)
                messenger.addMessage({
                    id: nanoid(),
                    title: translateTerm("error", true),
                    content: translateTerm(fetchObject.error.message, true),
                    type: "danger",
                    timeout: 5000,
                })
                messenger.send();
            }

            if (payload.errorAction) {
                const action = getAction(payload.errorAction.name);
                action(payload.errorAction.argument);
            }
        } else {
            // Подмешиваю атрибуты
            if (payload.attributes) {
                payload.attributes.forEach(attribute => {
                    if (attribute.ID) {
                        const collectionItem = fetchObject.data && Array.isArray(fetchObject.data) ? fetchObject.data.find(item => item.id === attribute.ID) : undefined;
                        // const collectionItem = normalizedData[attribute.collectName]?.[attribute.ID];
                        if (collectionItem) _.set(collectionItem, attribute.name, attribute.value)
                    } else {
                        if (fetchObject.data) {
                            if (Array.isArray(fetchObject.data)) {
                                fetchObject.data.forEach(item => _.set(item, attribute.name, attribute.value))
                            } else {
                                _.set(fetchObject.data, attribute.name, attribute.value)
                            }
                        } else {
                            _.set(fetchObject, attribute.name, attribute.value)
                        }
                    }
                })
            }

            // Добавляю массив ID родителю
            // if (payload.attach && attachObj) {
            //     const newAttachObj = cloneDeep(attachObj);
            //     _.set(newAttachObj, payload.attach.attr, forNormalize[payload.name].map(item => item.id))
            //     store.dispatch(setStoreCollectionItem({
            //         apiName: payload.attach.apiName,
            //         collectionItem: {
            //             ...newAttachObj,
            //             fetch: "success",
            //         }
            //     }))
            // }

            const normalizedData = getNormalized(fetchObject, payload.name)

            // Отдаю результаты
            yield put(collectionFetchingSucceed({
                apiName: !fetchOneID && !attachObj ? payload.name : undefined,
                collections: normalizedData || {}
            }))
            if (payload.onSuccessToastMessage) {
                const messenger = new Messenger(store.dispatch, store.getState().sliceIfSettings.toasts.slice(), store.getState().sliceIfSettings.toastsStatus)
                messenger.addMessage({
                    id: nanoid(),
                    title: translateTerm("success", true),
                    content: payload.onSuccessToastMessage,
                    type: "success",
                    timeout: 5000,
                })
                messenger.send();
            }
            if (payload.action) {
                const action = getAction(payload.action.name);
                action(payload.action.argument);
            }
        }

    }
}
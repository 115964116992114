import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store/store";
import { TBanner, TContentItem } from "../../../../../../../types/typesStoreData";

export const useGetBannerItem = (type: string, contentItem?: TContentItem) => {
    const placeHolder: TBanner = {
        id: "banner_placeHolder",
        locale: "ru",
        createdAt: "",
        fetch: "success",
        varDesktop: {
            id: "banner_placeHolder_desktop",
            createdAt: "",
            locale: "ru",
            ratio: "text-8 / image-4",
            imageAlign: "center-center",
            content: `<h4 class="placeholder rounded-1">Banner title</h4> <p class="placeholder rounded-1 fs-7 my-1">Banner placeholder text</p>`,
        },
        varMobile: {
            id: "banner_placeHolder_mobile",
            createdAt: "",
            locale: "ru",
            ratio: "text-8 / image-4",
            imageAlign: "center-center",
            content: `<h4 class="placeholder rounded-1">Banner title</h4> <p class="placeholder rounded-1 fs-7 my-1">Banner placeholder text</p>`,
        },
        isPlaceholder: true
    }

    if (!contentItem) return placeHolder
    switch (type) {
        case "banners": return contentItem as TBanner
        default: return placeHolder
    }
}
import { all } from "redux-saga/effects";
import { sagaInitialWatch } from "./sagaInitial";
import { sagaLocaleFetch } from "./sagaLocaleFetch";
import { sagaCollectionFetch } from "./sagaCollectionFetch";
import { sagaPageViewsFetch } from "./sagaPageViewsFetch";
import { sagaViewFetch } from "./sagaViewFetch";
import { sagaDataUpdate } from "./sagaDataUpdate";


export function* sagaRoot() {
    yield all([
        sagaInitialWatch(),
        sagaLocaleFetch(),
        sagaCollectionFetch(),
        sagaPageViewsFetch(),
        sagaViewFetch(),
        sagaDataUpdate(),
    ])
}
import { Alert, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux"
import { translateTerm } from "../../../library/translateTerm";
import { getCollectionStatus, getDataItemByAttr } from "../../../store/storeGetters";
import { getCurrentLocale, getIfSettingsFetchStatus, getShowCookieAlert, setShowCookieAlert } from "../../../store/slices/sliceIfSettings"
import { RootState } from "../../../store/store";
import { TSiteProperties } from "../../../types/typesStructure";

export const SectionCookie: React.FC = () => {
    const locale = useSelector(getCurrentLocale);
    const props = useSelector((state: RootState) => getDataItemByAttr("siteProperties", "locale", locale, state)) as TSiteProperties;
    const propsStatus = useSelector((state: RootState) => getCollectionStatus("siteProperties", state));
    const showAlert = useSelector(getShowCookieAlert);
    const ifFetchStatus = useSelector(getIfSettingsFetchStatus)
    const dispatch = useDispatch();

    const show = propsStatus === "success" && ifFetchStatus !== "init" && showAlert !== false
    return show ? <div className="position-sticky bottom-0" style={{ zIndex: 1 }}>
        <div className="position-absolute w-100 bottom-0">
            <div className="container-lg">
                <Alert variant="info" className="shadow-sm my-3" show={show}>
                    <p>{props?.cookieAlert}</p>
                    <p className="text-center mb-0">
                        <Button className="ms-3" variant="outline-info" onClick={() => dispatch(setShowCookieAlert(false))}>
                            {translateTerm("accept", true)}
                        </Button>
                    </p>
                </Alert>
            </div>
        </div>
    </div> : null
}
import { TImage } from "../../types/typesBase"

export const getImageUrl = (image?: TImage, size?: "lg" | "md" | "sm" | "th"): string | undefined => {
    const host = document.location.hostname

    if (!image) return
    switch (size) {
        case "th": return `${host === "localhost" ? "http://rdc-data.local" : ""}${image.formats?.thumbnail?.url ||
            image.formats?.small?.url ||
            image.formats?.medium?.url ||
            image.formats?.large?.url ||
            image.url}`

        case "sm": return `${host === "localhost" ? "http://rdc-data.local" : ""}${image.formats?.small?.url ||
            image.formats?.medium?.url ||
            image.formats?.large?.url ||
            image.url}`

        case "md": return `${host === "localhost" ? "http://rdc-data.local" : ""}${image.formats?.medium?.url ||
            image.formats?.large?.url ||
            image.url}`

        case "lg": return `${host === "localhost" ? "http://rdc-data.local" : ""}${image.formats?.large?.url ||
            image.url}`

        default: return `${host === "localhost" ? "http://rdc-data.local" : ""}${image.url}`
    }
}
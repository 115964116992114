import _, { camelCase } from "lodash";
import { useState } from "react";
import { Accordion } from "react-bootstrap";
import { TContentItem } from "../../../../../types/typesStoreData";
import { TPageView } from "../../../../../types/typesStructure";
import { AccordionItem } from "./ViewItem/AccordionItem";


export const ViewAccordion: React.FC<{ view: TPageView, className?: string }> = ({ view, className }) => {
    //Получаю плский массив объектов представления
    const viewItems = view.viewItems && Object.values(view.viewItems).length ? (Object.values(view.viewItems) as TContentItem[][]).reduce((a, b) => [...a, ...b] as TContentItem[]) : undefined
    const [activeItem, setActiveItem] = useState<string>("0");

    //Получаю имя коллекции объектов представления из имени API
    const type = camelCase(view.api);

    return <Accordion id={`view_${view.id}`} defaultActiveKey={"0"} activeKey={activeItem} className={className}>
        {
            (viewItems || [...Array(5)].map(i => undefined)).map((item, i) => {
                switch (view.itemTemplate) {
                    case "accordion-item":
                        const key = i.toString()
                        return <AccordionItem key={`accodrionItem_${i}`} type={type} contentItem={item} onClick={() => setActiveItem(key)} eventKey={key} active={key === activeItem} />
                    default: return null
                }
            })
        }
    </Accordion>
}
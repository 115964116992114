import _, { cloneDeep } from "lodash"
import React from "react";
import { Col, Dropdown, FormGroup } from "react-bootstrap";
import { translateTerm } from "../../../../../../../../library/translateTerm";
import { TFormSchemeValue } from "../../../../../../../../store/slices/sliceIfSettings";
import { TFormInputSelect, TFormInputSelectValue, TFormInputValue } from "../../../../../../../../types/typesForm";
import { getTitleByValue } from "../../../../../../../library/InputSelect";
import { InputSelectMenu } from "./InputSelectMenu";

export const InputSelect: React.FC<{
    input: TFormInputSelect,
    handler: (value: TFormInputValue | undefined, noTimeout?: boolean) => void
    schemeValue: TFormSchemeValue,
}> = ({ input, handler, schemeValue }) => {
    const schemeInputValue = schemeValue.value as TFormInputSelectValue | undefined;
    const title = schemeInputValue
        ? getTitleByValue(schemeInputValue, input.options).replace(";", "; ")
        : <>
            {translateTerm(input.label || "select", true)}
            {input.required ? <span className="ms-2 text-danger">*</span> : ""}
        </>

    const updateInput = () => {
        const newInput = { ...cloneDeep(input), value: schemeInputValue };
        handler(newInput.value, true);
    }

    const oneClickClose = input.maxItems === 1 && input.options.filter(option => option.subOptions?.length).length === 0;

    return <FormGroup as={Col}
        title={input.label ? `${input.label} ${input.required ? "*" : ""}` : undefined}
        xs={input.size.replace("col-", "")}
        sm={input.bpSizes?.sm?.replace("col-sm-", "")}
        md={input.bpSizes?.md?.replace("col-md-", "")}
        lg={input.bpSizes?.lg?.replace("col-lg-", "")}
        xl={input.bpSizes?.xl?.replace("col-xl-", "")}
        xxl={input.bpSizes?.xxl?.replace("col-xxl-", "")}
        className="position-relative px-1 mb-3" >
        <Dropdown onToggle={updateInput} autoClose={oneClickClose ? true : "outside"}>
            <Dropdown.Toggle as="button"
                type="button"
                className={"form-control d-flex align-items-center text-start" + (schemeValue.validation === "invalid" ? " is-invalid" : "")}>
                <span className="flex-fill text-truncate">{title}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100 py-3 shadow-sm" >
                <InputSelectMenu
                    menuItems={input.options}
                    handler={handler}
                    inputValue={schemeInputValue || undefined}
                    maxItems={input.maxItems}
                    valueAsArray={input.valueAsArray} />
            </Dropdown.Menu>
        </Dropdown>
        {
            schemeValue.feedback && schemeValue.validation === "invalid"
                ? <div className="position-absolute px-2 start-0 text-center mt-0 lh-sm fs-7 text-truncate w-100 text-danger" >
                    {schemeValue.feedback}
                </div>
                : null
        }
    </FormGroup>

}
import _, { camelCase, compact, kebabCase } from "lodash"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { viewFetching } from "../../../../../store/actions/actionsExtra"
import { RootState } from "../../../../../store/store"
import { getActiveDataItem, getDataItem } from "../../../../../store/storeGetters"
import { TPageView } from "../../../../../types/typesStructure"
import { ViewStatic } from "./ViewStatic"

export const ViewDynamic: React.FC<{ view: TPageView, className?: string }> = ({ view, className }) => {
    const dispatch = useDispatch();
    const viewItemsNum = Object.values(view.viewItems || {}).length;
    const viewFetched = useSelector((state: RootState) => getDataItem("views", view.id, state)) as TPageView;
    const currentPathArray = view.currentPath
        ? compact(view.currentPath.split(".")).map(member => camelCase(member))
        : undefined;
    const baseCurrentObject = useSelector((state: RootState) => getActiveDataItem(currentPathArray?.[0] || "", state))
    const currentObject = baseCurrentObject && currentPathArray
        ? _.get({ [currentPathArray[0]]: baseCurrentObject }, currentPathArray.join("."))
        : undefined

    useEffect(() => {
        if (!viewFetched && !viewItemsNum && !view.hidden && !view.fetch) {
            dispatch(viewFetching({ view }))
        }
    }, [view])

    // if (view.id === "73") console.log(currentPathArray)
    const displayView = view.id.indexOf(".") !== -1 && viewFetched
        ? viewFetched
        : view.useCurrent && currentObject
            // ? { ...view, viewItems: { [camelCase(view.api)]: [currentObject] as TContentItem[] }, fetch: "success" } as TPageView
            ? {
                ...view,
                viewItems: {
                    [camelCase(view.api)]: Array.isArray(currentObject)
                        ? currentObject
                        : [currentObject]
                },
                fetch: "success",
            } as TPageView
            : view
    // if (view.id === "70") console.log(displayView)

    return <ViewStatic key={`staticView_${view.id}`} view={displayView} className={className} />
}

import _ from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../../../../store/store"
import { TFormInput, TFormInputValue } from "../../../../../../../types/typesForm"
import { FormDivider } from "./FormDivider"
import { InputColor } from "./InputColor"
import { InputDate } from "./InputDate"
import { InputImage } from "./InputImage/InputImage"
import { InputNumber } from "./InputNumber"
import { InputSelect } from "./InputSelect/InputSelect"
import { InputSimple } from "./InputSimple"
import { InputTextArea } from "./InputTextArea"
import { getActives } from "../../../../../../../store/storeGetters"
import { mutateDataItem } from "../../../../../../library/Mutation/mutateDataItem"
import { getFormInput, getFormSchemeValue, getFormState, setFormSchemeValue, setFormSchemeValueItem, setInputValidate, TFormSchemeValue } from "../../../../../../../store/slices/sliceIfSettings"
import { useEffect, useState } from "react"
import { validateInput } from "./validateForm"

type TProps = {
    inputID: string,
    formID: string,
    inputType: string,
    formPath: string,
}
export const Input: React.FC<TProps> = ({ inputID, formID, inputType, formPath }) => {
    const dispatch = useDispatch();
    const formState = useSelector((state: RootState) => getFormState(state, formID));
    const active = { ...useSelector(getActives), formState };
    const schemeValue = useSelector((state: RootState) => getFormSchemeValue(state, formID, inputID, inputType));
    const input = useSelector((state: RootState) => getFormInput(state, formID, formPath))
    const mutantInput = input ? (mutateDataItem(input, active)) as TFormInput : undefined;
    // const mutantInput = input;
    const [timer, setTimer] = useState<NodeJS.Timeout>();

    if (!schemeValue || !mutantInput || mutantInput.hidden) return null

    const handler = (value: TFormInputValue | undefined, noTimeout?: boolean, pattern?: string) => {
        if (schemeValue?.validation === "invalid") {
            dispatch(setInputValidate({ formID, inputID }))
        }
        switch (mutantInput.type) {
            case "color":
            case "image":
            case "file":
            case "checkbox":
            case "radio":
                dispatch(setFormSchemeValue({ formID, inputID, inputType, value }));
                break;
            case "divider":
                return;
            default:
                if (value && pattern) {
                    const regExp = new RegExp(pattern);
                    const check = regExp.test(value.toString())
                    console.log(regExp, check)
                    if (check) {
                        dispatch(setFormSchemeValue({ formID, inputID, inputType, value }));
                    }
                } else {
                    dispatch(setFormSchemeValue({ formID, inputID, inputType, value }));
                }
                if (timer) clearTimeout(timer);
                if (!noTimeout) {
                    const newTimer = setTimeout(() => validateInput(formID, inputID, mutantInput, mutantInput.feedback), 3000);
                    setTimer(newTimer)
                } else {
                    validateInput(formID, inputID, mutantInput, mutantInput.feedback);
                }
        }
    }

    switch (mutantInput.type) {
        case "date":
        case "datetime":
        case "datetime-local":
        case "time":
        case "week":
        case "month":
        case "time":
            return <InputDate {...{ input: mutantInput, handler: (v, t) => handler(v, t), schemeValue }} />
        case "number":
        case "range":
            return <InputNumber {...{ input: mutantInput, handler: (v, t) => handler(v, t), schemeValue }} />
        case "color":
            return <InputColor {...{ input: mutantInput, handler: (v, t) => handler(v, t), schemeValue }} />
        case "text-area":
            return <InputTextArea {...{ input: mutantInput, handler: (v, t) => handler(v, t), schemeValue }} />
        case "image":
            return <InputImage {...{ input: mutantInput, handler: (v, t) => handler(v, t), schemeValue }} />
        case "file":
            return <>File input</>
        case "checkbox":
        case "radio":
            return <>(*)</>
        case "select":
            return <InputSelect {...{ input: mutantInput, handler: (v, t) => handler(v, t), schemeValue }} />
        case "divider":
            return <FormDivider {...mutantInput} />
        default:
            return <InputSimple {...{ input: mutantInput, handler: (v, t) => handler(v, t, mutantInput.pattern), schemeValue }} />
    }
}
import _ from "lodash";
import { setActiveDataItem } from "../store/slices/sliceData";
import { store } from "../store/store";
import { TSiteProperties } from "../types/typesStructure";

export const setStatesByQuery = (siteProps: TSiteProperties, params: { [a: string]: string }, active: { [a: string]: string }) => {
    siteProps.acceptedParams?.forEach(param => {
        const value = _.get(params, param);
        if (active[param] !== value) {
            store.dispatch(setActiveDataItem({ apiName: param, ID: value }))
        }
    })
}
import { schema } from "normalizr";
import { schemaNormalizeBlock } from "./schemaNormalizeBlock";
import { schemaNormalizeLayout } from "./schemaNormalizeLayout";

export const schemaNormalizePage = (): schema.Entity<any> => {
    const layout = schemaNormalizeLayout();
    const block = schemaNormalizeBlock();
    return new schema.Entity('pages', {
        layouts: [layout],
        blocks: [block]
    })
}
import _ from "lodash";

type TObjValue = string | number | boolean | object
export type TFlattenObj = { [a: string]: TObjValue | TObjValue[] }

export const flattenObj = (obj: TFlattenObj): TObjValue[] => {
  return _.reduce(obj, (r, v, k) => {
    switch (typeof v) {
      case 'object':
        if (_.isArray(v)) {
          return [
            ...r,
            ...v
              .map((item) => flattenObj(item))
              .reduce((ar, av, ak) => [...ar, ...av], []),
          ];
        }
        if (_.isPlainObject(v)) {
          return [...r, ...flattenObj(v as TFlattenObj)];
        }
        return [...r];
      case "string":
        if (k === "id") {
          return [...r];
        } else {
          return [...r, v];
        }
      case "number":
      case "boolean":
        return [...r, v]
      default:
        return [...r];
    }
  }, [] as TObjValue[]);
};
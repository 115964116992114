import { compact } from "lodash";
import { TContact, TContentItem, TLink } from "../../../../../../../types/typesStoreData";
import { getContactAttributes } from "../../../../../../library/getContactAttributes";
import { TTextLinkViewItem } from "./TextLink";

export const getTextLink = (type: string, contentItem?: TContentItem): TTextLinkViewItem => {
    const placeHolder: TTextLinkViewItem = {
        id: "placeholder",
        link: "#",
        title: "Link placeholder",
        disabled: false,
        isPlaceHolder: true,
    }
    switch (type) {
        case "links":
            const textLink = contentItem as TLink;
            return {
                ...placeHolder,
                id: textLink.id,
                title: textLink.title,
                description: textLink.description,
                icons: compact([textLink.iconNormal, textLink.iconHover, textLink.iconActive]),
                action: textLink.action,
                disabled: textLink.disabled,
                isPlaceHolder: false,
                link: textLink.url,
            }
        case "contacts":
            const phoneLink = contentItem as TContact;
            const props = getContactAttributes(phoneLink);
            return {
                ...placeHolder,
                id: phoneLink.id,
                title: props?.title || phoneLink.title,
                description: phoneLink.description,
                icons: compact([props?.icon]),
                disabled: false,
                isPlaceHolder: false,
                link: props?.url || phoneLink.url,
            }
        default: return placeHolder
    }
}
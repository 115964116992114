import { MouseEventHandler } from "react"
import { Link } from "react-router-dom"
import { getAction } from "../../actions/getAction"
import { TButton } from "../../types/typesStoreData"

export const Button: React.FC<{ button: TButton, className?: string }> = ({ button, className }) => {
    const classNameAll = `btn btn-${button.template} ${className || ""}`

    const content = <>
        {button.link.iconNormal ? <span className={button.link.iconNormal + " me-2 fs-7"} /> : null}
        {button.link.title}
    </>
    const handler: MouseEventHandler = (e) => {
        if (button.link.action) {
            if (button.link.type === "action") e.preventDefault();
            getAction(button.link.action.name)(button.link.action.argument);
        }
    }

    switch (button.link.type) {
        case "local":
            return <Link to={button.link.url && !button.link.disabled ? button.link.url : ""}
                className={classNameAll}
                onClick={handler}>
                {content}
            </Link>
        case "external":
            return <a href={button.link.url && !button.link.disabled ? button.link.url : undefined}
                className={classNameAll}
                onClick={handler}>
                {content}
            </a>
        case "action":
            return <a href={!button.link.disabled ? "#" : undefined}
                className={classNameAll}
                onClick={handler}>
                {content}
            </a>
    }
}
import _, { upperCase } from "lodash";
import { Link } from "react-router-dom";
import { TContentItem } from "../../../../../../../types/typesStoreData"
import { getCollectionCard } from "./getCollectionCard";

export type TCollectionCardViewItem = {
    id: string,
    title: string,
    subTitle?: string,
    description?: string,
    logo?: string,
    logoBgColor?: string,
    bannerBgColor?: string,
    logoShape: "square" | "circle",
    banner?: string,
    isPlaceholder?: boolean,
    disabled?: boolean,
    action?: (a?: any) => void,
    url?: string,
}

export const CollectionCard: React.FC<{ contentItem?: TContentItem, type: string, single?: boolean }> = ({ contentItem, type, single }) => {
    const card = getCollectionCard(contentItem, type)

    const interAct = (card.action || card.url) && !card.disabled;

    const content = <div className={"bg-white overflow-hidden position-relative" + (single ? "" : " shadow-sm rounded-1")}>

        <div className={"ratio w-100 overflow-hidden bg-img-cover rounded-top"
            + (card.logoShape === "circle" ? " ratio-4x1" : " ratio-3x1")
            + (card.isPlaceholder ? " bg-midlight" : " bg-white")}
            style={{ backgroundImage: card.banner ? `url(${card.banner})` : `linear-gradient(90deg, ${card.bannerBgColor || "rgb(var(--bs-midlight-rgb))"}, transparent)` }} />

        <div className="position-relative px-4 pb-3 pt-4">
            {
                interAct
                    ? <Link to={card.url || "#"}
                        className={"d-block position-absolute bottom-100 mb-n4 shadow-sm"
                            + (card.logoShape === "circle" ? " rounded-circle" : " rounded-1")
                            + (!card.logo || (card.logo && !card.logoBgColor) ? " bg-white" : "")}
                        style={card.logo && card.logoBgColor ? { backgroundColor: card.logoBgColor } : undefined}>

                        <div className={"ratio ratio-1x1 thumb-xs thumb-sm-sm overflow-hidden m-1"
                            + (card.logoShape === "circle" ? " bg-img-cover rounded-circle" : " bg-img-contain rounded-1")
                            + (!card.logoBgColor ? " bg-white" : "")}
                            style={{ backgroundImage: card.logo ? `url(${card.logo})` : `linear-gradient(0deg, ${card.logoBgColor || "rgb(var(--bs-secondary-rgb))"}, transparent)` }} >
                            {
                                !card.logo
                                    ? <div className="d-flex">
                                        <span className="display-5 fw-bold lh-1 mx-auto my-auto text-white">{card.title.split(" ").map((item, i) => i <= 1 ? _.upperCase(item[0]) : null)}</span>
                                    </div>
                                    : null
                            }
                        </div>
                    </Link>

                    : <div className={"position-absolute bottom-100 mb-n4 shadow-sm"
                        + (card.logoShape === "circle" ? " rounded-circle" : " rounded-1")
                        + (!card.logo || (card.logo && !card.logoBgColor) ? " bg-white" : "")}
                        style={card.logo && card.logoBgColor ? { backgroundColor: card.logoBgColor } : undefined}>

                        <div className={"ratio ratio-1x1 thumb-xs thumb-sm-sm overflow-hidden m-1"
                            + (card.logoShape === "circle" ? " bg-img-cover rounded-circle" : " bg-img-contain rounded-1")
                            + (!card.logoBgColor ? " bg-white" : "")}
                            style={{ backgroundImage: card.logo ? `url(${card.logo})` : `linear-gradient(0deg, ${card.logoBgColor || "rgb(var(--bs-secondary-rgb))"}, transparent)` }} >
                            {
                                !card.logo
                                    ? <div className="d-flex">
                                        <span className="display-5 fw-bold lh-1 mx-auto my-auto text-white">{card.title.split(" ").map((item, i) => i <= 1 ? _.upperCase(item[0]) : null)}</span>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
            }

            {
                interAct ?
                    <Link to={card.url || "#"} className={"h5 d-flex align-items-center mb-0 pt-2 text-secondary text-hover-warning"}>
                        <span className="flex-fill text-truncate" title={card.title}>{card.title}</span>
                        <span className="d-block icon-action-return fs-6 lh-1 ms-n1" style={{ transform: "rotate(180deg)" }} />
                    </Link> :
                    <h5 className={"d-block mb-0 pt-2 text-secondary text-truncate"}>
                        <span className={card.isPlaceholder ? " placeholder" : ""}>{card.title}</span>
                    </h5>
            }

            <span className={"d-block fs-7 text-secondary text-truncate w-100" + (card.isPlaceholder ? " placeholder fs-8 my-1" : "")}>{card.subTitle}</span>
        </div>
    </div >

    return <div className={(!single ? "p-1 rounded col-12 col-md-6" : "") + (card.isPlaceholder ? " placeholder-wave" : card.disabled ? " opacity-50" : interAct ? " bg-hover-light" : "")}>
        {content}
    </div>
}


import _ from "lodash";
import { Link } from "react-router-dom";
import { TContentItem } from "../../../../../../../types/typesStoreData";
import { getCollectionRow } from "./getCollectionRow";

export type TCollectionRowViewItem = {
    id: string,
    title: string,
    subTitle?: string,
    description?: string,
    logo?: string,
    logoBgColor?: string,
    logoShape: "square" | "circle",
    isPlaceholder?: boolean,
    disabled?: boolean,
    action?: (a?: any) => void,
    url?: string,
}

export const CollectionRow: React.FC<{ contentItem?: TContentItem, type: string }> = ({ contentItem, type }) => {
    const row = getCollectionRow(contentItem, type);

    const content = <>
        <div className={"position-relative m-1 shadow-sm"
            + (row.logoShape === "circle" ? " rounded-circle" : " rounded-1")
            + (!row.logo || (row.logo && !row.logoBgColor) ? " bg-white" : "")}
            style={row.logo && row.logoBgColor ? { backgroundColor: row.logoBgColor } : undefined}>

            <div className={"ratio ratio-1x1 avatar-md overflow-hidden m-1"
                + (row.logoShape === "circle" ? " bg-img-cover rounded-circle" : " bg-img-contain rounded-1")
                + (!row.logoBgColor ? " bg-white" : "")}
                style={{ backgroundImage: row.logo ? `url(${row.logo})` : `linear-gradient(0deg, ${row.logoBgColor || "rgb(var(--bs-secondary-rgb))"}, transparent)` }} >
                {
                    !row.logo
                        ? <div className="d-flex">
                            <span className="fs-4 fw-bold lh-1 mx-auto my-auto text-white">{row.title.split(" ").map((item, i) => i <= 1 ? _.upperCase(item[0]) : null)}</span>
                        </div>
                        : null
                }
            </div>
        </div>

        <div className="d-flex flex-fill flex-column justify-content-center flex-sm-row">
            <div className="position-relative flex-sm-fill py-3">
                <div className="position-absolute w-100 h-100 d-flex flex-column justify-content-center mx-2 top-0">
                    <div className="text-truncate lh-1">
                        <span className={row.isPlaceholder ? " placeholder rounded my-1" : ""}>{row.title}</span>
                    </div>
                    {
                        row.subTitle
                            ? <div className="text-truncate small text-muted fst-italic lh-1">
                                <span className={row.isPlaceholder ? " placeholder rounded my-1" : ""}>{row.subTitle}</span>
                            </div>
                            : null
                    }

                </div>
            </div>
            {/* 
            <div className="d-flex align-items-center mx-2">
            </div>
             */}
        </div>
    </>

    return <>{!row.url && !row.action
        ? <div className={"col-12 px-0 d-flex rounded show-hover bg-hover-light"
            + (row.isPlaceholder ? " placeholder-wave" : "")}>
            {content}
        </div>
        : <Link to={row.url || "#"} className={"col-12 px-0 d-flex rounded show-hover bg-hover-light"
            + (row.isPlaceholder ? " placeholder-wave" : "")}
            onClick={row.action}>
            {content}
        </Link>
    }</>

}
import { store } from "../../../../../../../store/store";
import { TContentItem } from "../../../../../../../types/typesStoreData";
import { TSitePageNormalized } from "../../../../../../../types/typesStructure";
import { TWorkGroup } from "../../../../../../../types/typesWorkGroup";
import { getImageUrl } from "../../../../../../library/getImageUrl";
import { TCollectionRowViewItem } from "./CollectionRow";

export const getCollectionRow = (contentItem?: TContentItem, type?: string): TCollectionRowViewItem => {
    const placeHolder: TCollectionRowViewItem = {
        id: "placeHolder",
        title: "Placeholder title",
        subTitle: "Placeholder subtitle",
        description: "Placeholder description",
        logo: undefined,
        logoBgColor: undefined,
        logoShape: "square",
        isPlaceholder: true,
        disabled: false,
        action: undefined,
        url: undefined,
    }
    const sitePages = Object.values(store.getState().sliceData.collections.pages || {}) as TSitePageNormalized[];
    const rowPageUrl = sitePages.find(item => item.name === `${type}_item`)?.url
    const rowLink = rowPageUrl && contentItem
        ? `${rowPageUrl}?${type}=${contentItem.id}`
        : undefined

    if (!contentItem) return placeHolder;

    switch (type) {
        case "workgroups":
            const workGroup = contentItem as TWorkGroup;
            return {
                ...placeHolder,
                id: workGroup.id,
                title: workGroup.card?.title || workGroup.label,
                subTitle: workGroup.card?.slogan,
                description: workGroup.card?.description,
                logo: workGroup.card?.logo?.fileData,
                logoBgColor: workGroup.card?.logoBgColor,
                logoShape: "square",
                isPlaceholder: false,
                disabled: false,
                url: rowLink,
            }
        default: return placeHolder;
    }
}
import { setModal, setShowModal } from "../../store/slices/sliceIfSettings"
import { store } from "../../store/store"
import { TModal } from "../../template/typesTemplate"
import { TPageView } from "../../types/typesStructure"

type TProps = { url: string, title: string }

export const actionShowObjectByURL = (props: TProps) => {

    const view: TPageView = {
        id: "modalCustom",
        fetch: "success",
        locale: "",
        createdAt: "",
        query: {},
        api: "publications",
        viewTemplate: "default",
        itemTemplate: "publication",
        viewItems: {
            publications: [
                {
                    id: "ModalCustomContent",
                    locale: "",
                    createdAt: "",
                    content: `<div class="ratio ratio-16x9"><object class="w-100" data=${props.url}/></div>`,
                }
            ]
        }
    }

    const modal: TModal = {
        title: props.title,
        view,
        size: "xl"
    }

    store.dispatch(setModal(modal))
    store.dispatch(setShowModal(true))
}
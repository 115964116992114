import { TPageBlock } from "../../types/typesStructure";

export const newsPlaceholder: TPageBlock = {
    id: "newsPHBlock",
    locale: "ru",
    placeholder: true,
    template: "default",
    section: "content",
    createdAt: "",
    views: [
        {
            id: "newsPHView",
            locale: "ru",
            createdAt: "",
            api: "publications",
            viewTemplate: "accordion",
            itemTemplate: "accordion-item",
            query: {},
            weight: 10,
            viewItems: {
                // publications: [undefined, undefined] as unknown as TContentItem[]
            },
        },
    ],
}
import _ from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { initialFetching, viewsFetching } from "../store/actions/actionsExtra";
import { getActiveDataItem, getActives, getCollectionStatus, getCollectionStatuses, getDataItemByAttr, getDataItems } from "../store/storeGetters";
import { getCurrentLocale } from "../store/slices/sliceIfSettings";
import { template } from "./template";
import { setActiveDataItem } from "../store/slices/sliceData";
import { RootState, store } from "../store/store";
import { TPageView, TSitePage, TSiteProperties } from "../types/typesStructure";
import { mutateDataItem } from "./library/Mutation/mutateDataItem";
import { setStatesByQuery } from "../library/pageQueryParams";
import { checkObjectPermissions } from "./library/checkPermissions";
import { getAction } from "../actions/getAction";


export const Template: React.FC = () => {
    const location = document.location.pathname
    const dispatch = useDispatch()
    const locale = useSelector(getCurrentLocale);
    const active = useSelector(getActives);
    const currentPage = useSelector((state: RootState) => getActiveDataItem("pages", state) as TSitePage);
    // const pagesStatus = useSelector((state: RootState) => getCollectionStatus("pages", state));
    const fetchStatuses = useSelector(getCollectionStatuses);
    const currentUrlPage = useSelector((state: RootState) => getDataItemByAttr("pages", "url", location, state) as TSitePage);
    const siteProps = useSelector((state: RootState) => getDataItemByAttr("siteProperties", "locale", locale, state) as TSiteProperties);
    const navigate = useNavigate();
    const searchParams = Object.fromEntries(new URLSearchParams(document.location.search));
    const mutantPage = currentPage ? mutateDataItem(currentPage, active) as TSitePage : currentPage;
    const sitePages = useSelector((state: RootState) => getDataItems("pages", undefined, state) as TSitePage[])

    useEffect(() => {
        if (!Object.values(fetchStatuses).length) {
            dispatch(initialFetching());
        }
    }, [fetchStatuses])

    useEffect(() => {
        switch (fetchStatuses.pages) {
            // case undefined: dispatch(initialFetching()); break;
            case "error": navigate("/520"); break;
            case "success":
                if (location === "/") {
                    navigate(sitePages.find(item => item.name === "main")?.url || "/520")
                } else {
                    if (currentUrlPage) {
                        if (currentPage?.id !== currentUrlPage.id) {
                            if (checkObjectPermissions("pages", currentUrlPage.id, "view")) {
                                dispatch(setActiveDataItem({ apiName: "pages", ID: currentUrlPage.id }))
                            } else {
                                navigate(sitePages.find(item => item.name === "error403")?.url || "/520")
                            }
                        }
                    } else {
                        navigate(sitePages.find(item => item.name === "error404")?.url || "/520")
                    }
                }
        }
    }, [fetchStatuses.pages, location])

    useEffect(() => {
        if (siteProps) {
            const siteTitle = siteProps.title;
            const pageTitle = mutantPage?.title;
            const siteSlogan = siteProps.slogan;
            const pageDescription = mutantPage?.description;
            if ((siteTitle || pageTitle) && (siteSlogan || pageDescription)) {
                document.title = `${pageTitle || siteTitle} | ${pageDescription || siteSlogan}`
            }
        }

        if (fetchStatuses.pages === "success" && mutantPage && mutantPage.fetch !== "success") {
            dispatch(viewsFetching({ page: mutantPage }))
        }
    }, [mutantPage])

    useEffect(() => {
        if (fetchStatuses.pages === "success") {
            setStatesByQuery(siteProps, searchParams, active.id)
        }
    }, [location, currentPage?.fetch, searchParams])

    useEffect(() => {
        if (currentPage && currentPage.fetch === "success" && currentPage.onLoadAction) {
            // const pageViews = _.reduce(currentPage, (res) => {


            //     return [...res, var]
            // }, [] as TPageView[])

            // const action = getAction(currentPage.onLoadAction?.name);
            // action(currentPage.onLoadAction?.props);
        }

    }, [currentPage])
    return mutantPage?.template && template.page[mutantPage.template] ?
        <>{template.page[mutantPage.template]({})}</> :
        <>{template.page.default({})}</>
}
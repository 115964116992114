import _, { camelCase, cloneDeep } from "lodash";
import { normalize } from "normalizr";
import QueryString from "qs";
import { call, put } from "redux-saga/effects";
import { IDsToString } from "../../library/backendObjectsFix";
import { TBackendObject, TBackendObjectData } from "../../types/typesBackendData";
import { TApiQuery } from "../../types/typesBase";
import { TContentItem } from "../../types/typesStoreData";
import { TApiRoute, TPageView } from "../../types/typesStructure";
import { viewFetching, viewFetchingPending, viewFetchingRejected, viewFetchingSucceed } from "../actions/actionsExtra";
import { schemaNormalizeView } from "../normalizers/schema/schemaNormalizeView";
import { apiFetchData, TRequestProps } from "../requests/apiFetch";
import { TDataStore } from "../slices/sliceData";
import { store } from "../store";

export function* viewFetchWorker({ payload }: ReturnType<typeof viewFetching>) {
    const view = payload.view;
    const viewItemsNum = Object.values(view.viewItems || {}).length
    const apiRoutes = store.getState().sliceData.collections.apiRoutes;
    const routeUrl = (Object.values(apiRoutes) as unknown as TApiRoute[]).find(item => item.storageName === camelCase(view.api))?.url
    const jwt = store.getState().sliceIfSettings.jwt;

    if ((!view.fetch && !viewItemsNum && view.api) || (view.api && viewItemsNum && payload.nextPage)) {
        yield put(viewFetchingPending({ ...view, fetch: "progress" }));

        const getPagination = (): TApiQuery["pagination"] | undefined => {
            if (!payload.nextPage) return view.query.pagination;
            if (view.pagination && view.pagination.page < view.pagination.pageCount) return { page: view.pagination.page + 1, pageSize: view.pagination.pageSize }
        }

        const query = QueryString.stringify(
            {
                ...view.query,
                locale: view.locale,
                pagination: getPagination()
            }
        )

        const currentHostName = document.location.hostname
        const requestHostName = currentHostName === "localhost" ? "http://localhost:8000" : `${document.location.protocol}//${document.location.hostname}`
        const request: TRequestProps = {
            url: `${requestHostName}${routeUrl}${query ? "/?" + query : ""}`,
            method: "GET",
            responseFormat: "json"
        }

        const fetchObject: TBackendObject = yield call(() => apiFetchData(jwt ? { ...request, headers: { ...request.headers, Authorization: `Bearer ${jwt}` } } : request));

        if (fetchObject.error) {
            yield put(viewFetchingRejected({ ID: view.id, response: fetchObject.error.message }));
        } else {
            const apiName = camelCase(view.api)
            const newView = cloneDeep(view);
            const isArray = fetchObject.data && Array.isArray(fetchObject.data)

            const fixedIDsData = (apiName === "locales"
                ? IDsToString(fetchObject as unknown as TBackendObjectData[])
                : !isArray
                    ? IDsToString({ ...fetchObject.data })
                    : IDsToString(fetchObject.data)) as TContentItem[];

            newView.fetch = "success"
            newView.isPlaceholder = false
            newView.pagination = fetchObject.meta.pagination
            newView.viewItems = {
                [apiName]: payload.nextPage ? [...view.viewItems[apiName], ...fixedIDsData] : fixedIDsData
            }
            const normalizedData = normalize(newView, schemaNormalizeView()).entities
            yield put(viewFetchingSucceed(normalizedData as TDataStore["collections"]))

        }
    }
}
import React from "react";
import { useSelector } from "react-redux";
import { getCurrentLocale } from "../../../../store/slices/sliceIfSettings";
import { RootState } from "../../../../store/store";
import { getCollectionStatus, getDataItemByAttr } from "../../../../store/storeGetters";
import { TSiteProperties } from "../../../../types/typesStructure";

export const FooterMenuContacts: React.FC = () => {
    const locale = useSelector(getCurrentLocale);
    const props = useSelector((state: RootState) => getDataItemByAttr("siteProperties", "locale", locale, state)) as TSiteProperties;
    const propsStatus = useSelector((state: RootState) => getCollectionStatus("siteProperties", state));

    return props?.contactsMenuItems.length || propsStatus !== "success" ?
        <div className={`d-flex flex-column justify-content-center mb-3 mb-lg-0`}>
            {
                propsStatus === "success" ?
                    props?.contactsMenuItems.map((contact, i) => {
                        let iconClassName: string | undefined = undefined;
                        const contactLinkPrefix = contact.url?.split("://")[0]
                        switch (contactLinkPrefix) {
                            case "mailto": iconClassName = "icon-contacts-mail me-2"; break;
                            case "tel": iconClassName = "icon-contacts-phone me-2"; break;
                            case "http": iconClassName = "icon-contacts-url me-2"; break;
                            case "https": iconClassName = "icon-contacts-url me-2"; break;
                        }

                        return <a key={`contact_${contact.id}`}
                            className={`text-reset text-hover-warning fst-italic ${i < props?.contactsMenuItems.length ? " mb-1" : ""}`}
                            href={contact.url}
                            rel="noreferrer"
                            target="_blank"
                            title={contact.description}>
                            {iconClassName ? <span className={iconClassName} /> : null}

                            {contact.url?.split("://").splice(1, 1).join()}
                        </a>
                    }) :

                    [...Array(3)].map((ph, i) => <a key={`contact-placeholder_${i}`}
                        id={`contact-placeholder_${i}`}
                        className={`d-flex text-reset text-hover-warning fs-7  placeholder-wave ${i < 2 ? "mb-2" : ""}`}
                        href="#">
                        <span style={{ width: "1.5em" }} className="d-block me-2 placeholder">&nbsp;</span>
                        <span className="placeholder">Contact Placeholder</span>
                    </a>
                    )
            }
        </div>
        : null
    return null
}
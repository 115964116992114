import { Link } from "react-router-dom";
import { TContentItem } from "../../../../../../types/typesStoreData"
import { getViewItemData } from "./getViewItemData";

export const CatalogFolder: React.FC<{ type: string, contentItem?: TContentItem }> = ({ type, contentItem }) => {
    const cardFolder = getViewItemData(type, contentItem)
    const interAct = cardFolder.onClick || cardFolder.link;
    const content = <div className={"shadow-sm rounded-1 overflow-hidden position-relative bg-white"
        + (interAct ? " opacity-hover" : "")}>
        <div className={"ratio ratio-1x1 opacity-hover-content bg-img-cover" + (cardFolder.isPlaceholder ? " bg-midlight" : "")}
            style={cardFolder.banner ? { backgroundImage: `url(${cardFolder.banner})` } : undefined} />
        <div className="d-flex p-2 align-items-end">
            <div className="pt-5" />
            <p className={"mb-0 lh-1 fs-7 text-start" + (cardFolder.isPlaceholder ? " placeholder rounded-1" : "")}>{cardFolder.title}</p>
        </div>
    </div >

    if (interAct) {
        return <Link className={"btn border-0 p-1 rounded thumb-lg" + (cardFolder.isPlaceholder ? " placeholder-wave" : cardFolder.disabled ? " disabled opacity-50" : " bg-hover-light opacity-hover")}
            to={cardFolder.link || "#"}>
            {content}
        </Link>
    } else {
        return <div className={"p-1 rounded thumb-lg " + (cardFolder.isPlaceholder ? " placeholder-wave" : cardFolder.disabled ? " opacity-50" : "")}>
            {content}
        </div>
    }
}
import _ from "lodash";
import { validate } from "../../../../../../../library/Form/validate";
import { translateTerm } from "../../../../../../../library/translateTerm";
import { cleanupValidates, setInputValidate } from "../../../../../../../store/slices/sliceIfSettings";
import { store } from "../../../../../../../store/store"
import { TFormInput } from "../../../../../../../types/typesForm";

export const validateInput = (formID: string, inputID: string, input: TFormInput, feedback?: string) => {
    // store.dispatch(cleanupValidates({ formID }));
    const schemeValues = store.getState().sliceIfSettings.formSchemes[formID]?.values;
    const schemeValue = schemeValues?.find(item => item.id === inputID && item.type === input.type);
    if (!schemeValue) return;
    const value = schemeValue.value;
    const idArray = inputID.split("\.");

    const group = schemeValues.filter(item => {
        if (schemeValue.id === item.id || schemeValue.type !== item.type) return false;
        const inputIdArray = item.id.split("\.");
        if (idArray.length === inputIdArray.length) {
            return idArray.length === 1
                ? true
                : idArray.slice(0, -1).join("\.") === inputIdArray.slice(0, -1).join("\.")
        } else { return false }
    })

    const pairSchemeValue = schemeValue.name.indexOf("Confirmation") !== -1
        ? _.find(group, { name: schemeValue.name.replace("Confirmation", "") })
        : _.find(group, { name: schemeValue.name + "Confirmation" });

    if (pairSchemeValue?.value && pairSchemeValue.value !== value) {
        store.dispatch(setInputValidate({ formID, inputID: schemeValue.id, validation: "invalid", feedback: translateTerm("the values don't match", true) }));
        store.dispatch(setInputValidate({ formID, inputID: pairSchemeValue.id, validation: "invalid", feedback: translateTerm("the values don't match", true) }));
        return;

    }

    if (input.required && !value) {
        store.dispatch(setInputValidate({ formID, inputID: schemeValue.id, validation: "invalid", feedback: translateTerm("required value", true) }));
        return;
    }

    if (input.validator && value && (typeof value === "string" || typeof value === "number")) {
        const isValid = validate[input.validator](value, input.pattern)
        if (!isValid) {
            store.dispatch(setInputValidate({ formID, inputID: schemeValue.id, validation: "invalid", feedback: translateTerm(feedback || "wrong format", true) }));
            return;
        }
    }

    store.dispatch(setInputValidate({ formID, inputID: schemeValue.id }));
    if (pairSchemeValue) store.dispatch(setInputValidate({ formID, inputID: pairSchemeValue.id }));
}
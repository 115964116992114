import { TContentItem } from "../../types/typesStoreData";
import { TPageBlock } from "../../types/typesStructure";

export const topBannerPlaceholder: TPageBlock = {
    id: "TopBannerPHBlock",
    locale: "ru",
    placeholder: true,
    template: "banner",
    section: "banner",
    createdAt: "",
    views: [
        {
            id: "TopBannerPHView",
            locale: "ru",
            createdAt: "",
            api: "banners",
            viewTemplate: "default",
            itemTemplate: "banner",
            query: {},
            viewItems: {
                banners: [undefined] as unknown as TContentItem[]
            },
        }
    ],
}
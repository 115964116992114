import QueryString from "qs";
import { call, put } from "redux-saga/effects";
import { TBackendObject } from "../../types/typesBackendData";
import { apiFetchData, TRequestProps } from "../requests/apiFetch";
import { denormalize, normalize } from "normalizr";
import { TContentItem } from "../../types/typesStoreData";
import _, { camelCase, cloneDeep } from "lodash";
import { store } from "../store";
import { IDsToString } from "../../library/backendObjectsFix";
import { schemaNormalize } from "../normalizers/schemaNormalize";
import { TDataStore } from "../slices/sliceData";
import { viewsFetching, viewsFetchingPending, viewsFetchingSucceed } from "../actions/actionsExtra";
import { TApiRoute, TPageBlock, TPageView, TSitePage, TSiteProperties } from "../../types/typesStructure";
import { schemaNormalizePage } from "../normalizers/schema/schemaNormalizePage";
import { schemaNormalizeSiteProperty } from "../normalizers/schema/schemaNormalizeSiteProperties";

const getCurrentPage = () => {
    const pageID = store.getState().sliceData.activeItems.pages;
    if (!pageID) return;
    const page = store.getState().sliceData.collections.pages?.[pageID];
    if (!page) return;
    return denormalize(page, schemaNormalizePage(), store.getState().sliceData.collections) as TSitePage;
}

const getSiteProps = () => {
    const locale = store.getState().sliceIfSettings.locale;
    const props = Object.values(store.getState().sliceData.collections.siteProperties || {}).find(item => item.locale === locale)
    if (!props) return;
    return denormalize(props, schemaNormalizeSiteProperty(), store.getState().sliceData.collections) as TSiteProperties;
}

export function* pageViewsFetchWorker({ payload }: ReturnType<typeof viewsFetching>) {
    const page = payload.page || getCurrentPage() as TSitePage
    if (!page) return;
    const locale = store.getState().sliceIfSettings.locale;
    const apiRoutes = store.getState().sliceData.collections.apiRoutes;
    const jwt = store.getState().sliceIfSettings.jwt;
    const sitePermBlocks = getSiteProps()?.permanentBlocks

    const pageBlocks = [
        ...(page.blocks || []),
        ...(sitePermBlocks || []),
        ...page.layouts.reduce((res, val) => [
            ...res,
            ...(val.blocks || [])
        ], [] as TPageBlock[])
    ];

    const pageViews = pageBlocks
        .filter(block => !block.hidden)
        .reduce((res, val) => [
            ...res,
            ...(val.views || [])
        ], [] as TPageView[])
        .filter(view => !view.hidden)


    // const pageViewItems = pageViews.reduce((res, val) => {
    //     const viewItems = _.reduce(val.viewItems, (vRes, vVal) => [
    //         ...vRes,
    //         ...vVal,
    //     ], [] as TContentItem[])
    //     return [...res, ...viewItems]
    // }, [] as TContentItem[])

    if (page.fetch !== "success" && page.fetch !== "progress") {
        // console.log("Views fetch")

        yield put(viewsFetchingPending(page.id))
        const fetchedViews: TBackendObject[] = yield call(() => Promise.all(
            pageViews.map(async (view) => {
                const routeUrl = (Object.values(apiRoutes) as unknown as TApiRoute[]).find(item => item.storageName === camelCase(view.api))?.url
                if (view.query && view.fetch !== "success" && view.fetch !== "progress") {
                    // console.log(view.id)
                    const query = QueryString.stringify(
                        {
                            ...view.query,
                            locale: locale,
                        }
                    )
                    const currentHostName = document.location.hostname
                    const requestHostName = currentHostName === "localhost" ? "http://localhost:8000" : `${document.location.protocol}//${document.location.hostname}`
                    const request: TRequestProps = {
                        url: `${requestHostName}${routeUrl}${query ? "/?" + query : ""}`,
                        method: "GET",
                        responseFormat: "json"
                    }

                    const fetchObject = await apiFetchData(jwt ? { ...request, headers: { ...request.headers, Authorization: `Bearer ${jwt}` } } : request);

                    if (fetchObject.error) {
                        const errorView = cloneDeep(view);
                        return { ...errorView, fetch: "error" }
                    } else {
                        // if (view.api === "links") console.log(view.id, view.query, fetchObject.data)
                        const successView = cloneDeep(view) as unknown as TPageView;
                        // successView.fetch = "success";
                        const apiName = camelCase(view.api);
                        successView.viewItems = (IDsToString(fetchObject.data) as TContentItem[])
                            .reduce((a, b) => (
                                {
                                    ...a,
                                    [apiName]: [...(a[apiName] || []), b]
                                }
                            ), {} as { [a: string]: object[] }) as TPageView["viewItems"]
                        return { ...successView, fetch: "success", isPlaceholder: false, pagination: fetchObject.meta.pagination };
                    }
                }
            })
        ))
        const schema = schemaNormalize("views")
        const normalizedViewsData = schema
            ? normalize({ ["views"]: fetchedViews }, schema()).entities
            : { ["views"]: fetchedViews };
        // console.log("Views fetch success")
        yield put(viewsFetchingSucceed({ ID: page.id, collections: normalizedViewsData as TDataStore["collections"] }))
    }
}

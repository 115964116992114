import { TContentItem, TPublication } from "../../../../../../../types/typesStoreData";
import { TWGCard, TWorkGroup } from "../../../../../../../types/typesWorkGroup";

export const getPublicationItem = (type: string, contentItem?: TContentItem): TPublication => {
    const placeHolderText = "Come placeholder publication text.".split(" ").map(item => `<span class="placeholder rounded-1 fs-7 my-1">${item}</span>`).join(" ")
    const placeHolder: TPublication = {
        id: "banner_placeholder",
        locale: "",
        createdAt: "",
        content: `<div class="bg-img-cover placeholder-wave d-flex flex-column flex-sm-row">
        <div class="col-12 col-sm-4 me-sm-3 mb-3 mb-sm-0 float-start">
        <div class="ratio ratio-4x3 bg-midlight rounded-1"></div>
        </div>
        <div class="opacity-50">
        <h2 class="placeholder rounded-1">Some title</h2>
        <p class="mb-0">${[...Array(8)].map(item => placeHolderText).join(" ")}</p>
        </div>
        </div>`,
    }

    if (!contentItem) return placeHolder
    switch (type) {
        case "publications": return contentItem as TPublication
        case "workgroupCards":
            const card = contentItem as TWGCard
            return {
                id: card.id,
                locale: card.locale,
                content: card.description || "",
            } as TPublication
        case "workgroups":
            const group = contentItem as TWorkGroup
            return {
                id: group.card?.id || group.id,
                locale: group.locale,
                content: group.card?.description || "",
            } as TPublication
        case "wgCardDescriptions":
            const description = contentItem as unknown as string;
            return {
                id: "card_description",
                locale: "",
                content: description,
            } as TPublication
        case "userCardDescriptions":
            const userCardDescription = contentItem as unknown as string;
            return {
                id: "card_description",
                locale: "",
                content: userCardDescription,
            } as TPublication

        default: return placeHolder
    }
}

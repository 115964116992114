export const getLinkIconClassName = (link: string):string => {
    const linkTypes = [
        {
            address: "www.facebook.com",
            iconClassName: "icon-social-facebook"
        },
        {
            address: "www.instagram.com",
            iconClassName: "icon-social-instagram"
        },
        {
            address: "vk.com",
            iconClassName: "icon-social-vkontakte"
        }
    ]

    return linkTypes.find(item => link.indexOf(item.address) !== -1)?.iconClassName || "icon-contacts-url"
}
import { createAction } from "@reduxjs/toolkit";
import {
    APP_INITIAL_STATE,
    IF_SETTINGS_FETCH_PENDING,
    IF_SETTINGS_FETCH_SUCCEED,
    IF_SETTINGS_FETCH_REJECTED,
    INITIAL_FETCH,
    IF_SETTINGS_FETCH,
    LOCALE_FETCH,
    USER_REGISTER_FETCH,
    USER_REGISTER_FETCH_PENDING,
    USER_REGISTER_FETCH_SUCCEED,
    USER_REGISTER_FETCH_REJECTED,
    USER_LOGIN_FETCH,
    USER_LOGIN_FETCH_PENDING,
    USER_LOGIN_FETCH_SUCCEED,
    USER_LOGIN_FETCH_REJECTED,
    USER_ME_FETCH,
    USER_ME_FETCH_PENDING,
    USER_ME_FETCH_SUCCEED,
    USER_ME_FETCH_REJECTED,
    VIEW_FETCH,
    VIEW_FETCH_PENDING,
    VIEW_FETCH_SUCCEED,
    VIEW_FETCH_REJECTED,
    COLLECTION_FETCH,
    COLLECTION_FETCH_PENDING,
    COLLECTION_FETCH_SUCCEED,
    COLLECTION_FETCH_REJECTED,
    COLLECTION_ITEM_FETCH_PENDING,
    VIEWS_FETCH,
    VIEWS_FETCH_PENDING,
    VIEWS_FETCH_SUCCEED,
    VIEWS_FETCH_REJECTED,
    DATA_UPDATE,
    DATA_UPDATE_PENDING,
    DATA_UPDATE_REJECTED,
    DATA_UPDATE_SUCCEED,
} from "../../settings/constants";
import { TSiteActionProps, TDataItem } from "../../types/typesStoreData";
import { TUserLoginData, TUser, TUserRegisterData } from "../../types/typesUser";
import { TInterfaceStore } from "../slices/sliceIfSettings";
import { TApiQuery, TRequestMethod } from "../../types/typesBase";
import { TDataStore } from "../slices/sliceData";
import { TPageView, TSitePage } from "../../types/typesStructure";

export const setInitialState = createAction(APP_INITIAL_STATE);
export const initialFetching = createAction(INITIAL_FETCH);
export const localeFetching = createAction(LOCALE_FETCH);

export const collectionFetching = createAction<{
    url: string,
    name: string,
    query: TApiQuery,
    single?: boolean,
    headers?: { [a: string]: string },
    body?: object,
    attributes?: {
        collectName: string,
        ID?: string,
        name: string,
        value?: string | number | boolean | object
    }[],
    attach?: {
        apiName: string,
        ID: string,
        attr: string,
    },
    onErrorShowToast?: boolean,
    onSuccessToastMessage?: string,
    action?: TSiteActionProps,
    errorAction?: TSiteActionProps,
}>(COLLECTION_FETCH);

export const collectionFetchingPending = createAction<string>(COLLECTION_FETCH_PENDING);
export const collectionItemFetchingPending = createAction<{ apiName: string, ID: string }>(COLLECTION_ITEM_FETCH_PENDING);
export const collectionFetchingSucceed = createAction<{ apiName?: string, collections: TDataStore["collections"] }>(COLLECTION_FETCH_SUCCEED);
export const collectionFetchingRejected = createAction<{ apiName: string, response: string, ID?: string }>(COLLECTION_FETCH_REJECTED);

export const viewFetching = createAction<{ view: TPageView, nextPage?: boolean }>(VIEW_FETCH);
export const viewFetchingPending = createAction<TPageView>(VIEW_FETCH_PENDING);
export const viewFetchingRejected = createAction<{ ID: string, response: string }>(VIEW_FETCH_REJECTED);
export const viewFetchingSucceed = createAction<TDataStore["collections"]>(VIEW_FETCH_SUCCEED);

export const viewsFetching = createAction<{ page?: TSitePage }>(VIEWS_FETCH);
export const viewsFetchingPending = createAction<string>(VIEWS_FETCH_PENDING);
export const viewsFetchingRejected = createAction(VIEWS_FETCH_REJECTED);
export const viewsFetchingSucceed = createAction<{ ID: string, collections: TDataStore["collections"] }>(VIEWS_FETCH_SUCCEED);

export const dataUpdate = createAction<{
    apiName: string,
    formID: string,
    headers?: { [a: string]: string },
    body: Partial<TDataItem>,
    parent?: {
        apiName: string,
        ID?: string,
        field: string,
        asArray?: boolean,
    },
    onErrorShowToast?: boolean,
    onSuccessToastMessage?: string,
    action?: TSiteActionProps,
}>(DATA_UPDATE);
export const dataUpdatePending = createAction<string>(DATA_UPDATE_PENDING);
export const dataUpdateRejected = createAction<{ formID: string, response: string }>(DATA_UPDATE_REJECTED);
export const dataUpdateSucceed = createAction<{ formID: string, apiName: string, collections: TDataStore["collections"] }>(DATA_UPDATE_SUCCEED);

export const ifSettingsFetching = createAction(IF_SETTINGS_FETCH);
export const ifSettingsFetchingPending = createAction(IF_SETTINGS_FETCH_PENDING);
export const ifSettingsFetchingSucceed = createAction<TInterfaceStore>(IF_SETTINGS_FETCH_SUCCEED);
export const ifSettingsFetchingRejected = createAction<string>(IF_SETTINGS_FETCH_REJECTED);

export const userLoginFetching = createAction<TUserLoginData>(USER_LOGIN_FETCH);
export const userLoginFetchingPending = createAction(USER_LOGIN_FETCH_PENDING);
export const userLoginFetchingSucceed = createAction<{ user: TUser, jwt: string }>(USER_LOGIN_FETCH_SUCCEED);
export const userLoginFetchingRejected = createAction<string>(USER_LOGIN_FETCH_REJECTED);

export const userRegisterFetching = createAction<TUserRegisterData>(USER_REGISTER_FETCH);
export const userRegisterFetchingPending = createAction(USER_REGISTER_FETCH_PENDING);
export const userRegisterFetchingSucceed = createAction<{ user: TUser, jwt: string }>(USER_REGISTER_FETCH_SUCCEED);
export const userRegisterFetchingRejected = createAction<string>(USER_REGISTER_FETCH_REJECTED);

export const userMeFetching = createAction(USER_ME_FETCH);
export const userMeFetchingPending = createAction(USER_ME_FETCH_PENDING);
export const userMeFetchingSucceed = createAction<TUser>(USER_ME_FETCH_SUCCEED);
export const userMeFetchingRejected = createAction<string>(USER_ME_FETCH_REJECTED);
import { TContentItem, TMediaItem } from "../../../../../../../types/typesStoreData";

export const getMediaItem = (type: string, contentItem?: TContentItem) => {

    const placeHolder: TMediaItem = {
        id: "media_placeholder",
        locale: "",
        createdAt: "",
        title: "",
        video: "",
        date: "",
        isPlaceholder: true,
    }

    if (!contentItem) return placeHolder

    switch (type) {
        case "mediaItems": return contentItem as TMediaItem
        default: return placeHolder
    }
}
import React from "react";
import { useSelector } from "react-redux";
import { actionSwitchSiteLocale } from "../../../../actions/global/actionSwitchSiteLocale";
import { getDataItems } from "../../../../store/storeGetters";
import { getCurrentLocale } from "../../../../store/slices/sliceIfSettings";
import { RootState } from "../../../../store/store";
import { TSiteLocale } from "../../../../types/typesStructure";

export const HeaderLocaleMenu: React.FC = () => {
    const currentLocale = useSelector(getCurrentLocale);
    const locales = useSelector((state: RootState) => getDataItems("locales", undefined, state)) as TSiteLocale[]

    return locales.length > 1 ? <div className="d-flex align-items-center me-auto text-secondary text-uppercase fw-bold">
        {locales.map(locale => (
            <a key={`localeSwitcher_${locale.code}`}
                href="#" className={currentLocale === locale.code ? "ms-2 text-warning" : "text-reset ms-2 text-hover-warning"}
                title={locale.name}
                onClick={(e) => {
                    e.preventDefault();
                    actionSwitchSiteLocale(locale.code);
                }}
            >
                {locale.code}
            </a>
        ))}
    </div> : null
}
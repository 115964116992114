import { Button, ButtonGroup, Col, Form } from "react-bootstrap"
import { TFormSchemeItem } from "../../../../../../../../store/slices/sliceIfSettings"
import { Input } from "../Input"
import { InputPlaceHolder } from "../InputPlaceHolder"
import { FormGroupSet } from "./FormGroupSet"

export const FormGroup: React.FC<{
    group: TFormSchemeItem[],
    formID: string,
    path: string,
    addHandler?: () => void,
    removeHandler?: () => void
}> = ({ group, formID, path, addHandler, removeHandler }) => {
    return <>
        {
            group.length
                ? group.map((formItem, i) => {
                    switch (formItem.type) {
                        case "group": return <FormGroupSet
                            key={`groupSet_${formItem.type}-${formItem.id}`}
                            groupSet={formItem}
                            formID={formID || "dummy"}
                            path={`${path ? path + "\." : ""}[${i}]`} />
                        default: return <Input
                            key={`input_${formItem.type}-${formItem.id}`}
                            formID={formID || "dummy"}
                            inputID={formItem.id}
                            inputType={formItem.type}
                            formPath={formItem.formPath} />
                    }
                })
                : Array(4).fill("").map((_, i) => i + 1).map(ph => <InputPlaceHolder key={`inputPlaceholder_${ph}`} />)
        }
        {addHandler || removeHandler ?
            <Form.Group as={Col} xs="12" className="px-1 text-end mb-3">
                <ButtonGroup>
                    {
                        removeHandler
                            ? <Button variant="outline-secondary p-0" size="sm" onClick={removeHandler}>
                                <span className="align-items-center avatar-xs d-flex fs-5 justify-content-center m-n1">&#8722;</span>
                            </Button>
                            : null
                    }
                    {
                        addHandler
                            ? <Button variant="outline-secondary p-0" size="sm" onClick={addHandler}>
                                <span className="align-items-center avatar-xs d-flex fs-5 justify-content-center m-n1">&#43;</span>
                            </Button>
                            : null
                    }
                </ButtonGroup>
            </Form.Group>
            : null
        }
    </>
}
import { schema } from "normalizr"
import { schemaNormalizeBlock } from "./schemaNormalizeBlock";
import { schemaNormalizeLink } from "./schemaNormalizeLink";

export const schemaNormalizeSiteProperty = () => {
    const link = schemaNormalizeLink();
    const block = schemaNormalizeBlock();
    return new schema.Entity('siteProperties', {
        mainMenuItems: [link],
        socialsMenuItems: [link],
        contactsMenuItems: [link],
        docsMenuItems: [link],
        permanentBlocks: [block]
    })
}
import { nanoid } from "@reduxjs/toolkit"
import _ from "lodash"
import { useState } from "react"
import { Collapse } from "react-bootstrap"
import { useSelector } from "react-redux"
import { getAction } from "../../../../actions/getAction"
import { getShowHints } from "../../../../store/slices/sliceIfSettings"
import { getActives } from "../../../../store/storeGetters"
import { TPageBlock, TPageView } from "../../../../types/typesStructure"
import { checkObjectPermissions } from "../../../library/checkPermissions"
import { mutateDataItem } from "../../../library/Mutation/mutateDataItem"
import { BlockHint } from "./BlockHint"
import { ViewDynamic } from "./View/ViewDynamic"
import { ViewStatic } from "./View/ViewStatic"

export const Block: React.FC<{ block: TPageBlock }> = ({ block }) => {
    const showHints = useSelector(getShowHints);
    const id = `collapse_${nanoid(6)}`;
    const active = useSelector(getActives);
    const [collapse, setCollapse] = useState<boolean>(block.collapsed ? true : false);
    const [showHint, setShowHint] = useState<boolean>(false);

    // Объявляю наборы CSS-классов для разных макетов блока
    const templates: { id: string, cover: string, header: string, title: string, button: string, body: string, prefix: string }[] = [
        {
            id: "default",
            cover: "my-2 px-3 px-sm-4 py-3 rounded shadow-sm bg-white",
            header: "px-3 px-sm-4",
            title: "",
            button: "text-hover-warning",
            body: "my-2",
            prefix: "text-hover-warning",
        },
        {
            id: "secondary",
            cover: "my-2 px-3 px-sm-4 py-3 rounded shadow-sm bg-secondary text-white",
            header: "px-3 px-sm-4",
            title: "text-reset",
            button: "text-hover-warning",
            body: "my-2",
            prefix: "text-hover-warning",
        },
        {
            id: "info",
            cover: "my-2 px-3 px-sm-4 py-3 rounded shadow-sm alert alert-info",
            header: "px-3 px-sm-4",
            title: "text-reset",
            button: "text-hover-info",
            body: "my-2",
            prefix: "text-hover-info",
        },
        {
            id: "warning",
            cover: "my-2 px-3 px-sm-4 py-3 rounded shadow-sm alert alert-warning",
            header: "px-3 px-sm-4",
            title: "text-reset",
            button: "text-hover-warning",
            body: "my-2",
            prefix: "text-hover-warning",
        },
        {
            id: "success",
            cover: "my-2 px-3 px-sm-4 py-3 rounded shadow-sm alert alert-success",
            header: "px-3 px-sm-4",
            title: "text-reset",
            button: "text-hover-success",
            body: "my-2",
            prefix: "text-hover-success",
        },
        {
            id: "danger",
            cover: "my-2 px-3 px-sm-4 py-3 rounded shadow-sm alert alert-danger",
            header: "px-3 px-sm-4",
            title: "text-reset",
            button: "text-hover-danger",
            body: "my-2",
            prefix: "text-hover-danger",
        },
        {
            id: "banner",
            cover: "my-2 rounded shadow-sm bg-white border-0",
            header: "d-none",
            title: "",
            button: "",
            body: "",
            prefix: "",
        },
        {
            id: "menu",
            cover: "",
            header: "d-none",
            title: "",
            button: "",
            body: "my-2",
            prefix: "",
        },
        {
            id: "user-section",
            cover: "",
            header: "d-none",
            title: "",
            button: "",
            body: "my-3 px-4",
            prefix: "",
        },
        {
            id: "simple",
            cover: "",
            header: "d-none",
            title: "",
            button: "",
            body: "",
            prefix: "",
        }

    ]

    const template = templates.find(item => item.id === block.template) || templates[0]
    // const template = templates.find(item => item.id === "danger") || templates[0]
    const buttonActionName = block.prefixButton?.action?.name || "dummy"
    const buttonAction = getAction(buttonActionName)

    const blockTitle = block.title || block.prefixButton?.icon || block.collapsible || block.hint
        ? <h4 className={`align-items-center d-flex my-3 ${template.title}`}>
            {
                block.prefixButton?.icon ?
                    <button className={`border-0 bg-transparent d-flex fs-6 lh-1 me-2 p-0 icon-action-return text-reset text-hover-warning ${template.prefix}`}
                        onClick={(e) => {
                            e.preventDefault()
                            buttonAction(block.prefixButton?.action?.props)
                        }}>
                    </button> : null
            }
            {
                block.title ?
                    <span className="text-truncate">
                        {block.title}
                    </span>
                    : null
            }

            {
                showHints && block.hint ?
                    <button
                        className="border-0 bg-transparent d-flex fs-6 icon-action-help ms-2 p-0 text-reset text-hover-info"
                        onClick={() => setShowHint(!showHint)} />
                    : null
            }

            {
                block.collapsible ?

                    <button type="button"
                        className={`border-0 bg-transparent d-flex fs-6 p-0 collapse-button icon-chevron-down ms-auto text-reset ${template.button}`}
                        onClick={() => setCollapse(!collapse)}
                        aria-controls="example-collapse-text"
                        aria-expanded={!collapse}>
                    </button>
                    : null
            }
        </h4>
        : null

    const hint = block.hint
        ? <Collapse in={showHint}>
            <div id={`blockHint_${id}`}>
                <div className="my-3">
                    <BlockHint {...block.hint} />
                </div>
            </div>
        </Collapse>
        : null

    const content = block.views
        ? _.sortBy(block.views.map(view => mutateDataItem(view, active) as TPageView)
            .filter(view => !view.hidden && checkObjectPermissions("views", view.id, "view")), "weight")
            .map(view => {
                if (view.mutations || view.useCurrent) {
                    return <ViewDynamic key={`dynamicView_${view.id}`} view={view} className={template.body} />
                } else {
                    return <ViewStatic key={`staticView_${view.id}`} view={view} className={template.body} />
                }
            })
        : null


    return <div id={`block_${block.id}`}
        className={`d-flex flex-column ${template.cover}`}>
        {blockTitle}
        {
            block.collapsible
                ? <Collapse in={!collapse}>
                    <div id={`blockCollapse_${id}`}>
                        {hint}
                        {content}
                    </div>
                </Collapse>
                : <>
                    {hint}
                    {content}
                </>
        }
    </div>
}
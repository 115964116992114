import { schema } from "normalizr";
import { schemaNormalizeImage } from "./schemaNormalizeImage";
import { schemaNormalizeWGCardFolder } from "./schemaNormalizeWGCardFolder";

export const schemaNormalizeWGCard = () => {
    const image = schemaNormalizeImage();
    const catalogFolder = new schema.Entity('workgroupCardFolders')

    return new schema.Entity('workgroupCards', {
        // logo: image,
        // banner: image,
        folders: [catalogFolder],
    })
}
import _ from "lodash";
import { store } from "../store/store";
import { TDictionaryTerm } from "../types/typesStructure";

export const translateTerm = (srcTerm: string, upper?: boolean): string => {
    const term = _.lowerCase(srcTerm)
    const locale = store.getState().sliceIfSettings.locale;
    const dictionaries = Object.values(store.getState().sliceData.collections.dictionaries || {}) as unknown as TDictionaryTerm[];
    const dictionary = dictionaries?.filter(term => term.locale === locale);
    const dictionaryTerm = dictionary.find(item => _.lowerCase(item.term) == term);
    const translated = dictionaryTerm?.translation;
    return upper ? _.upperFirst(translated || term) : translated || term;
}
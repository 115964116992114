import { useMessenger } from "../../../../hooks/useMessenger";
import { Toast } from "./Toast";

export const SectionToasts: React.FC = () => {
    const toasts = useMessenger().getToasts();

    const messageCards = toasts.map(toast => {
        return <Toast key={`toast_${toast.id}`} {...toast} />
    });

    return (
        <div style={{ zIndex: 11 }} className='position-fixed bottom-0 end-0 p-3'>
            {messageCards}
        </div>
    );

}
import { TContentItem } from "../../types/typesStoreData";
import { TPageBlock } from "../../types/typesStructure";

export const publicationPlaceholder: TPageBlock = {
    id: "publicationPHBlock",
    locale: "ru",
    placeholder: true,
    template: "default",
    section: "content",
    createdAt: "",
    views: [
        {
            id: "publicationPHView",
            locale: "ru",
            createdAt: "",
            api: "publications",
            viewTemplate: "default",
            itemTemplate: "publication",
            query: {},
            weight: 10,
            viewItems: {
                publications: [undefined] as unknown as TContentItem[]
            },
        }
    ],
}
import _, { compact } from "lodash";
import { FormEventHandler, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { flattenObj, TFlattenObj } from "../../../../../../../library/flattenObj";
import { dataUpdate } from "../../../../../../../store/actions/actionsExtra";
import { getFormResult, getFormScheme, getFormSchemeValues, getFormState, initFormSchemeSet } from "../../../../../../../store/slices/sliceIfSettings";
import { RootState } from "../../../../../../../store/store";
import { getActives } from "../../../../../../../store/storeGetters";
import { TForm, TFormInput } from "../../../../../../../types/typesForm";
import { TContentItem } from "../../../../../../../types/typesStoreData";
import { mutateDataItem } from "../../../../../../library/Mutation/mutateDataItem";
import { FormGroupSet } from "./FormGroupSet/FormGroupSet";
import { Input } from "./Input";
import { InputPlaceHolder } from "./InputPlaceHolder";

export const PageForm: React.FC<{ type: string, contentItem?: TContentItem }> = ({ contentItem }) => {
    const dispatch = useDispatch();
    const formScheme = useSelector((state: RootState) => getFormScheme(state, contentItem?.id || "dummy"));
    const formState = useSelector((state: RootState) => getFormState(state, contentItem?.id || "dummy"));
    const active = { ...useSelector(getActives), formState };
    const mutantForm = contentItem ? (mutateDataItem(contentItem, active)) as TForm : undefined;
    const formResult = useSelector((state: RootState) => getFormResult(state, active, mutantForm));
    const changedValuesNum = formResult && flattenObj(formResult as TFlattenObj).length;

    const invalidInputs = compact(useSelector((state: RootState) => getFormSchemeValues(state, mutantForm?.id || "dummy"))
        ?.filter(item => item.validation === "invalid")
        .reduce((r, v, k) => {
            const srcInput = _.get(mutantForm, v.formPath) as TFormInput | undefined;
            if (!srcInput) {
                return [...r]
            } else {
                return [...r, mutateDataItem(srcInput, active) as TFormInput];
            }
        }, [] as TFormInput[]))
        .filter(item => !item.hidden);

    const emptyRequiredInputs = compact(useSelector((state: RootState) => getFormSchemeValues(state, mutantForm?.id || "dummy"))
        ?.filter(item => !item.value)
        .reduce((r, v, k) => {
            const srcInput = _.get(mutantForm, v.formPath) as TFormInput | undefined;
            if (!srcInput) {
                return [...r]
            } else {
                return [...r, mutateDataItem(srcInput, active) as TFormInput];
            }
        }, [] as TFormInput[]))
        .filter(item => item.required);

    // console.log(invalidInputs, emptyRequiredInputs)
    const submitEnabled = changedValuesNum && !invalidInputs.length && !emptyRequiredInputs.length;

    useEffect(() => {
        if (mutantForm && !formScheme) {
            // console.log(mutantForm)
            dispatch(initFormSchemeSet({ form: mutantForm, actives: active }));
        }
    }, [mutantForm])

    const submitHandler: FormEventHandler = (e) => {
        e.preventDefault();
        // console.log(formResult)
        if (!mutantForm || !formResult) return;
        const fetchObj = {
            apiName: mutantForm.responseApiName,
            formID: mutantForm.id,
            body: formResult,
            headers: { "Content-Type": "application/json;charset=utf-8" },
            onErrorShowToast: mutantForm.onErrorShowToast,
            onSuccessToastMessage: mutantForm.onSuccessToastMessage,
            action: mutantForm.onSuccessAction,
            parent: mutantForm.parentName && mutantForm.parentField
                ? {
                    apiName: mutantForm.parentName,
                    ID: mutantForm.parentID,
                    field: mutantForm.parentField,
                    asArray: mutantForm.asArray
                }
                : undefined
        }
        dispatch(dataUpdate(fetchObj))
    }


    return <Form noValidate onSubmit={submitHandler}>
        {
            mutantForm?.title
                ? <h5 className={`border-bottom pb-2 text-secondary fw-bold mb-3 text-truncate ${mutantForm.isPlaceholder && " placeholder rounded-1"}`}>
                    {mutantForm.title}
                </h5>
                : null
        }

        <Row className="mx-n1">
            {
                formScheme?.length
                    ? formScheme.map((formItem, i) => {
                        switch (formItem.type) {
                            case "group":
                                return <FormGroupSet
                                    key={`groupSet_${formItem.type}-${formItem.id}`}
                                    groupSet={formItem}
                                    formID={mutantForm?.id || "dummy"}
                                    path={`[${i}]`} />
                            default:
                                return <Input
                                    key={`input_${formItem.type}-${formItem.id}`}
                                    formID={mutantForm?.id || "dummy"}
                                    inputID={formItem.id}
                                    inputType={formItem.type}
                                    formPath={formItem.formPath} />
                        }
                    })
                    : Array(4).fill("").map((_, i) => i + 1).map(ph => <InputPlaceHolder key={`inputPlaceholder_${ph}`} />)
            }
            <Form.Group as={Col} xs="12" className="px-1 text-end mt-3">
                <Button variant={submitEnabled ? "success" : "outline-success"} type="submit" disabled={!submitEnabled}>
                    <>
                        {mutantForm?.submitTitle || "OK"}
                        {mutantForm?.fetch === "progress"
                            ? <span className="ms-2 spinner-border spinner-border-sm" />
                            : <span className="icon-action-checked ms-2 small" />}
                    </>
                </Button>
            </Form.Group>
        </Row>
    </Form>
}
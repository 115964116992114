import { MouseEventHandler } from "react";
import { actionShowObjectByURL } from "../../../../../../actions/global/actionShowObjectByURL";
import { TContentItem } from "../../../../../../types/typesStoreData";
import { getMediaItem } from "./convertTypes/getMediaItem";

export const Video: React.FC<{ type: string, contentItem?: TContentItem }> = ({ contentItem, type }) => {
    const video = getMediaItem(type, contentItem)

    const modalHandler: MouseEventHandler = (e) => {
        e.preventDefault()
        if (video.isPlaceholder) return
        actionShowObjectByURL({ url: video.video, title: video.title })
    }

    return <div className={"p-1 show-hover rounded opacity-hover thumb-xxl " + (video.isPlaceholder ? " isPlaceholder-wave" : " bg-hover-light")}>
        <div className={"shadow-sm rounded-1 overflow-hidden position-relative" + (!video.isPlaceholder ? " bg-black bg-img-cover opacity-hover-content mouse-pointer" : " bg-midlight")}>
            <div className={"ratio ratio-16x9 w-100"}
                style={!video.isPlaceholder && video.image ? { backgroundImage: `url(${video.image?.url})` } : undefined}
                onClick={!video.isPlaceholder ? modalHandler : undefined}>
                {!video.isPlaceholder && !video.image ?
                    <>
                        <div className="position-absolute w-100 h-100" style={{ zIndex: 1 }} />
                        <object data={video.video} />
                    </> : null
                }
            </div>
        </div>
    </div>
}
import { normalize } from "normalizr";
import { IDsToString } from "../../library/backendObjectsFix"
import { TBackendObjectData } from "../../types/typesBackendData"
import { schemaNormalize } from "../normalizers/schemaNormalize";
import { TDataStore } from "../slices/sliceData";

export const getNormalized = (fetchObject: { [a: string]: any }, apiName: string): TDataStore["collections"] | undefined => {
    switch (apiName) {
        case "locales":
            const localesSchema = schemaNormalize("locales");
            if (localesSchema) {
                return normalize(
                    { ["locales"]: IDsToString(fetchObject as TBackendObjectData[]) }, localesSchema())
                    .entities as TDataStore["collections"]
            }
            break;

        case "me":
            const meSchema = schemaNormalize("users");
            if (meSchema) {
                return normalize({
                    ["users"]: IDsToString([fetchObject] as TBackendObjectData[]) as TBackendObjectData[]
                }, meSchema())
                    .entities as TDataStore["collections"]
            }
            break;

        case "login":
            const usersSchema = schemaNormalize("users");
            if (usersSchema) {
                return normalize({
                    ["users"]: IDsToString([{
                        ...fetchObject.user,
                        jwt: fetchObject.jwt
                    }]) as TBackendObjectData[]
                }, usersSchema()).entities as TDataStore["collections"]
            }
            break;

        default:
            const schema = schemaNormalize(apiName);

            if (schema) {
                return normalize(
                    fetchObject.data && Array.isArray(fetchObject.data)
                        ? { [apiName]: IDsToString(fetchObject.data) as TBackendObjectData[] }
                        : { [apiName]: [IDsToString(fetchObject.data)] as TBackendObjectData[] }
                    , schema()).entities as TDataStore["collections"]
            }
    }
}
import { actionLogOut } from "./global/actionLogOut";
import { actionShowObjectByURL } from "./global/actionShowObjectByURL";
import { actionShowUserMenu } from "./global/actionShowUserMenu";
import { actionSwitchSiteLocale } from "./global/actionSwitchSiteLocale";
import { actionFetchMe } from "./global/actionFetchMe";
import { actionThrow } from "./global/actionThrow";
import { store } from "../store/store";
import { clearJWT } from "../store/slices/sliceIfSettings";
import { setActiveDataItem } from "../store/slices/sliceData";
import { actionNavigateTo } from "./global/actionNavigateTo";
import { actionFetchCollection } from "./global/actionFetch";

type TAction = (a: any) => void

export const getAction = (name: string): TAction => {
    switch (name) {
        case "switchSiteLocale": return actionSwitchSiteLocale;
        case "actionShowObjectByURL": return actionShowObjectByURL;
        case "logout": return actionLogOut;
        case "resetToken": return () => store.dispatch(clearJWT());
        case "navigateBack": return () => window.history.back();
        case "showUserMenu": return actionShowUserMenu;
        case "redirectTo": return (url: string) => window.location.replace(url);
        case "navigateTo": return actionNavigateTo;
        case "fetchMe": return actionFetchMe;
        case "reloadPage": return () => window.location.reload();
        case "error404": return () => actionThrow("404");
        case "setActive": return (props: { apiName: string, ID: string }) => store.dispatch(setActiveDataItem({ apiName: props.apiName, ID: props.ID }));
        case "fetchCollection": return actionFetchCollection;

        default: return () => console.log("Action not found");
    }
}
import { TFormSchemeValue } from "../../../../../../../../store/slices/sliceIfSettings";
import { TFormInputImage, TFormInputValue } from "../../../../../../../../types/typesForm";
import { InputImageDefault } from "./InputImageDefault";

export const InputImage: React.FC<{
    input: TFormInputImage,
    handler: (value: TFormInputValue | undefined, noTimeout?: boolean) => void
    schemeValue: TFormSchemeValue,
}> = ({ input, handler, schemeValue }) => {
    switch (input.template) {
        default: return <InputImageDefault {...{ input, handler, schemeValue }} />
    }
}
import { collectionFetching } from "../../store/actions/actionsExtra";
import { setCollectionStatus } from "../../store/slices/sliceData";
import { store } from "../../store/store";
import { TApiRoute } from "../../types/typesStructure";

export const actionFetchMe = () => {
    const meFetchUrl = (Object.values(store.getState().sliceData.collections.apiRoutes || {}) as TApiRoute[])
        .find(item => item.storageName === "me")?.url
    if (meFetchUrl) {
        const meID = store.getState().sliceData.activeItems.me;
        const apiUrl = (Object.values(store.getState().sliceData.collections.apiRoutes) as TApiRoute[]).find(item => item.storageName === "me")?.url
        if (!meID || !apiUrl) return
        store.dispatch(setCollectionStatus({ collection: "me", status: "init" }));

        store.dispatch(collectionFetching({
            name: "me",
            url: apiUrl,
            query: {
                populate: [
                    "userCard.contactPhones",
                    "userCard.contactEmails",
                    "userCard.contactSites",
                    "userCard.contactSocials",
                    "userPersonals.contactPhones",
                    "userPersonals.contactEmails",
                    "userPersonals.contactSites",
                    "userPersonals.contactSocials",
                    // "workgroups.card.logo",
                    // "workgroups.card.banner",
                    "workgroups.card.contactPhones",
                    "workgroups.card.contactEmails",
                    "workgroups.card.contactSites",
                    "workgroups.card.contactSocials",
                    "workgroups.card.folders",
                    "ownWorkgroups.details",
                    "ownWorkgroups.address",
                    "ownWorkgroups.bankRequisites",
                    // "ownWorkgroups.card.logo",
                    // "ownWorkgroups.card.banner",
                    "ownWorkgroups.card.contactPhones",
                    "ownWorkgroups.card.contactEmails",
                    "ownWorkgroups.card.contactSites",
                    "ownWorkgroups.card.contactSocials",
                    "ownWorkgroups.card.folders",
                    "role"
                ]
            },
        }))
    }

}
import React from "react";
import { useSelector } from "react-redux";
import { getCollectionStatus, getDataItemByAttr } from "../../../../store/storeGetters";
import { getCurrentLocale } from "../../../../store/slices/sliceIfSettings";
import { RootState } from "../../../../store/store";
import { getLinkIconClassName } from "../../../library/getLinkIconClassName";
import { TSiteProperties } from "../../../../types/typesStructure";

export const FooterMenuSocial: React.FC = () => {
    const locale = useSelector(getCurrentLocale);
    const props = useSelector((state: RootState) => getDataItemByAttr("siteProperties", "locale", locale, state)) as TSiteProperties;
    const propsStatus = useSelector((state: RootState) => getCollectionStatus("siteProperties", state));

    return props?.socialsMenuItems.length || propsStatus !== "success" ?
        <div className={`d-flex align-items-center justify-content-center mb-3 mb-lg-0 ${propsStatus !== "success" ? " placeholder-wave" : ""}`}>
            {
                propsStatus === "success" ?
                    props?.socialsMenuItems.map((contact, i) => {

                        return contact.url ? <a key={`contact_${contact.id}`}
                            className={`text-reset text-hover-warning fs-5${i < props?.socialsMenuItems.length ? " me-3" : ""}`}
                            href={contact.url}
                            rel="noreferrer"
                            target="_blank"
                            title={contact.description}>
                            <span className={`${getLinkIconClassName(contact.url)}-solid`} />
                        </a> : null
                    }) :

                    [...Array(3)].map((ph, i) => <a key={`contact-placeholder_${i}`}
                        id={`contact-placeholder_${i}`}
                        className={`placeholder text-reset text-hover-warning fs-7 rounded ${i < 2 ? "me-3" : ""}`}
                        href="#">
                        <span style={{ width: "1.5em" }} className="d-block">&nbsp;</span>
                    </a>
                    )
            }
        </div>
        : null
    return null

}
import { MouseEventHandler } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAction } from "../../../../../../../actions/getAction";
import { setShowBurgerMenu, setShowUserMenu } from "../../../../../../../store/slices/sliceIfSettings";
import { getActives } from "../../../../../../../store/storeGetters";
import { TContentItem, TSiteActionProps } from "../../../../../../../types/typesStoreData";
import { mutateDataItem } from "../../../../../../library/Mutation/mutateDataItem";
import { getTextLink } from "./getTextLink";

export type TTextLinkViewItem = {
    id: string,
    action?: TSiteActionProps,
    link?: string,
    icons?: string[],
    title?: string,
    disabled?: boolean,
    isPlaceHolder?: boolean,
    badge?: string,
    description?: string,
}

type TProps = { type: string, contentItem?: TContentItem, variant?: "default" | "compact" }

export const TextLink: React.FC<TProps> = ({ contentItem, type, variant = "default" }) => {
    const active = useSelector(getActives);
    const mutantItem = contentItem ? mutateDataItem(contentItem, active) as TContentItem : undefined;
    const textLink = getTextLink(type, mutantItem);
    const dispatch = useDispatch();
    const linkType = () => {
        if (textLink.action) return "action";
        if (textLink.link?.indexOf("://") !== -1 || textLink.link[0] === "#") return "external";
        return "local"
    }
    const target = textLink.link ? textLink.link[0] === "#" ? "_self" : "_blank" : undefined

    const variants: { [a: string]: { wrapper: string } } = {
        default: {
            wrapper: "col-12 px-1 d-flex align-items-center lh-1 py-3 py-lg-2"
        },
        compact: {
            wrapper: "col-12 px-1 d-flex align-items-center py-0"
        }
    }

    // console.log(mutantItem)
    return <div className={variants[variant].wrapper + (textLink.disabled ? " disabled" : "")}>
        {
            (() => {
                const className = "d-flex align-items-center text-truncate " + (textLink.disabled ? " disabled" : "") + (textLink.isPlaceHolder ? " placeholder rounded-1 fs-7 my-1" : "");
                const content = <>
                    {textLink.icons?.[0] ? <span className={textLink.icons?.[0] + " d-flex me-2 fs-7"} /> : null}
                    {textLink.title}
                </>
                const handler: MouseEventHandler = (e) => {
                    dispatch(setShowBurgerMenu(false));
                    dispatch(setShowUserMenu({ state: false }));
                    if (textLink.action && linkType() === "action") {
                        e.preventDefault();
                        const action = getAction(textLink.action.name);
                        if (action) action(textLink.action.argument);
                    }
                }

                switch (linkType()) {
                    case "local":
                        return <Link to={textLink.link && !textLink.disabled ? textLink.link : ""}
                            className={className}
                            onClick={!textLink.disabled ? handler : undefined}>
                            {content}
                        </Link>
                    case "external":
                        return <a href={textLink.link && !textLink.disabled ? textLink.link : undefined}
                            target={target}
                            className={className}
                            onClick={!textLink.disabled ? handler : undefined}>
                            {content}
                        </a>
                    case "action":
                        return <a href={textLink.link && !textLink.disabled ? "#" : undefined}
                            className={className}
                            onClick={!textLink.disabled ? handler : undefined}>
                            {content}
                        </a>
                }
            })()
        }
        {textLink.badge ? <span className="ms-auto d-block bg-danger text-white rounded-pill p-1 fs-8">{textLink.badge}</span> : null}
    </div>
}
import { Col, FloatingLabel, FormControl, FormGroup } from "react-bootstrap";
import { TFormSchemeValue } from "../../../../../../../store/slices/sliceIfSettings";
import { TFormInputNumber, TFormInputValue } from "../../../../../../../types/typesForm";

export const InputNumber: React.FC<{
    input: TFormInputNumber,
    schemeValue: TFormSchemeValue,
    handler: (value: TFormInputValue | undefined, noTimeout?: boolean) => void
}> = ({ input, handler, schemeValue }) => {

    const control = <><FormControl
        required
        value={schemeValue.value as number || ""}
        isValid={schemeValue.validation === "valid"}
        isInvalid={schemeValue.validation === "invalid"}
        onChange={(e) => handler(e.target.value)}
        onBlur={(e) => handler(e.target.value, true)}
        disabled={input.disabled}
        type={input.type}
        max={input.max}
        min={input.min}
        placeholder={input.placeholder + (input.required ? " *" : "")} />

        {
            schemeValue.feedback && schemeValue.validation === "invalid"
                ? <FormControl.Feedback className="position-absolute px-2 start-0 text-center mt-0 lh-sm fs-7 text-truncate w-100" type="invalid" >
                    {schemeValue.feedback}
                </FormControl.Feedback>
                : null
        }
    </>

    return <FormGroup as={Col}
        title={input.label ? `${input.label} ${input.required ? "*" : ""}` : undefined}
        xs={input.size.replace("col-", "")}
        sm={input.bpSizes?.sm?.replace("col-sm-", "")}
        md={input.bpSizes?.md?.replace("col-md-", "")}
        lg={input.bpSizes?.lg?.replace("col-lg-", "")}
        xl={input.bpSizes?.xl?.replace("col-xl-", "")}
        xxl={input.bpSizes?.xxl?.replace("col-xxl-", "")}
        className="position-relative px-1 mb-3" >
        {
            input.label
                ? <FloatingLabel label={<>{input.label} {input.required ? <span className="text-danger">*</span> : null}</>}>
                    {control}
                </FloatingLabel>
                : control
        }
    </FormGroup>
}
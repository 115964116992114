import _, { cloneDeep, compact } from "lodash";
import { useEffect, useState } from "react";
import { Form, FormCheck } from "react-bootstrap";
import { TFormInputSelectOption, TFormInputValue } from "../../../../../../../../types/typesForm";

export const InputSelectMenu: React.FC<{
    menuItems: TFormInputSelectOption[],
    maxItems?: number
    handler: (value: TFormInputValue | undefined, noTimeout?: boolean) => void
    inputValue?: string | number | (string | number)[]
    valueAsArray?: boolean
}> = ({ menuItems, maxItems, handler, inputValue, valueAsArray }) => {
    const [path, setPath] = useState<string[]>([]);

    const levelDownHandler = (index: number) => {
        const newPath = cloneDeep(path)
        newPath.push(`[${index}].subOptions`)
        setPath(newPath)
    }

    const levelUpHandler = () => {
        const newPath = cloneDeep(path).splice(path.length, 1)
        setPath(newPath)
    }

    let updateValues = (checkItem: string | number, srcArray: (number | string)[]): (number | string)[] | undefined => {
        const valueIndex = srcArray.findIndex(item => item === checkItem);
        if (valueIndex !== -1) {
            const newArray = srcArray.filter((item, i) => i !== valueIndex)
            return newArray.length ? newArray : undefined;
        } else {
            if (maxItems === 1) {
                return [checkItem]
            } else if (!maxItems || (maxItems && srcArray.length < maxItems)) {
                return [...srcArray, checkItem]
            } else {
                return srcArray
            }
        }
    }

    const checkHandler = (index: number) => {
        const checkPath = [...path, `[${index}]`].join(".")
        const checkValue = (_.get(menuItems, checkPath) as TFormInputSelectOption).value;
        if (!checkValue) return;

        if (valueAsArray) {
            if (Array.isArray(inputValue)) {
                handler(updateValues(checkValue, inputValue), true);
            } else {
                handler(updateValues(checkValue, (inputValue?.toString().split(";") || [])), true);
            }
        } else {
            if (typeof inputValue === "string") {
                const newValueArray = updateValues(checkValue, inputValue.split(";"));
                handler(newValueArray ? newValueArray.join(";") : undefined, true);
            } else {
                handler(checkValue, true)
            }
        }
    }

    const itemIsActive = (menuItem: TFormInputSelectOption): boolean => {
        if (!inputValue) {
            return false
        } else {
            if (Array.isArray(inputValue)) {
                return inputValue.findIndex(item => item == menuItem.value) !== -1;
            } else if (typeof inputValue === "number") {
                return inputValue === menuItem.value;
            } else {
                return inputValue.split(";").findIndex(item => item === menuItem.value) !== -1;
            }
        }
    }


    const MenuItem: React.FC<{ menuItem: TFormInputSelectOption, index: number, checked?: boolean }> = ({ menuItem, index, checked }) => {
        const showCheckboxes = menuItem.selectable && ((maxItems && maxItems > 1) || !maxItems)
        const showChevrons = menuItems.filter(item => item.subOptions?.length).length > 0;
        return <>
            <div className={"d-flex px-3 py-2 align-items-center bg-hover-light" + (!showCheckboxes && checked ? " bg-light" : "")}>
                {
                    menuItem.subOptions || !showCheckboxes
                        ? <button
                            type="button"
                            className="border-0 btn p-0 text-start flex-fill d-flex align-items-center lh-sm text-secondary text-truncate"
                            title={menuItem.label}
                            onClick={
                                menuItem.subOptions?.length
                                    ? (e) => { e.preventDefault(); levelDownHandler(index) }
                                    : menuItem.selectable
                                        ? (e) => { e.preventDefault(); checkHandler(index) }
                                        : undefined
                            }>
                            {
                                showChevrons
                                    ? <div className="d-flex flex-column align-items-center me-2">
                                        <div className="ps-2" />
                                        <span className="icon-chevron-down fs-8 text-secondary" />
                                    </div>
                                    : null
                            }
                            <span className="text-truncate">{menuItem.label}</span>
                        </button>
                        : <div
                            className="flex-fill d-flex align-items-center lh-sm text-truncate text-secondary"
                            title={menuItem.label}>
                            {
                                showChevrons
                                    ? <div className="d-flex flex-column align-items-center me-2">
                                        <div className="ps-2" />
                                    </div>
                                    : null
                            }

                            <span className="text-truncate">{menuItem.label}</span>
                        </div>
                }
                {

                    showCheckboxes ? <FormCheck checked={checked} onChange={(e) => { e.preventDefault(); checkHandler(index) }} />
                        : null
                }
            </div>
        </>
    }

    const layoutPath = path.reduce((res, val, key) => {
        if (key === path.length - 1) {
            return [...res, val.replace(".subOptions", "")]
        } else {
            return [...res, val]
        }
    }, [] as string[]).join(".")


    console.log(layoutPath)

    const currentLayout = _.get({ items: menuItems }, `items.${layoutPath}`) as unknown as TFormInputSelectOption;

    return <>
        {
            path.length
                ? <div className={"d-flex px-3 py-2 align-items-center bg-hover-light"}>
                    <button
                        type="button"
                        className="border-0 btn p-0 text-start flex-fill d-flex align-items-center lh-sm text-truncate text-secondary"
                        onClick={(e) => { e.preventDefault(); levelUpHandler() }}>
                        <div className="d-flex flex-column align-items-center me-2">
                            <div className="ps-2" />
                            <span className="icon-chevron-up fs-8 text-secondary" />
                        </div>
                        <span className="fw-bold">{currentLayout?.label}</span>
                    </button>
                </div>
                : null
        }
        {
            (path.length ? currentLayout?.subOptions : menuItems)?.map((menuItem, i) => {
                return <MenuItem key={`option_${i}`} menuItem={menuItem} index={i} checked={itemIsActive(menuItem)} />
            })
        }

    </>

}
import { camelCase } from "lodash";
import { TContentItem } from "../../../../../types/typesStoreData";
import { TPageView } from "../../../../../types/typesStructure";
import { Banner } from "./ViewItem/Banner";
import { Publication } from "./ViewItem/Publication/Publication";
import { ItemPageHeader } from "./ViewItem/ItemPageHeader/ItemPageHeader";
import { CollectionCard } from "./ViewItem/CollectionCard/CollectionCard";
import { PageForm } from "./ViewItem/Form/PageForm";

export const ViewDefault: React.FC<{ view: TPageView, className?: string }> = ({ view, className }) => {
    const viewItems = view.viewItems && Object.values(view.viewItems).length ? (Object.values(view.viewItems) as TContentItem[][]).reduce((a, b) => [...a, ...b] as TContentItem[]) : undefined
    const type = camelCase(view.api);
    return <div id={`view_${view.id}`} className={className}>{
        view.title
            ? <h5 className={`border-bottom pb-2 mb-3 px-1 col-12 text-secondary fw-bold text-truncate ${view.isPlaceholder ? " placeholder rounded-1" : ""}`}>
                {view.title}
            </h5>
            : null
    }
        {
            (viewItems || [undefined]).map((item, i) => {
                switch (view.itemTemplate) {
                    case "banner":
                        return <Banner key={`banner_${i}`} type={type} contentItem={item} />
                    case "publication":
                        return <Publication key={`publication_${i}`} type={type} contentItem={item} />
                    case "item-page-header":
                        return <ItemPageHeader key={`wgHeader_${i}`} type={type} contentItem={item} />
                    case "single-card":
                        return <CollectionCard key={`card_${i}`} type={type} contentItem={item} single={true} />
                    // case "wg-card-about":
                    //     return <Publication key={`wgAbout_${i}`} type={type} contentItem={item} />
                    case "form-base":
                        return <PageForm key={`form_${i}`} type={type} contentItem={item} />
                    // case "user-card-single":
                    //     return <UserMenuCard key={`userCard_${i}`} contentItem={item} />
                    // case "user-profile-header":
                    //     return <UserProfileHeader key={`userHeader_${i}`} contentItem={item} />
                    // case "user-profile-description":
                    //     return <UserProfileDescription key={`userDescription_${i}`} contentItem={item} />
                    // case "user-profile-contacts":
                    //     return <UserProfileContacts key={`userContacts_${i}`} contentItem={item} />
                    // case "user-profile-personal-contacts":
                    //     return <UserProfileContacts key={`userPersonalContacts_${i}`} contentItem={item} personal={true} />
                    // case "user-profile-personals":
                    //     return <UserProfilePersonals key={`userPersonalData_${i}`} contentItem={item} />
                    // case "user-account-info":
                    //     return <UserAccountInfo key={`userPersonalData_${i}`} contentItem={item} />
                    // case "wg-about":
                    //     return <Publication key={`wgAbout_${i}`} type={type} contentItem={item} />
                    default: return null
                }
            })
        }</div>
}
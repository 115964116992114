import ReactMarkdown from "react-markdown";
import { THint } from "../../../../types/typesStructure";

export const BlockHint: React.FC<THint> = (props) => {
    return <div className="alert alert-info d-flex flex-column w-100 mb-0">
        <p className="border-bottom border-info border-opacity-50 text-center text-uppercase mb-3 pb-2">{props.title}</p>
        <ReactMarkdown className="small" >
            {props.content}
        </ReactMarkdown>
    </div>
}
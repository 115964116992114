import { ReactNode } from "react-markdown/lib/react-markdown";
import { translateTerm } from "../../../../../../../library/translateTerm";
import { TContentItem } from "../../../../../../../types/typesStoreData";
import { getPropsList } from "./getPropsList";
import { PropsListTerm } from "./PropsListTerm";

export type TPropsListItem = {
    id: string,
    therm: string | ReactNode,
    description?: string | ReactNode,
    isPlaceHolder?: boolean,
    termClassName?: string,
    descriptionClassName?: string,
    weight: number,
}

export const PropsList: React.FC<{ type: string, contentItem?: TContentItem }> = ({ contentItem, type }) => {
    const propsList = getPropsList(type, contentItem);
    if (propsList.filter(item => item.description).length) {
        return <>{
            propsList.sort((a, b) => a.weight > b.weight ? 1 : a.weight < b.weight ? -1 : 0).map((listItem, i) => <PropsListTerm key={`listItem_${listItem.id}`} {...listItem} />)
        }</>
    } else {
        return <div className="text-center my-5">{translateTerm("there's nothing here yet", true)}...</div>
    }
}
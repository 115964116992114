import { nanoid } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { useRef, useState } from "react";
import { Col, FormControl, FormGroup } from "react-bootstrap";
import FileResizer from "react-image-file-resizer";
import { translateTerm } from "../../../../../../../../library/translateTerm";
import { TFormSchemeValue } from "../../../../../../../../store/slices/sliceIfSettings";
import { TFormInputImage, TFormInputImageValue, TFormInputValue } from "../../../../../../../../types/typesForm";

export const InputImageDefault: React.FC<{
    input: TFormInputImage,
    handler: (value: TFormInputValue | undefined, noTimeout?: boolean) => void
    schemeValue: TFormSchemeValue,
}> = ({ input, handler, schemeValue }) => {
    const value = schemeValue.value as TFormInputImageValue | undefined;
    const [formatError, setFormatError] = useState<boolean>(false);

    const ref = useRef<HTMLInputElement>(null);
    const nanoID = nanoid(6);
    const acceptedTypes = [
        "image/jpeg",
        "image/png",
        // "image/svg+xml",
        "image/webp"
    ]

    const onChangeHandler = () => {
        const file = ref.current?.files?.[0]
        if (!file) return;
        const fileType = file.type
        if (!acceptedTypes.find(item => item === file.type)) {
            setFormatError(true);
        } else {
            const newInput = cloneDeep(input);

            const setNewInput = (fileResized: File) => {
                let reader = new FileReader();
                reader.onload = () => {
                    //     newvalueData = reader.result || "",
                    //     newvalueType = file.type,
                    handler({
                        fileName: fileResized.name,
                        fileData: reader.result || "",
                        fileType: fileResized.type
                    }, true)
                }
                reader.readAsDataURL(fileResized)
            }

            if (fileType !== "image/svg") {
                try {
                    FileResizer.imageFileResizer(
                        file,
                        input.maxWidth || 1024,
                        input.maxHeight || 1024,
                        fileType.split("/")[1].toUpperCase(),
                        input.quality || 80,
                        0,
                        (uri) => setNewInput(uri as File),
                        "file"
                    )

                } catch {
                    newInput.validation = "invalid";
                    newInput.feedback = translateTerm("file optimization error", true);
                    handler(value, true);
                }
            } else {
                setNewInput(file)
            }
        }
    }

    const cleanUpHandler = () => {
        handler(undefined, true)
    }

    const control = <>
        <FormControl
            as={"div"}
            className="d-flex flex-column h-100 align-items-center px-2"
            isValid={input.validation === "valid"}
            isInvalid={input.validation === "invalid" || formatError}
            style={{
                paddingRight: "0.75rem",
                backgroundPosition: "right calc(0.375em + 0.125rem) bottom calc(0.375em + 0.125rem)"
            }}>
            <div className="align-items-center d-flex w-100">
                <span className="fs-7 opacity-75">{input.label} </span>
                {input.required ? <span className="text-danger">*</span> : null}

                {value?.fileData
                    ? <button type="button" className="btn btn-sm border-0 p-0 ms-auto text-hover-warning" onClick={cleanUpHandler} >&#128465;</button>
                    : null

                }

            </div>
            <input ref={ref} id={`file_${nanoID}`} type="file" className="d-none" disabled={input.disabled} onChange={onChangeHandler} />
            <label className={
                `ratio ratio-${input.ratio}
                ${!formatError && !input.disabled ? " cursor-pointer" : ""} rounded-${input.shape === "circle" ? "circle" : "1"} 
                bg-img-cover my-auto d-flex`
            }
                style={{ backgroundImage: `url(${value?.fileData})` }}
                htmlFor={`file_${nanoID}`}>
                {
                    !value
                        ? <div className="d-flex align-items-center justify-content-center">
                            <span className="fs-7 text-center">*.jpg, *.jpeg, *.png, *.svg, *.webp</span>
                        </div>
                        : null
                }
            </label>
        </FormControl>
        {
            (input.feedback && input.validation === "invalid") || formatError
                ? <FormControl.Feedback className="position-absolute px-2 start-0 text-center mt-0 lh-sm fs-7 text-truncate w-100" type="invalid" >
                    {formatError ? translateTerm("wrong file format", true) : input.feedback}
                </FormControl.Feedback>
                : null
        }

    </>

    const label = <>
    </>

    return <FormGroup as={Col}
        title={input.label ? `${input.label} ${input.required ? "*" : ""}` : undefined}
        xs={input.size.replace("col-", "")}
        sm={input.bpSizes?.sm?.replace("col-sm-", "")}
        md={input.bpSizes?.md?.replace("col-md-", "")}
        lg={input.bpSizes?.lg?.replace("col-lg-", "")}
        xl={input.bpSizes?.xl?.replace("col-xl-", "")}
        xxl={input.bpSizes?.xxl?.replace("col-xxl-", "")}
        className="position-relative mb-3 d-flex px-1" >
        {control}

    </FormGroup>

}
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { TFetchStatus } from "../../types/typesBackendData";
import { TFormNormalized } from "../../types/typesForm";
import { TDataItem, TStoreCollectionItem } from "../../types/typesStoreData";
import { collectionFetchingPending, collectionFetchingRejected, collectionFetchingSucceed, collectionItemFetchingPending, dataUpdatePending, dataUpdateRejected, dataUpdateSucceed, setInitialState, viewFetchingPending, viewFetchingRejected, viewFetchingSucceed, viewsFetchingPending, viewsFetchingSucceed } from "../actions/actionsExtra";
import { schemaNormalize } from "../normalizers/schemaNormalize";

export type TDataStore = {
    fetchStatuses: { [a: string]: TFetchStatus }
    fetchResponses: { [a: string]: string }
    activeItems: { [a: string]: string }
    collections: {
        [a: string]: {
            [b: string]: TStoreCollectionItem
        }
    };
}

const initialState: TDataStore = {
    fetchStatuses: {},
    fetchResponses: {},
    collections: {},
    activeItems: {},
}

const sliceData = createSlice({
    name: "data",
    initialState,
    reducers: {

        clearCollectionStatuses: (state: TDataStore) => {
            state.fetchStatuses = {}
        },

        clearCollections: (state: TDataStore) => {
            state.collections = {}
        },

        clearCollectionResponses: (state: TDataStore) => {
            state.fetchResponses = {}
        },

        setStoreCollectionItem: (state: TDataStore, { payload }: PayloadAction<{ apiName: string, collectionItem: TStoreCollectionItem }>) => {
            state.collections[payload.apiName] = {
                ...state.collections[payload.apiName],
                [payload.collectionItem.id]: payload.collectionItem
            }
        },

        setDataItem: (state: TDataStore, { payload }: PayloadAction<{ apiName: string, dataItem: TDataItem }>) => {
            const schema = schemaNormalize(`${payload.apiName}Single`);
            if (!schema) return;
            const dataNormalized = normalize(payload.dataItem, schema()).entities as TDataStore["collections"]
            state.collections = {
                ...state.collections,
                ...(_.reduce(dataNormalized, (collections, collection, collectionName) => {
                    return { ...collections, [collectionName]: { ...state.collections[collectionName], ...collection } };
                }, {}) as TDataStore["collections"])
            }
        },

        setActiveDataItem: (state: TDataStore, { payload }: PayloadAction<{ apiName: string, ID?: string }>) => {
            if (payload.ID) {
                state.activeItems[payload.apiName] = payload.ID;
            } else {
                if (state.activeItems[payload.apiName]) delete state.activeItems[payload.apiName];
            }
        },

        setActiveDataItems: (state: TDataStore, { payload }: PayloadAction<TDataStore["activeItems"]>) => {
            state.activeItems = payload;
        },

        setDataItemStatus: (state: TDataStore, { payload }: PayloadAction<{ collection: string, ID: string, status: TFetchStatus }>) => {
            const collection = state.collections[payload.collection];
            if (!collection) return;
            const item = collection[payload.ID];
            if (!item) return;
            item.fetch = payload.status
        },
        setCollectionStatus: (state: TDataStore, { payload }: PayloadAction<{ collection: string, status?: TFetchStatus }>) => {
            if (payload.status) {
                state.fetchStatuses[payload.collection] = payload.status
            } else {
                if (state.fetchStatuses[payload.collection]) delete state.fetchStatuses[payload.collection];
            }
        }
    },

    extraReducers: (builder) => {
        builder.addCase(setInitialState, () => initialState);

        // Collections -->

        builder.addCase(collectionFetchingPending, (state, { payload }) => {
            state.fetchStatuses[payload] = "progress";
        })

        builder.addCase(collectionItemFetchingPending, (state, { payload }) => {
            const collection = state.collections[payload.apiName];
            if (!collection) return;
            const item = collection[payload.ID];
            if (!item) return;
            item.fetch = "progress"
        })

        builder.addCase(collectionFetchingRejected, (state, { payload }) => {
            if (!payload.ID) {
                state.fetchStatuses[payload.apiName] = "error";
                state.fetchResponses[payload.apiName] = payload.response;
            } else {
                const item = state.collections[payload.apiName]?.[payload.ID];
                if (item) {
                    item.fetch = "error";
                    item.fetchResponse = payload.response;
                }
            }
        })

        builder.addCase(collectionFetchingSucceed, (state, { payload }) => {
            // console.log(payload)
            switch (payload.apiName) {
                case "me":
                    const me = Object.values(payload.collections.users)[0]
                    if (me) {
                        state.fetchStatuses = { ...state.fetchStatuses, me: "success" };
                        state.activeItems = { ...state.activeItems, me: me.id };
                    }
                    break;
                case undefined: break;
                default: state.fetchStatuses[payload.apiName] = "success";
            }
            state.collections = {
                ...state.collections,
                ...(_.reduce(payload.collections, (csr, csv, csk) => {
                    return { ...csr, [csk]: _.mergeWith(state.collections[csk], csv, (d, s) => !s && d ? d : s) };
                }, {}) as TDataStore["collections"])
            }
        })

        // <-- Collections

        // Views -->

        builder.addCase(viewsFetchingPending, (state, { payload }) => {
            const page = state.collections.pages[payload];
            if (!page) return
            page.fetch = "progress"
            // state.fetchStatuses.views = "progress";
        })

        builder.addCase(viewsFetchingSucceed, (state, { payload }) => {
            state.collections = {
                ...state.collections,
                ...(_.reduce(payload.collections, (colRes, colVal, colKey) => {
                    return {
                        ...colRes,
                        [colKey]: _.mergeWith(state.collections[colKey], colVal, (itemOld, itemNew) => {
                            if (!itemOld) return itemNew;
                            if (!itemNew) return itemOld;
                            return _.merge(itemOld, itemNew)
                        })
                    }
                }, {}) as TDataStore["collections"])
            }
            // state.collections = _.merge(state.collections, payload)
            const page = state.collections.pages[payload.ID];
            if (!page) return
            page.fetch = "success"
        })

        // <-- views

        // View -->

        builder.addCase(viewFetchingPending, (state, { payload }) => {
            state.collections.views[payload.id] = payload
        })

        // builder.addCase(viewFetchingRejected, (state, { payload }) => {
        //     state.fetchStatuses.views = "error"
        //     state.fetchResponses.views = `id: ${payload.ID}, response: ${payload.response}`
        // })

        builder.addCase(viewFetchingSucceed, (state, { payload }) => {
            state.collections = {
                ...state.collections,
                ...(_.reduce(payload, (colRes, colVal, colKey) => {
                    // return { ...colRes, [colKey]: _.merge(state.collections[colKey], colVal) };
                    return {
                        ...colRes,
                        [colKey]: _.mergeWith(state.collections[colKey], colVal, (itemOld, itemNew) => {
                            if (!itemOld) return itemNew;
                            if (!itemNew) return itemOld;
                            return _.merge(itemOld, itemNew)
                        })
                    }
                    // return { ...colRes, [colKey]: { ...state.collections[colKey], ...colVal } }
                }, {}) as TDataStore["collections"])
            }
            // state.collections = _.merge(state.collections, payload)
        })

        // <-- View

        // Data update -->

        builder.addCase(dataUpdatePending, (state, { payload }) => {
            const form = state.collections.forms?.[payload];
            if (!form) return;
            form.fetch = "progress";
        })

        builder.addCase(dataUpdateRejected, (state, { payload }) => {
            const form = state.collections.forms?.[payload.formID];
            if (!form) return;
            form.fetch = "error";
            form.fetchResponse = payload.response;
        })

        builder.addCase(dataUpdateSucceed, (state, { payload }) => {
            const form = state.collections.forms?.[payload.formID] as TFormNormalized;
            if (!form) return;

            switch (form.responseApiName) {
                case "login":
                    const me = Object.values(payload.collections.users)[0]
                    if (me) {
                        state.activeItems = { ...state.activeItems, me: me.id };
                        form.fetch = "success";
                        state.fetchStatuses = {};
                    }
                    break;
                case undefined: break;
                default: form.fetch = "success";
            }
            state.collections = {
                ...state.collections,
                ...(_.reduce(payload.collections, (collections, collection, collectionName) => {
                    return { ...collections, [collectionName]: collection };
                }, {}) as TDataStore["collections"])
            }
        })


        // <-- Data update
    }

})

export const {
    setDataItem,
    setActiveDataItem,
    setActiveDataItems,
    setStoreCollectionItem,
    setDataItemStatus,
    setCollectionStatus,
    clearCollectionStatuses,
    clearCollections,
    clearCollectionResponses,
} = sliceData.actions
export default sliceData.reducer
import _, { camelCase } from "lodash";
import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { TContentItem } from "../../../../../types/typesStoreData";
import { TPageView } from "../../../../../types/typesStructure";
import { CatalogFolder } from "./ViewItem/CatalogFolder";
import { Video } from "./ViewItem/Video";

export const ViewSlider: React.FC<{ view: TPageView, rows?: number, className?: string }> = ({ view, rows = 1, className }) => {
    const viewItems = view.viewItems && Object.values(view.viewItems).length ? (Object.values(view.viewItems) as TContentItem[][]).reduce((a, b) => [...a, ...b] as TContentItem[]) : undefined

    const wrapper = useRef<HTMLDivElement>(null);
    const [wrapperWidth, setWrapperWidth] = useState<number>(0);
    const [wrapperScrollWidth, setWrapperScrollWidth] = useState<number>(0);
    const [wrapperScrollPos, setWrapperScrollPos] = useState<number>(0);

    useEffect(() => {
        const el = wrapper.current;
        if (!el) return;
        setWrapperWidth(el.clientWidth);
        setWrapperScrollWidth(el.scrollWidth);
        setWrapperScrollPos(el.scrollLeft);
        window.onresize = () => {
            setWrapperWidth(el.clientWidth);
            setWrapperScrollWidth(el.scrollWidth);
        }
    }, [viewItems])

    const scrollHandler = () => {
        setWrapperScrollPos(wrapper.current?.scrollLeft || 0);
    }

    const scrollLeft: MouseEventHandler = (e) => {
        const el = wrapper.current;
        if (!el) return;
        el.scrollTo({
            left: el.scrollLeft - el.clientWidth,
            behavior: 'smooth'
        })
    }

    const scrollRight: MouseEventHandler = (e) => {
        const el = wrapper.current;
        if (!el) return;
        el.scrollTo({
            left: el.scrollLeft + el.clientWidth,
            behavior: 'smooth'
        })
    }
    const itemRows = _.chunk((viewItems || [...Array(6)].map(a => undefined)), Math.ceil(viewItems?.length || 6 / rows))

    const showScrollLeft = (wrapperWidth !== wrapperScrollWidth) && wrapperScrollPos > 0;
    const showScrollRight = (wrapperWidth !== wrapperScrollWidth) && (wrapperScrollPos < (wrapperScrollWidth - wrapperWidth));
    const scrollButtonClassName = "align-items-center avatar-xs bg-white border-midlight btn d-flex justify-content-center p-0 rounded-circle shadow-sm text-hover-warning text-secondary"
    const scrollLeftBtn = <button className={scrollButtonClassName + " icon-chevron-left"}
        onClick={scrollLeft}>
        {/* <span className=" icon-chevron-left d-flex" /> */}
    </button>
    const scrollRightBtn = <button className={scrollButtonClassName + " icon-chevron-right"}
        onClick={scrollRight}>
        {/* <span className="icon-chevron-right d-flex" /> */}
    </button>

    const type = camelCase(view.api);

    return <div id={`view_${view.id}`} className={"position-relative w-100" + (className ? ` ${className}` : "")}>
        {/* {view.title ? <h6 className="mb-1 text-truncate">{view.title}</h6> : null} */}
        {view.title ? <h5 className={`border-bottom pb-2 text-secondary mb-2 text-truncate ${view.isPlaceholder && " placeholder rounded-1"}`}>
            {view.title}
        </h5> : null}

        <div ref={wrapper} className="w-100 position-relative" style={{ overflowX: "scroll" }} onScroll={scrollHandler}>
            {
                (itemRows).map((row, i) => {
                    return <div key={`row_${i}`} className="d-flex position-relative h-100">
                        {row.map((item, j) => {
                            switch (view.itemTemplate) {
                                case "video":
                                    return <Video key={`sliderItem_${i}${j}`} type={type} contentItem={item} />
                                case "catalog-card":
                                    return <CatalogFolder key={`sliderItem_${i}${j}`} type={type} contentItem={item} />
                                default: return
                            }
                        })}
                    </div>
                })
            }
        </div>

        {showScrollLeft ?
            <div className="position-absolute start-0 top-0 h-100 d-none d-sm-flex flex-column justify-content-center" style={{ zIndex: 1 }}>
                {scrollLeftBtn}
            </div> : null}
        {showScrollRight ?
            <div className="position-absolute end-0 top-0 h-100 d-none d-sm-flex flex-column justify-content-center" style={{ zIndex: 1 }}>
                {scrollRightBtn}
            </div> : null}
    </div>
}
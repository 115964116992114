import { camelCase } from "lodash";
import { MouseEventHandler } from "react";
import { useDispatch } from "react-redux";
// import { viewFetching } from "../../../../../store/actions/actionsExtra";
import { TPageView } from "../../../../../types/typesStructure";
import { TContentItem } from "../../../../../types/typesStoreData";
import { CollectionCard } from "./ViewItem/CollectionCard/CollectionCard";
import { viewFetching } from "../../../../../store/actions/actionsExtra";
import { TextLink } from "./ViewItem/TextLink/TextLink";
import { PropsList } from "./ViewItem/PropsList/PropsList";
import { CollectionRow } from "./ViewItem/CollectionRow/CollectionRow";
import { FormDivider } from "./ViewItem/Form/FormDivider";

export const ViewRow: React.FC<{ view: TPageView, className?: string, titleClassName?: string }> = ({ view, className, titleClassName }) => {
    const viewItems = view.viewItems && Object.values(view.viewItems).length ? (Object.values(view.viewItems) as TContentItem[][]).reduce((a, b) => [...a, ...b] as TContentItem[]) : undefined
    const dispatch = useDispatch();
    const moreHandler: MouseEventHandler = (e) => {
        e.preventDefault();
        dispatch(viewFetching({ view, nextPage: true }))
    }
    const type = camelCase(view.api);
    // console.log(view)
    return (view.fetch === "success" && viewItems && viewItems.length) || view.fetch === "progress"
        ? <div id={`view_${view.id}`} className={"row mx-n1" + (className ? ` ${className}` : "")}>
            {view.title
                ? <FormDivider {...{
                    id: `view_${view.id}_title`,
                    name: "",
                    size: "12",
                    __component: "",
                    template: "",
                    bpSizes: {},
                    type: "divider",
                    weight: 0,
                    title: view.title,
                    border: true,
                }} />
                : null}

            {(viewItems || [...Array(5)].map(i => undefined)).map((item, i) => {
                switch (view.itemTemplate) {
                    case "collection-card": return <CollectionCard key={`card_${i}`} type={type} contentItem={item} />
                    case "single-card": return <CollectionCard key={`card_${i}`} type={type} contentItem={item} single={true} />
                    case "collection-row": return <CollectionRow key={`row_${i}`} type={type} contentItem={item} />
                    case "text-link":
                    case "contact-link": return <TextLink key={`link_${i}`} type={type} contentItem={item} />
                    case "props-list": return <PropsList key={`propsList_${i}`} type={type} contentItem={item} />
                    default: return null
                }
            })}
            {
                view.pagination && view.pagination.page < view.pagination.pageCount ?
                    <div className="col-12 px-1 mt-3 text-center">
                        <a href="#" onClick={moreHandler}>Еще...</a>
                    </div> : null
            }
        </div>
        : null
}

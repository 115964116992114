import _ from "lodash";
import { ReactNode } from "react";
import { translateTerm } from "../../../../../../../library/translateTerm";
import { TContact, TContentItem } from "../../../../../../../types/typesStoreData";
import { TextLink } from "../TextLink/TextLink";
import { TPropsListItem } from "./PropsList";

export const getPropsList = (type: string, contentItem?: TContentItem): TPropsListItem[] => {
    const placeHolder: TPropsListItem = {
        id: "placeholder",
        therm: "Therm placeholder",
        description: "Therm description placeholder",
        isPlaceHolder: true,
        weight: 0,
    }

    const constructTermArray = (propsList: { key: string, title: string, convert?: string, weight: number }[]) => {
        return _.reduce(contentItem, (res, val, key): TPropsListItem[] => {
            const propItem = propsList.find(item => key === item.key);
            if (propItem) {
                const getValue = (): ReactNode => {
                    if (!val || (Array.isArray(val) && !val.length)) return;
                    if (!propItem.convert) return val.toString();
                    switch (propItem.convert) {
                        case "localDateTime": return new Date(val.toString()).toLocaleString();
                        case "localDate": return new Date(val.toString()).toLocaleDateString();
                        case "localTime": return new Date(val.toString()).toLocaleTimeString();
                        case "contactLinks":
                            const contacts = val as TContact[];
                            return <div className="row mx-n1">
                                {
                                    contacts.map(contact => <TextLink key={`contactLink_${contact.id}`} type="contacts" contentItem={contact} variant="compact" />)
                                }
                            </div>

                        default: return val.toString();
                    }
                }

                return [
                    ...res,
                    {
                        id: key,
                        therm: propItem.title,
                        isPlaceHolder: false,
                        description: getValue(),
                        weight: propItem.weight
                    }
                ]
            } else {
                return [...res]
            }
        }, [] as TPropsListItem[])
    }

    switch (type) {
        case "me":
            const meProps = [
                { key: "username", title: translateTerm("user name", true), weight: 10 },
                { key: "email", title: "E-mail", weight: 20 },
                { key: "createdAt", title: translateTerm("registered", true), convert: "localDateTime", weight: 30 }
            ];
            return constructTermArray(meProps);

        case "userPersonals":
            const userPersonalProps = [
                { key: "firstName", title: translateTerm("first name", true), weight: 20 },
                { key: "middleName", title: translateTerm("middle name", true), weight: 30 },
                { key: "lastName", title: translateTerm("last name", true), weight: 10 },
                { key: "birthDate", title: translateTerm("birth date", true), convert: "localDate", weight: 40 },
            ];
            return constructTermArray(userPersonalProps);

        case "workgroups":
            const workGroupProps = [
                { key: "label", title: translateTerm("label", true), weight: 10 },
                { key: "type", title: translateTerm("group type", true), weight: 20 },
            ]
            return constructTermArray(workGroupProps);

        case "workgroupDetails":
            const workgroupDetailProps = [
                { key: "title", title: translateTerm("group name", true), weight: 10 },
                { key: "titleFull", title: translateTerm("title full", true), weight: 30 },
                { key: "titleShort", title: translateTerm("title short", true), weight: 40 },
                { key: "firstName", title: translateTerm("first name", true), weight: 50 },
                { key: "middleName", title: translateTerm("middle name", true), weight: 60 },
                { key: "lastName", title: translateTerm("last name", true), weight: 70 },
                { key: "inn", title: translateTerm("inn", true), weight: 80 },
                { key: "ogrnip", title: translateTerm("ogrnip", true), weight: 90 },
                { key: "ogrn", title: translateTerm("ogrn", true), weight: 100 },
                { key: "kpp", title: translateTerm("kpp", true), weight: 110 },
            ];
            return constructTermArray(workgroupDetailProps);

        case "workgroupAddress":
            const address = [
                { key: "zipCode", title: translateTerm("zip-code", true), weight: 10 },
                { key: "country", title: translateTerm("country", true), weight: 20 },
                { key: "region", title: translateTerm("country region", true), weight: 30 },
                { key: "populatedArea", title: translateTerm("populated area", true), weight: 40 },
                { key: "string1", title: translateTerm("street", true), weight: 50 },
                { key: "string2", title: translateTerm("house number", true), weight: 60 },
                { key: "string3", title: translateTerm("office (apartment) number", true), weight: 70 },
            ]
            return constructTermArray(address);

        case "contacts":
            const contactProps = [
                { key: "contactPhones", title: translateTerm("phone", true), convert: "contactLinks", weight: 10 },
                { key: "contactEmails", title: translateTerm("e-mail", true), convert: "contactLinks", weight: 20 },
                { key: "contactSocials", title: translateTerm("social", true), convert: "contactLinks", weight: 30 },
                { key: "contactSites", title: translateTerm("site", true), convert: "contactLinks", weight: 40 },
            ]
            return constructTermArray(contactProps);

        default: return [1, 2, 3].map((item, i) => ({
            ...placeHolder,
            id: `${placeHolder.id}-${i}`
        }))
    }
}
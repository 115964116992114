import { store } from "../../store/store"
import { TUser } from "../../types/typesUser";
import { TPermissionObjects } from "../../types/typesBase";
import { denormalize } from "normalizr";
import { schemaNormalizeUser } from "../../store/normalizers/schema/schemaNormalizeUser";

export const checkObjectPermissions = (apiName: string, objectID: string, permission: "view" | "edit") => {
    const permissions = store.getState().sliceData.collections[apiName]?.[objectID]?.access?.object;
    if (permissions) {
        const currentUserID = store.getState().sliceData.activeItems.me;
        if (!currentUserID) return false;
        const currentUser = store.getState().sliceData.collections.users?.[currentUserID];
        if (!currentUser) return false;
        const fullUser = denormalize(currentUser, schemaNormalizeUser(), store.getState().sliceData.collections) as TUser;
        switch (permission) {
            case "view":
                if (permissions.view) {
                    return checkPermission(permissions.view, fullUser)
                } else return true;
            case "edit":
                if (permissions.edit) {
                    return checkPermission(permissions.edit, fullUser)
                } else return true;
        }
    }
    return true
}

const checkPermission = (permission: TPermissionObjects, user: TUser) => {
    const rolePermission = permission.roles ? permission.roles.findIndex(roleID => user.role.id === roleID) : false;
    const idPermission = permission.users ? permission.users.findIndex(userID => user.id === userID) : false;
    return (rolePermission || idPermission)
}
import { useEffect } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";
import { useMessenger } from "../../../../hooks/useMessenger";
import { TToast } from "../../../typesTemplate";

export const Toast: React.FC<TToast> = (props) => {
    const messenger = useMessenger();


    const styleClassMap = {
        success: {
            head: "  bg-success text-white ",
            close: " text-white ",
            body: " alert-success text-success ",
        },
        danger: {
            head: " bg-danger text-white ",
            close: " text-white ",
            body: " alert-danger text-danger ",
        },
        info: {
            head: " bg-info text-white ",
            close: " text-white ",
            body: " alert-info text-info ",
        }
    };

    useEffect(() => {
        if (props.timeout) {
            new Promise((resolve) => setTimeout(() => resolve(props.id), props.timeout))
                .then(() => messenger.removeMessage(props.id as string));
        }
    }, [])

    return <div id={"message_" + props.id} key={"message_" + props.id}
        className="toast border-0 fade show mb-3"
        role="alert" aria-live="assertive"
        aria-atomic="true"
    >
        <div className={"toast-header " + styleClassMap[props.type].head}>
            <strong className="me-auto">{props.title}</strong>
            <small className="pl-2">{props.time}</small>
            <button onClick={() => messenger.removeMessage(props.id)}
                id={props.id}
                className="btn border-0 ms-2 p-0 text-reset icon-action-close fs-8"
                style={{ right: "0.5rem" }}
                data-dismiss="toast" aria-label="Close" />
        </div>
        <div className={"toast-body " + styleClassMap[props.type].body}>
            <ReactMarkdown children={props.content} className="lh-sm" rehypePlugins={[rehypeRaw]} />
        </div>
    </div>

}
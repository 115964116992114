import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getActiveDataItem, getActives } from "../../../../store/storeGetters";
import { RootState } from "../../../../store/store";
import { TLink } from "../../../../types/typesStoreData";
import { ViewMenuItemBadge } from "../Block/View/ViewItem/MenuIcons/ViewMenuItemBadge";
import { TSitePage } from "../../../../types/typesStructure";
import { mutateDataItem } from "../../../library/Mutation/mutateDataItem";
import { getAction } from "../../../../actions/getAction";
import { getShowUserMenu, setShowBurgerMenu, setShowUserMenu } from "../../../../store/slices/sliceIfSettings";
import { Dropdown } from "react-bootstrap";
import { SectionContent } from "../SectionContent";

export const HeaderMainMenu: React.FC<{ menuItems: TLink[] }> = ({ menuItems }) => {
    const currentPage = useSelector((state: RootState) => getActiveDataItem("pages", state) as TSitePage);
    const active = useSelector(getActives);
    return <>{menuItems.map(item => {
        const mutantItem = mutateDataItem(item, active) as TLink;
        return <MainMenuItem
            key={`mainMenuItem_${mutantItem.id}`}
            isActive={mutantItem.url ? currentPage?.url.includes(mutantItem.url) : false}
            item={mutantItem} />
    })}</>
}

export const MainMenuItem: React.FC<{ item: TLink, isActive: boolean }> = ({ item, isActive }) => {
    const dispatch = useDispatch();
    const iconNormal = item.iconNormal || "icon-status-error";
    const iconActive = item.iconActive || "icon-status-error";
    const showSubmenu = useSelector((state: RootState) => getShowUserMenu(state, item.id))

    const icon = item.image
        ? <div className="avatar-xs bg-img-cover rounded-circle shadow-sm" style={{ backgroundImage: `url(${item.image})` }} />
        : <span className={"d-flex avatar-xs align-items-center justify-content-center fs-5 " + (item.disabled ? "" : "icon-hover ") + (isActive ? iconActive : iconNormal)}
        />

    // <span className={"d-flex justify-content-center " + item.iconClassName} style={{fontSize: "1.3rem"}}/> :
    // <span className="d-flex justify-content-center icon-status-error" style={{fontSize: "1.3rem"}}/>;

    const MenuItem: React.FC = () => {
        const itemLabel = <div className="d-none d-md-block fs-7 lh-1 h6 fw-normal mb-0 text-center text-truncate w-100 bottom-0">
            {item.title}
        </div>

        switch (item.type) {
            case "local":
                return <>
                    <Link to={!item.disabled && item.url ? item.url : "#"}
                        title={item.description}
                        onClick={item.dropdownSectionName
                            ? (e) => {
                                e.preventDefault();
                                dispatch(setShowBurgerMenu(false))
                                dispatch(setShowUserMenu({ ID: item.id, state: showSubmenu ? false : true }))
                            }
                            : undefined}
                        className={`position-relative text-secondary mx-auto py-md-1 ${item.disabled ? "disabled" : ""}`} >
                        {item.badge ? <ViewMenuItemBadge /> : null}
                        {icon}
                    </Link>
                    {itemLabel}
                </>
            case "external":
            case "action":
                return <>
                    <a title={item.description}
                        className={"position-relative text-secondary mx-auto py-md-1" + (item.disabled ? "disabled" : "")}
                        href={item.disabled ? "#" : item.url}
                        onClick={
                            (e) => {
                                if (item.action) {
                                    e.preventDefault();
                                    dispatch(setShowBurgerMenu(false));
                                    dispatch(setShowUserMenu({ ID: item.id, state: showSubmenu ? false : true }))
                                    getAction(item.action.name)(item.action.argument);
                                }
                            }
                        }
                    >
                        {item.badge ? <ViewMenuItemBadge /> : null}
                        {icon}
                    </a>
                    {itemLabel}
                </>

        }
    }

    return <Dropdown show={showSubmenu} onToggle={() => dispatch(setShowUserMenu({ ID: item.id, state: false }))}
        className="d-flex flex-column align-items-center justify-content-center px-2 px-md-1 thumb-md-sm">
        <MenuItem />
        {
            item.dropdownSectionName && !item.disabled
                ? <Dropdown.Menu as={"div"} className="top-100 end-0 mt-1 border-0 overflow-hidden rounded shadow" >
                    <SectionContent name={item.dropdownSectionName} />
                </Dropdown.Menu>
                : null
        }
    </Dropdown>
}
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { RootState } from "./store/store";
import { getDataItems } from "./store/storeGetters";
import { Template } from "./template/Template";
import { TSitePage } from "./types/typesStructure";

export const App: React.FC = () => {
    const pages = useSelector((state: RootState) => getDataItems("pages", undefined, state)) as TSitePage[];
    return <Routes>
        {pages?.map(page => <Route key={`page_${page.id}`} path={page.url} element={<Template />} />)}
        <Route path="*" element={<Template />} />
    </Routes>
}
import { schema } from "normalizr";
import { schemaNormalizeApiRoute } from "./schema/schemaNormalizeApiRoute";
import { schemaNormalizeBanner } from "./schema/schemaNormalizeBanner";
import { schemaNormalizeBlock } from "./schema/schemaNormalizeBlock";
import { schemaNormalizeDictionary } from "./schema/schemaNormalizeDictionary";
import { schemaNormalizeFaqItem } from "./schema/schemaNormalizeFaqItem";
import { schemaNormalizeForm } from "./schema/schemaNormalizeForm";
import { schemaNormalizeHint } from "./schema/schemaNormalizeHint";
import { schemaNormalizeLayout } from "./schema/schemaNormalizeLayout";
import { schemaNormalizeLink } from "./schema/schemaNormalizeLink";
import { schemaNormalizeMediaItem } from "./schema/schemaNormalizeMediaItem";
import { schemaNormalizeNewsItem } from "./schema/schemaNormalizeNewsItem";
import { schemaNormalizePage } from "./schema/schemaNormalizePage";
import { schemaNormalizePublication } from "./schema/schemaNormalizePublication";
import { schemaNormalizeSiteProperty } from "./schema/schemaNormalizeSiteProperties";
import { schemaNormalizeUser } from "./schema/schemaNormalizeUser";
import { schemaNormalizeUserCard } from "./schema/schemaNormalizeUserCard";
import { schemaNormalizeUserPersonals } from "./schema/schemaNormalizeUserPersonals";
import { schemaNormalizeView } from "./schema/schemaNormalizeView";
import { schemaNormalizeWGCard } from "./schema/schemaNormalizeWGCard";
import { schemaNormalizeWGCardFolder } from "./schema/schemaNormalizeWGCardFolder";
import { schemaNormalizeWorkgroup } from "./schema/schemaNormalizeWorkgroup";

export const schemaNormalize = (name: string): (() => schema.Entity<any> | schema.Array<any>) | undefined => {
    switch (name) {
        case "apiRoutes": return (() => new schema.Array([schemaNormalizeApiRoute()]))
        case "apiRoutesSingle": return schemaNormalizeApiRoute
        case "pages": return (() => new schema.Array([schemaNormalizePage()]))
        case "pagesSingle": return schemaNormalizePage
        case "layouts": return (() => new schema.Array([schemaNormalizeLayout()]))
        case "layoutsSingle": return schemaNormalizeLayout
        case "blocks": return (() => new schema.Array([schemaNormalizeBlock()]))
        case "blocksSingle": return schemaNormalizeBlock
        case "views": return (() => new schema.Array([schemaNormalizeView()]))
        case "viewsSingle": return schemaNormalizeView
        case "banners": return (() => new schema.Array([schemaNormalizeBanner()]))
        case "bannersSingle": return schemaNormalizeBanner
        case "faqItems": return (() => new schema.Array([schemaNormalizeFaqItem()]))
        case "faqItemsSingle": return schemaNormalizeFaqItem
        case "forms": return (() => new schema.Array([schemaNormalizeForm()]))
        case "formsSingle": return schemaNormalizeForm
        case "hints": return (() => new schema.Array([schemaNormalizeHint()]))
        case "hintsSingle": return schemaNormalizeHint
        case "mediaItems": return (() => new schema.Array([schemaNormalizeMediaItem()]))
        case "mediaItemsSingle": return schemaNormalizeMediaItem
        case "links": return (() => new schema.Array([schemaNormalizeLink()]))
        case "linksSingle": return schemaNormalizeLink
        case "newsItems": return (() => new schema.Array([schemaNormalizeNewsItem()]))
        case "newsItemsSingle": return schemaNormalizeNewsItem
        case "publications": return (() => new schema.Array([schemaNormalizePublication()]))
        case "publicationsSingle": return schemaNormalizePublication
        case "workgroupCardFolders": return (() => new schema.Array([schemaNormalizeWGCardFolder()]))
        case "workgroupCardFoldersSingle": return schemaNormalizeWGCardFolder
        case "workgroupCards": return (() => new schema.Array([schemaNormalizeWGCard()]))
        case "workgroupCardsSingle": return schemaNormalizeWGCard
        case "siteProperties": return (() => new schema.Array([schemaNormalizeSiteProperty()]))
        case "sitePropertiesSingle": return schemaNormalizeSiteProperty
        case "dictionaries": return (() => new schema.Array([schemaNormalizeDictionary()]))
        case "dictionariesSingle": return schemaNormalizeDictionary
        case "users": return (() => new schema.Array([schemaNormalizeUser()]))
        case "usersSingle": return schemaNormalizeUser
        case "userCards": return (() => new schema.Array([schemaNormalizeUserCard()]))
        case "userCardsSingle": return schemaNormalizeUserCard
        case "userPersonals": return (() => new schema.Array([schemaNormalizeUserPersonals()]))
        case "userPersonalsSingle": return schemaNormalizeUserPersonals
        case "workgroups": return (() => new schema.Array([schemaNormalizeWorkgroup()]))
        case "workgroupsSingle": return schemaNormalizeWorkgroup

        default: return undefined;
    }
}
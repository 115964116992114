import { Accordion } from "react-bootstrap";
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw";
import { TContentItem } from "../../../../../../types/typesStoreData";
import { getViewItemData } from "./getViewItemData";

export const AccordionItem: React.FC<{ contentItem?: TContentItem, onClick: () => void, active?: boolean, eventKey: string, type: string }> = ({ contentItem, onClick, active, eventKey, type }) => {
    const accordionItem = getViewItemData(type, contentItem)

    return <div className={"my-1 rounded-1 bg-white overflow-hidden" + (active ? " shadow-sm" : "") + (accordionItem.isPlaceholder ? " placeholder-wave" : "")}>
        <h6 className={"d-block p-2 mb-0 mouse-pointer text-truncate lh-1" + (active ? " bg-light" : " bg-hover-light")}
            onClick={onClick}>
            <span className={accordionItem.isPlaceholder ? "placeholder rounded-1" : ""}>{accordionItem.title}</span>
        </h6>
        <Accordion.Collapse eventKey={eventKey} className="border-top" >
            <div className="p-2">
                {
                    !accordionItem.isPlaceholder && accordionItem.image ?
                        <div className="rounded-1 bg-light overflow-hidden float-start me-2">
                            <div className={"ratio ratio-4x3 thumb-xl bg-img-cover border-bottom" + (accordionItem.isPlaceholder ? "  bg-midlight" : "")}
                                style={{ backgroundImage: `url(${accordionItem.image})` }} />
                            {
                                accordionItem.date ?
                                    <span className="small fst-italic small px-2 py-1">
                                        {new Date(accordionItem.date).toLocaleDateString()}
                                    </span> : null
                            }
                        </div> : null
                }
                <ReactMarkdown children={accordionItem.content || ""}
                    className={"small" + (accordionItem.isPlaceholder ? "  opacity-50" : "")}
                    rehypePlugins={[rehypeRaw]} />

                {/* {props.link ?
                <div className="text-end">
                    {props.link.indexOf("://") === -1 ?
                        <Link to={props.link} className="small text-warning fst-italic small" >Подробнее...</Link> :
                        <a href={props.link} className="small text-warning fst-italic small" target="_blank">Подробнее...</a>
                    }
                </div> : null
            } */}
                <div className="clearfix" />
            </div>
        </Accordion.Collapse>
    </div>

}

import { nanoid } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { getAction } from "../../actions/getAction";
import { fixIDsToNumber } from "../../library/backendObjectsFix";
import { translateTerm } from "../../library/translateTerm";
import { Messenger } from "../../services/Messenger";
import { LOCAL_API_URL } from "../../settings/constants";
import { TBackendObject, TBackendObjectData } from "../../types/typesBackendData";
import { TDataItem } from "../../types/typesStoreData";
import { TApiRoute } from "../../types/typesStructure";
import { dataUpdate, dataUpdatePending, dataUpdateRejected, dataUpdateSucceed } from "../actions/actionsExtra";
import { apiFetchData, TRequestProps } from "../requests/apiFetch";
import { store } from "../store";
import { getNormalized } from "./getNormalized";

export function* dataUpdateWorker({ payload }: ReturnType<typeof dataUpdate>) {
    const apiWithoutDataField = ["me", "login", "register", "forgotPassword", "users", "resetPassword"]

    const apiRoutes = store.getState().sliceData.collections.apiRoutes
    const routeUrl = payload.apiName === "apiRoutes"
        ? "/api/api-routes"
        : payload.apiName === "me"
            ? (Object.values(apiRoutes) as unknown as TApiRoute[]).find(item => item.storageName === "users")?.url
            : (Object.values(apiRoutes) as unknown as TApiRoute[]).find(item => item.storageName === payload.apiName)?.url
    const status = store.getState().sliceData.fetchStatuses[payload.apiName];
    const jwt = store.getState().sliceIfSettings.jwt;
    const itemID = payload.body?.id
    if (routeUrl && status !== "progress") {
        yield put(dataUpdatePending(payload.formID))
        // Формирую адрес для запроса
        const currentHostName = document.location.hostname
        const requestHostName = currentHostName === "localhost" ? LOCAL_API_URL : `${document.location.protocol}//${document.location.hostname}`

        const fixedBody = fixIDsToNumber(payload.body) as Partial<TDataItem>
        delete fixedBody.id

        // Формирую запрос
        const request: TRequestProps = {
            url: `${requestHostName}${routeUrl}${itemID ? `/${itemID}` : ""}`,
            method: !itemID ? "POST" : "PUT",
            responseFormat: "json",
            body: apiWithoutDataField.find(item => item === payload.apiName) ? fixedBody : { data: fixedBody },
            headers: jwt ? { ...payload.headers, Authorization: `Bearer ${jwt}` } : payload.headers,
        }
        // console.log(request)

        // Запуск функции запроса
        // console.log(payload, request)
        const fetchObject: TBackendObject = yield call(() => apiFetchData(request));

        if (fetchObject.error) {
            yield put(dataUpdateRejected({ formID: payload.formID, response: `${fetchObject.error.message}` }));
            if (payload.onErrorShowToast) {
                const messenger = new Messenger(store.dispatch, store.getState().sliceIfSettings.toasts.slice(), store.getState().sliceIfSettings.toastsStatus)
                messenger.addMessage({
                    id: nanoid(),
                    title: translateTerm("error", true),
                    content: translateTerm(fetchObject.error.message, true),
                    type: "danger",
                    timeout: 5000,
                })
                messenger.send();
            }

        } else {
            let parentFetchObject: TBackendObject | undefined = undefined;
            if (payload.parent && !payload.body.id) {
                const parent = payload.parent.apiName === "me"
                    ? store.getState().sliceData.collections.users[store.getState().sliceData.activeItems.me]
                    : payload.parent.ID
                        ? store.getState().sliceData.collections[payload.parent.apiName][payload.parent.ID]
                        : undefined
                const parentRouteUrl = payload.parent.apiName === "apiRoutes"
                    ? "/api/api-routes"
                    : payload.parent.apiName === "me"
                        ? (Object.values(apiRoutes) as unknown as TApiRoute[]).find(item => (item.storageName === "users"))?.url
                        : (Object.values(apiRoutes) as unknown as TApiRoute[]).find(item => (item.storageName === payload.parent?.apiName))?.url
                if (parentRouteUrl && parent) {
                    const newObjectID = (fetchObject.data as TBackendObjectData).id
                    const parentFieldContent = (parent as { [a: string]: any })[payload.parent.field]
                    const newFieldContent = parentFieldContent
                        ? Array.isArray(parentFieldContent)
                            ? [...parentFieldContent, newObjectID]
                            : [newObjectID]
                        : payload.parent.asArray
                            ? [newObjectID]
                            : newObjectID;

                    const parentBody = { [payload.parent.field]: newFieldContent }

                    // Формирую запрос родителя
                    const parentRequest: TRequestProps = {
                        url: `${requestHostName}${parentRouteUrl}${payload.parent.ID ? `/${payload.parent.ID}` : ""}`,
                        method: "PUT",
                        responseFormat: "json",
                        body: apiWithoutDataField.find(item => item === payload.parent?.apiName) ? parentBody : { data: parentBody },
                        headers: jwt ? { ...payload.headers, Authorization: `Bearer ${jwt}` } : payload.headers,
                    }

                    // Запуск функции запроса родителя
                    // console.log(parentRequest)
                    parentFetchObject = yield call(() => apiFetchData(parentRequest));

                    if (parentFetchObject?.error) {
                        yield put(dataUpdateRejected({ formID: payload.formID, response: `parent: ${parentFetchObject.error.message}` }));

                        if (payload.onErrorShowToast) {
                            const messenger = new Messenger(store.dispatch, store.getState().sliceIfSettings.toasts.slice(), store.getState().sliceIfSettings.toastsStatus)
                            messenger.addMessage({
                                id: nanoid(),
                                title: translateTerm("error", true),
                                content: translateTerm(parentFetchObject.error.message, true),
                                type: "danger",
                                timeout: 5000,
                            })
                            messenger.send();
                        }

                    }
                }
            }

            const normalizedData = payload.parent && parentFetchObject
                ? { ...getNormalized(fetchObject, payload.apiName), ...getNormalized(parentFetchObject, payload.parent.apiName) }
                : getNormalized(fetchObject, payload.apiName)
            yield put(dataUpdateSucceed({
                apiName: payload.apiName,
                formID: payload.formID,
                collections: normalizedData || {}
            }))

            if (payload.onSuccessToastMessage) {
                const messenger = new Messenger(store.dispatch, store.getState().sliceIfSettings.toasts.slice(), store.getState().sliceIfSettings.toastsStatus)
                messenger.addMessage({
                    id: nanoid(),
                    title: translateTerm("success", true),
                    content: payload.onSuccessToastMessage,
                    type: "success",
                    timeout: 5000,
                })
                messenger.send();
            }
            if (payload.action) {
                const action = getAction(payload.action.name);
                action(payload.action.argument);
            }
        }
    }
}
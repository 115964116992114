import { Dispatch } from "@reduxjs/toolkit";
import { setToasts, setToastsStatus } from "../store/slices/sliceIfSettings";
import { TToast, TToastStatus } from "../template/typesTemplate";

export class Messenger {

    private dispatch: Dispatch;
    private messages: TToast[] = [];
    private status: TToastStatus;

    constructor(dispatch: Dispatch, messages: TToast[], status: TToastStatus) {
        this.dispatch = dispatch
        this.messages = messages;
        this.status = status;
    }

    getToasts() {
        return this.messages;
    }

    setToasts(messages: TToast[]) {
        this.messages = messages;
        return this;
    }

    getStatus() {
        return this.status;
    }

    received() {
        this.dispatch(setToastsStatus('received'));
    }

    addMessage(message: TToast) {
        if (!this.messages.find(item => item.id === message.id)) {
            message.time = new Date().toLocaleTimeString();
            // if (!message.timeout) message.timeout = 5000;
            // if (message.timeout === 0) message.timeout = undefined;
            this.messages.push(message);
        }
        return this;
    }

    removeMessage(id: TToast['id']) {
        this.messages = this.messages.filter(item => item.id !== id);
        this.dispatch(setToasts(this.messages));
    }

    send() {
        this.dispatch(setToastsStatus('notReceived'));
        this.dispatch(setToasts(this.messages));
        this.messages = []
    }

    clear() {
        if (this.status === 'received') this.dispatch(setToasts([]));
    }
}
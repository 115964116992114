import "../assets/css/app.scss"
import React, { MouseEventHandler } from "react";
import { SectionHeader } from "./Section/SectionHeader/SectionHeader";
import { SectionFooter } from "./Section/SectionFooter";
import { SectionCookie } from "./Section/SectionCookie";
import { SectionModal } from "./Section/SectionModal";
import { useDispatch, useSelector } from "react-redux";
import { getShowBurgerMenu, getShowSidebar, setShowBurgerMenu } from "../../store/slices/sliceIfSettings";
import { Offcanvas, OffcanvasBody } from "react-bootstrap";
import { CloseButton } from "../Controls/CloseButton";
import { SectionContent } from "./Section/SectionContent";
import { PageLayoutMenu } from "./PageLayoutMenu";
import { SectionToasts } from "./Section/SectionToasts/SectionToasts";

export const PageDefault: React.FC = () => {
    const showSideBar = useSelector(getShowSidebar);
    const dispatch = useDispatch();
    const showBurgerMenu = useSelector(getShowBurgerMenu);

    const burgerHideHandler: MouseEventHandler = (e) => {
        dispatch(setShowBurgerMenu(false));
    }

    return <>
        <div className="d-flex flex-column flex-fill bg-light">
            <SectionHeader />
            <div className="flex-fill d-flex flex-column">
                <div className="position-fixed w-100" style={{ height: 0 }}>
                    <div className="container-lg h-100">
                        <div className="d-none d-lg-block col-3 overflow-hidden" id="section_sidebar" style={!showSideBar ? { width: 0 } : undefined}>

                            <Offcanvas id="section_burger"
                                show={showBurgerMenu}
                                scroll={true}
                                onHide={burgerHideHandler}
                                className="bg-light">
                                <OffcanvasBody>
                                    <CloseButton handler={burgerHideHandler} />
                                    <SectionContent name="sidebar" phTemplate="menu" />
                                </OffcanvasBody>
                            </Offcanvas>

                            <div className="me-3 my-2 overflow-hidden">
                                <SectionContent name="sidebar" phTemplate="menu" />
                            </div>


                        </div>
                    </div>
                </div>
                <div className='container-lg flex-fill d-flex'>
                    <div className="d-none d-lg-block col" />
                    <div className={"d-flex flex-column font-small-2 text-break col-12 py-2" + (showSideBar ? " col-lg-9" : "")}>

                        <div id="section_banner" className="d-flex flex-column">
                            <SectionContent name="banner" phTemplate="banner" />
                        </div>

                        <div id="section_alerts" className="d-flex flex-column">
                            <SectionContent name="pageAlerts" />
                        </div>

                        <div id="section_content" className="d-flex flex-column">
                            <PageLayoutMenu />
                            <SectionContent name="layoutAlerts" />
                            <SectionContent name="content" phTemplate="loader" />
                        </div>

                    </div>
                </div>
            </div>
            <SectionFooter />
            <SectionToasts />
            <SectionCookie />
            <SectionModal />
        </div>
    </>

}
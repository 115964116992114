/**
 * API constants
 */

// export const LOCAL_API_URL = "http://localhost:5678"
export const LOCAL_API_URL = "http://localhost:8000"

/**
 * Actions constants
 */
export const APP_INITIAL_STATE = "app/initial";
export const INITIAL_FETCH = "initial/fetch";
export const LOCALE_FETCH = "locale/fetch";

export const COLLECTION_FETCH = "collection/fetch";
export const COLLECTION_FETCH_PENDING = "collection/fetch-pending";
export const COLLECTION_ITEM_FETCH_PENDING = "collectionItem/fetch-pending"
export const COLLECTION_FETCH_SUCCEED = "collection/fetch-succeed";
export const COLLECTION_FETCH_REJECTED = "collection/fetch-rejected";

export const IF_SETTINGS_FETCH = "ifSettings/fetch";
export const IF_SETTINGS_FETCH_PENDING = "ifSettings/fetch-pending";
export const IF_SETTINGS_FETCH_SUCCEED = "ifSettings/fetch-succeed";
export const IF_SETTINGS_FETCH_REJECTED = "ifSettings/fetch-rejected";

export const VIEW_FETCH = "view/fetch";
export const VIEW_FETCH_PENDING = "view/fetch-pending";
export const VIEW_FETCH_SUCCEED = "view/fetch-succeed";
export const VIEW_FETCH_REJECTED = "view/fetch-rejected";

export const VIEWS_FETCH = "views/fetch";
export const VIEWS_FETCH_PENDING = "views/fetch-pending";
export const VIEWS_FETCH_SUCCEED = "views/fetch-succeed";
export const VIEWS_FETCH_REJECTED = "views/fetch-rejected";

export const DATA_UPDATE = "data/update";
export const DATA_UPDATE_PENDING = "data/update-pending";
export const DATA_UPDATE_SUCCEED = "data/update-succeed";
export const DATA_UPDATE_REJECTED = "data/update-rejected";

export const USER_REGISTER_FETCH = "userRegister/fetch";
export const USER_REGISTER_FETCH_PENDING = "userRegister/fetch-pending";
export const USER_REGISTER_FETCH_SUCCEED = "userRegister/fetch-succeed";
export const USER_REGISTER_FETCH_REJECTED = "userRegister/fetch-rejected";

export const USER_LOGIN_FETCH = "userLogin/fetch";
export const USER_LOGIN_FETCH_PENDING = "userLogin/fetch-pending";
export const USER_LOGIN_FETCH_SUCCEED = "userLogin/fetch-succeed";
export const USER_LOGIN_FETCH_REJECTED = "userLogin/fetch-rejected";

export const USER_ME_FETCH = "userMe/fetch";
export const USER_ME_FETCH_PENDING = "userMe/fetch-pending";
export const USER_ME_FETCH_SUCCEED = "userMe/fetch-succeed";
export const USER_ME_FETCH_REJECTED = "userMe/fetch-rejected";


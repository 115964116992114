import _ from "lodash";
import React, { MouseEventHandler } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { RootState } from "../../store/store";
import { getActiveDataItem, getActives } from "../../store/storeGetters";
import { TDataItem } from "../../types/typesStoreData";
import { TPageLayout, TSitePage } from "../../types/typesStructure";
import { checkObjectPermissions } from "../library/checkPermissions";
import { mutateDataItem } from "../library/Mutation/mutateDataItem";

const MenuItem: React.FC<{ layOut: TPageLayout, isActive: boolean }> = ({ layOut, isActive }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultClassName = " btn-midlight bg-hover-secondary text-hover-light";
    const activeClassName = " btn-secondary";
    const disabledClassName = " btn-midlight disabled text-muted";

    searchParams.set("layouts", layOut.id);
    const newQuery = `${document.location.pathname}?${searchParams.toString()}`

    const handler: MouseEventHandler = (e) => {
        if (layOut.inactive) return;
        e.preventDefault()
        setSearchParams(searchParams, { replace: true })
    }

    return <a key={`layoutSwitch_${layOut.id}`}
        href={newQuery}
        title={layOut.description}
        onClick={layOut.inactive ? undefined : handler}
        className={`btn rounded-pill mx-1 text-nowrap${layOut.inactive ? disabledClassName : isActive ? activeClassName : defaultClassName}`}
    >
        {layOut.title}
    </a>
}

export const PageLayoutMenu: React.FC = () => {
    const currentPage = useSelector((state: RootState) => getActiveDataItem("pages", state)) as TSitePage;
    const actives = useSelector(getActives);
    const activeLayoutID = useSelector((state: RootState) => getActiveDataItem("layouts", state))?.id;
    // console.log(currentPage)
    if (!currentPage || !currentPage.layouts) return <div className="d-flex position-relative placeholder-wave">
        {[1, 2, 3].map(i => <a key={`layoutSwitchPh_${i}`}
            href="#"
            className="btn rounded-pill mx-1 text-nowrap text-secondary btn-midlight placeholder">Layout switch {i}</a>)}
    </div>
    const mutantPageLayouts = currentPage?.layouts ? currentPage.layouts.map(layout => mutateDataItem(layout as TDataItem, actives)) as TPageLayout[] : []
    if (mutantPageLayouts.length < 2) return null;
    const pageLayouts = _.sortBy(mutantPageLayouts.filter(item => !item.hidden && checkObjectPermissions("layouts", item.id, "view")), "weight")
    const pageLayoutActive = pageLayouts.find(item => item.id === activeLayoutID) || pageLayouts[0]
    return <div className="d-flex position-relative">
        <div className="d-flex overflow-auto">
            {pageLayouts.map((layOut, i) => (
                <MenuItem key={`layOutSwitch_${i}`} layOut={layOut} isActive={layOut.id === pageLayoutActive.id} />
            ))}
        </div>
    </div>
}


import { TPageBlock } from "../../types/typesStructure";

export const mediaPlaceholder: TPageBlock = {
    id: "mediaPHBlock",
    locale: "ru",
    placeholder: true,
    template: "default",
    section: "content",
    createdAt: "",
    views: [
        {
            id: "mediaPHView",
            locale: "ru",
            createdAt: "",
            api: "publications",
            viewTemplate: "slider",
            itemTemplate: "video",
            query: {},
            weight: 10,
            viewItems: {
                // publications: [undefined, undefined] as unknown as TContentItem[]
            },
        },
    ],
}
import { translateTerm } from "../../../library/translateTerm";
import { FooterMenuContacts } from "./SectionFooter/FooterMenuContacts";
import { FooterMenuDocs } from "./SectionFooter/FooterMenuDocs";
import { FooterMenuSocial } from "./SectionFooter/FooterMenuSocial";

export const SectionFooter: React.FC = () => {

    return <footer className='bg-secondary text-white py-4'>
        <div className='container-lg'>
            <div className='align-content-center d-flex flex-column flex-lg-row justify-content-between'>

                <FooterMenuSocial />
                <FooterMenuContacts />

                <div className='border-start d-none d-lg-block'></div>
                <div className='border-top d-block d-lg-none mb-3'></div>
                <div className='d-flex flex-column justify-content-center'>
                    <p>
                        <span>© &nbsp;</span>Russian Design Council. {translateTerm("all rights - reserved", true)}.
                    </p>
                    <FooterMenuDocs />
                </div>
            </div>
        </div>
    </footer>
}
import { schema } from "normalizr";
import { schemaNormalizeBanner } from "./schemaNormalizeBanner";
import { schemaNormalizeFaqItem } from "./schemaNormalizeFaqItem";
import { schemaNormalizeForm } from "./schemaNormalizeForm";
import { schemaNormalizeLink } from "./schemaNormalizeLink";
import { schemaNormalizeMediaItem } from "./schemaNormalizeMediaItem";
import { schemaNormalizeNewsItem } from "./schemaNormalizeNewsItem";
import { schemaNormalizePublication } from "./schemaNormalizePublication";
import { schemaNormalizeWGCard } from "./schemaNormalizeWGCard";
import { schemaNormalizeWGCardFolder } from "./schemaNormalizeWGCardFolder";
import { schemaNormalizeWorkgroup } from "./schemaNormalizeWorkgroup";

export const schemaNormalizeView = () => {
    const publication = schemaNormalizePublication();
    const banner = schemaNormalizeBanner();
    const newsItem = schemaNormalizeNewsItem();
    const mediaItem = schemaNormalizeMediaItem();
    const faqItem = schemaNormalizeFaqItem();
    const WGCard = schemaNormalizeWGCard();
    const WGCardFolder = schemaNormalizeWGCardFolder()
    const form = schemaNormalizeForm();
    const link = schemaNormalizeLink();
    const workgroup = schemaNormalizeWorkgroup();
    return new schema.Entity('views', {
        viewItems: {
            publications: [publication],
            banners: [banner],
            newsItems: [newsItem],
            mediaItems: [mediaItem],
            faqItems: [faqItem],
            workgroupCards: [WGCard],
            workgroupCardFolders: [WGCardFolder],
            workgroups: [workgroup],
            forms: [form],
            links: [link]
        }
    })
}
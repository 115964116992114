import _ from "lodash";
import { Button, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addFormSchemeGroup, getFormState, removeSchemeGroup, TFormSchemeItem } from "../../../../../../../../store/slices/sliceIfSettings";
import { RootState } from "../../../../../../../../store/store";
import { getActives, getDataItem } from "../../../../../../../../store/storeGetters";
import { TFormGroup } from "../../../../../../../../types/typesForm";
import { mutateDataItem } from "../../../../../../../library/Mutation/mutateDataItem";
import { FormGroup } from "./FormGroup";

export const FormGroupSet: React.FC<{ groupSet: TFormSchemeItem, formID: string, path: string, }> = ({ groupSet, formID, path }) => {
    const dispatch = useDispatch();
    const form = useSelector((state: RootState) => getDataItem("forms", formID, state));
    const formGroup = _.get(form, groupSet.formPath) as TFormGroup | undefined;
    const formState = useSelector((state: RootState) => getFormState(state, formID || "dummy"));
    const active = { ...useSelector(getActives), formState };
    const mutantGroup = formGroup ? (mutateDataItem(formGroup, active)) as TFormGroup : undefined;
    // const mutantGroup = formGroup;
    if (!mutantGroup || !formGroup) return null;

    const formGroupadd = () => {
        dispatch(addFormSchemeGroup({ formID, formGroup, path }))
    }
    const formGroupRemove = () => {
        dispatch(removeSchemeGroup({ formID, path }))
    }
    if (!mutantGroup || mutantGroup.hidden) return null

    return <>
        {
            mutantGroup.title
                ? <div className="position-relative col-12 px-1 mb-2 fs-7 text-truncate">
                    {mutantGroup.title}:
                </div>
                : null
        }
        {
            groupSet.asArray
                ? (groupSet.items as TFormSchemeItem[][])
                    .map((group, i) => <FormGroup
                        key={`group_${i}`}
                        group={group}
                        formID={formID}
                        path={`${path}.items.[${i}]`}
                        addHandler={
                            !mutantGroup.maxNum && Number(groupSet.items?.length) - 1 === i
                                || (mutantGroup.maxNum && Number(groupSet.items?.length) < mutantGroup.maxNum)
                                ? formGroupadd
                                : undefined
                        }
                        removeHandler={
                            ((!mutantGroup.minNum && Number(groupSet.items?.length))
                                || (mutantGroup.minNum && Number(groupSet.items?.length) > mutantGroup.minNum))
                                && Number(groupSet.items?.length) - 1 === i
                                ? formGroupRemove
                                : undefined
                        }
                    />)
                : <FormGroup
                    group={groupSet.items as TFormSchemeItem[]}
                    formID={formID}
                    path={`${path}.items`}
                    addHandler={
                        !groupSet.items
                            ? formGroupadd
                            : undefined
                    }
                    removeHandler={
                        groupSet.items && !mutantGroup.minNum
                            ? formGroupRemove
                            : undefined
                    }

                />
        }
        {
            !groupSet.items?.length
                ? <Form.Group as={Col} xs="12" className="px-2 text-end mb-3">
                    <Button variant="outline-secondary p-0" size="sm" onClick={formGroupadd}>
                        <span className="align-items-center avatar-xs d-flex fs-5 justify-content-center m-n1">&#43;</span>
                    </Button>
                </Form.Group>
                : null
        }
    </>
}
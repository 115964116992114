import React from "react";
import { useSelector } from "react-redux";
import { actionShowObjectByURL } from "../../../../actions/global/actionShowObjectByURL";
import { getCollectionStatus, getDataItemByAttr } from "../../../../store/storeGetters";
import { getCurrentLocale } from "../../../../store/slices/sliceIfSettings";
import { RootState } from "../../../../store/store";
import { TSiteProperties } from "../../../../types/typesStructure";

export const FooterMenuDocs: React.FC = () => {
    const locale = useSelector(getCurrentLocale);
    const props = useSelector((state: RootState) => getDataItemByAttr("siteProperties", "locale", locale, state)) as TSiteProperties;
    const propsStatus = useSelector((state: RootState) => getCollectionStatus("siteProperties", state));
    return props?.docsMenuItems.length || propsStatus !== "success" ?
        <div className={`d-flex flex-column small ${propsStatus !== "success" ? " placeholder-wave" : ""}`}>
            {
                propsStatus === "success" ?
                    props?.docsMenuItems.map(siteDoc => (
                        <a key={`document${siteDoc.id}`}
                            href="#"
                            className="text-reset text-hover-warning"
                            onClick={siteDoc?.url ? (e) => {
                                e.preventDefault()
                                actionShowObjectByURL({ url: siteDoc?.url || "", title: siteDoc.title })
                            } : undefined}
                        >
                            {siteDoc.title}
                        </a>
                    )) :

                    [...Array(2)]
                        .map((ph, i) => (
                            <a key={`placeholder${i}`}
                                href="#"
                                className="text-reset text-hover-warning placeholder fs-8 mb-1">
                                Documents menu placeholder
                            </a>
                        ))
            }
        </div>
        : null
    return null
}
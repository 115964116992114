import { MouseEventHandler } from "react"
import { Link } from "react-router-dom"

export const CloseButton: React.FC<{ handler?: MouseEventHandler, link?: string, type?: "close" | "back" }> = (props) => {
    const btClassName = "border border-midlight bg-white btn btn-sm end-0 top-0 m-2 p-2 position-absolute rounded-circle text-hover-warning text-secondary"
    const iconClassName = props.type === "back" ? "icon-action-return" : "icon-action-close";
    switch (props.handler ? "button" : props.link?.indexOf("://") === -1 ? "link" : "anchor") {
        case "link":
            return <Link to={props.link || "/"}
                className={btClassName}
                style={{ zIndex: 1 }}
                aria-label='Close'
                onClick={props.handler}>
                <span className={"d-flex small " + iconClassName} />
            </Link>

        case "button":
            return <button className={btClassName}
                style={{ zIndex: 1 }}
                aria-label='Close'
                onClick={props.handler}>
                <span className={"d-flex small " + iconClassName} />
            </button>

        default:
            return <a href={props.link}
                target="_blank"
                rel="noreferrer"
                className={btClassName}
                style={{ zIndex: 1 }}
                aria-label='Close'
                onClick={props.handler}>
                <span className={"d-flex small " + iconClassName} />
            </a>
    }
}
import _ from "lodash";
import { Link } from "react-router-dom";
import { TContentItem } from "../../../../../../../types/typesStoreData";
import { getItemPageHeader } from "./getItemPageHeader";

export type TPageHeaderViewItem = {
    id: string,
    title: string,
    subTitle?: string,
    description?: string,
    logo?: string,
    logoBgColor?: string,
    banner?: string,
    bannerBgColor?: string,
    logoShape: "square" | "circle",
    isPlaceholder?: boolean,
    disabled?: boolean,
    backAction?: (a?: any) => void,
    backUrl?: string,
}

export const ItemPageHeader: React.FC<{ type: string, contentItem?: TContentItem }> = ({ type, contentItem }) => {
    const header = getItemPageHeader(contentItem, type)

    return <div className={header.isPlaceholder ? "placeholder-wave" : ""}>

        <div className={"ratio w-100 overflow-hidden bg-img-cover rounded-top"
            + (header.logoShape === "circle" ? " ratio-4x1" : " ratio-3x1")
            + (header.isPlaceholder ? " bg-midlight" : " bg-white")}
            style={{ backgroundImage: header.banner ? `url(${header.banner})` : `linear-gradient(90deg, ${header.bannerBgColor || "rgb(var(--bs-midlight-rgb))"}, transparent)` }} />

        {/* <div className={`ratio w-100 overflow-hidden ratio-4x1 rounded-top  ${header.banner ? "bg-img-cover" : header.isPlaceholder ? "bg-midlight" : "bg-banner-organization"}`}
            style={header.banner ? { backgroundImage: `url(${header.banner})` } : undefined} /> */}

        <div className="d-flex flex-column align-items-start position-relative ps-5 pe-3 pe-md-4 pb-4 pt-4 pt-md-5">

            <div className={"position-absolute bottom-100 mb-n4 mb-md-n5 shadow-sm"
                + (header.logoShape === "circle" ? " rounded-circle" : " rounded-1")
                + (!header.logo || (header.logo && !header.logoBgColor) ? " bg-white" : "")}
                style={header.logo && header.logoBgColor ? { backgroundColor: header.logoBgColor } : undefined}>

                <div className={"ratio ratio-1x1 thumb-sm thumb-sm-md thumb-md-lg overflow-hidden m-1"
                    + (header.logoShape === "circle" ? " bg-img-cover rounded-circle" : " bg-img-contain rounded-1")
                    + (!header.logoBgColor ? " bg-white" : "")}
                    style={{ backgroundImage: header.logo ? `url(${header.logo})` : `linear-gradient(0deg, ${header.logoBgColor || "rgb(var(--bs-secondary-rgb))"}, transparent)` }} >
                    {
                        !header.logo
                            ? <div className="d-flex">
                                <span className="display-1 fw-bold lh-1 mx-auto my-auto text-white">{header.title.split(" ").map((item, i) => i <= 1 ? _.upperCase(item[0]) : null)}</span>
                            </div>
                            : null
                    }
                </div>
            </div>

            {/* <div className={"position-absolute bottom-100 mb-n4 mb-md-n5 shadow-sm "
                + (header.logoShape === "circle" ? " rounded-circle" : " rounded-1")
                + (header.logoBgColor ? "" : " bg-white")}
                style={{ backgroundColor: header.logoBgColor ? header.logoBgColor : undefined }}>
                <div className={"ratio ratio-1x1 thumb-sm thumb-sm-md thumb-md-lg m-1"
                    + (header.logoShape === "circle" ? " rounded-circle" : " rounded-1")
                    + (header.logo ? header.logoShape === "circle" ? " bg-img-cover" : " bg-img-contain" : header.isPlaceholder ? " bg-midlight" : " bg-logo-organization")}
                    style={{ backgroundImage: header.logo ? `url(${header.logo})` : undefined }} >
                    {!header.logo && !header.isPlaceholder
                        ? <span className="align-items-center d-flex display-1 fw-bold justify-content-center text-white">{header.title.toUpperCase()[0]}</span>
                        : null}
                </div>
            </div> */}


            <h4 className={"align-items-center d-flex mt-2 overflow-visible text-truncate" + (header.isPlaceholder ? " placeholder rounded mb-1 lh-1" : " mb-0")}>
                {
                    header.backUrl || header.backAction
                        ? <Link to={header.backUrl || "#"}
                            className="align-items-center bg-transparent d-flex fs-6 icon-action-return me-2 ms-n4 p-0 text-hover-warning text-secondary"
                            onClick={header.backAction} />
                        : null
                }

                {header.title}
            </h4>

            <div className="text-secondary text-truncate w-100">
                <span className={header.isPlaceholder ? " placeholder rounded lh-1" : ""}>{header.subTitle}</span>
            </div>
        </div>
    </div>
}

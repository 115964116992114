import { call, take } from "redux-saga/effects";
import { LOCALE_FETCH } from "../../settings/constants";
import { ifSettingsFetchWorker } from "../fetches/ifSettingsFetch";
import { collectionFetchWorker } from "../fetches/collectionFetch";

export function* sagaLocaleFetch() {
    // while (true) {
    //     yield take(LOCALE_FETCH);

    //     yield call(ifSettingsFetchWorker);

    //     yield call(() => {
    //         const apiName = "siteProperties"
    //         return collectionFetchWorker({
    //             payload: {
    //                 name: apiName,
    //                 single: true,
    //                 query: {
    //                     populate: [
    //                         "dummy",
    //                         "mainMenuItems",
    //                         "socialsMenuItems",
    //                         "contactsMenuItems",
    //                         "docsMenuItems",
    //                         "systemURLs",
    //                     ]
    //                 }
    //             },
    //             type: apiName
    //         })
    //     })

    //     yield call(() => {
    //         const apiName = "dictionaries"
    //         return collectionFetchWorker({
    //             payload: {
    //                 name: apiName,
    //                 single: true,
    //                 query: {
    //                     populate: ["terms"]
    //                 }
    //             },
    //             type: apiName
    //         })
    //     })

    //     yield call(() => {
    //         const apiName = "pages"
    //         return collectionFetchWorker({
    //             payload: {
    //                 name: apiName,
    //                 query: {}
    //             },
    //             type: apiName
    //         })
    //     })
    //     // yield call(pageFetchWorker);
    //     // yield call(pageViewsFetchWorker);
    // }
}
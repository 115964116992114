import _ from "lodash";
import { useSelector } from "react-redux";
import { getActiveDataItem, getActives, getCollectionStatus, getDataItemByAttr } from "../../../store/storeGetters";
import { RootState, store } from "../../../store/store";
import { Block } from "./Block/Block";
import { TPageBlock, TPageLayout, TSitePage, TSiteProperties } from "../../../types/typesStructure";
import { topBannerPlaceholder } from "../../Placeholders/topBannerPlaceholder";
import { sideMenuPlaceholder } from "../../Placeholders/sideMenuPlaceholder";
import { publicationPlaceholder } from "../../Placeholders/publicationPlaceholder";
import { newsPlaceholder } from "../../Placeholders/newsPlaceholder";
import { mediaPlaceholder } from "../../Placeholders/mediaPlaceholder";
import { getCurrentLocale } from "../../../store/slices/sliceIfSettings";
import { mutateDataItem } from "../../library/Mutation/mutateDataItem";
import { TDataItem } from "../../../types/typesStoreData";
import { checkObjectPermissions } from "../../library/checkPermissions";

export const SectionContent: React.FC<{ name: string, phTemplate?: "banner" | "loader" | "menu" }> = ({ name, phTemplate }) => {
    const actives = useSelector(getActives);
    const currentPage = useSelector((state: RootState) => getActiveDataItem("pages", state)) as TSitePage;
    const currentLayoutID = store.getState().sliceData.activeItems["layouts"];
    const mutantPageLayouts = currentPage?.layouts ? currentPage.layouts.map(layout => mutateDataItem(layout as TDataItem, actives)) as TPageLayout[] : []
    const pageLayouts = _.sortBy(mutantPageLayouts.filter(item => checkObjectPermissions("layouts", item.id, "view")), "weight")
    const currentPageLayout = (currentLayoutID && pageLayouts.find(item => item.id === currentLayoutID)) || pageLayouts[0]
    const pagesStatus = useSelector((state: RootState) => getCollectionStatus("pages", state))
    const locale = useSelector(getCurrentLocale);
    const siteProps = useSelector((state: RootState) => getDataItemByAttr("siteProperties", "locale", locale, state) as TSiteProperties);
    const placeholder = () => {
        switch (phTemplate) {
            case "banner": return [topBannerPlaceholder];
            case "menu": return [sideMenuPlaceholder];
            default: return [publicationPlaceholder, newsPlaceholder, publicationPlaceholder, mediaPlaceholder];
        }
    }
    const sectionBlocks = _.sortBy([...currentPage?.blocks || [], ...currentPageLayout?.blocks || [], ...siteProps?.permanentBlocks || []]
        .map(block => mutateDataItem(block, actives) as TPageBlock)
        .filter(item => item.section === name && !item.hidden && checkObjectPermissions("blocks", item.id, "view")), "weight")

    // console.log(pageLayouts)

    return <>{phTemplate && pagesStatus !== "success" ?
        placeholder().map((block, i) => <Block key={`block_${block.id}_${i}_decorator`} block={block} />)
        : sectionBlocks?.map(block => <Block key={`block_${block.id}_decorator`} block={block} />)
    }</>
}


import { TFormGroup, TFormInput, TFormInputValue } from "../../types/typesForm";
import { TDataItem } from "../../types/typesStoreData";
import { TFormSchemeItem, TFormSchemeSet, TFormSchemeValue } from "../slices/sliceIfSettings";
import { setFormInputValue } from "./setFormInputValue";
import _ from "lodash"
import { getFormInputValue } from "./getFormInputValue";

export const constructFormSchemeGroup = (
    formInputs?: TFormInput[],
    formGroups?: TFormGroup[],
    object?: TDataItem,
    schemePrefix?: string,
    schemeObjPath?: string,
    schemeFormPath?: string,
    schemePath?: string): { scheme: TFormSchemeItem[], values: TFormSchemeValue[] } => {

    const newValues: TFormSchemeValue[] = []

    const getGroup = (inputs?: TFormInput[], formGroups?: TFormGroup[], prefix?: string, objPath?: string, formPath?: string, schemePath?: string): TFormSchemeItem[] => {
        return [
            ...(inputs || []).map((input, i) => {
                const inputValue = _.get(object, `${objPath ? objPath + "." : ""}${input.name}`) as TFormInputValue;
                const newSchemeItem = {
                    id: (prefix ? prefix + "." : "") + input.id,
                    name: input.name,
                    type: input.type,
                    weight: input.weight,
                    formPath: (formPath ? formPath + "\." : "") + "inputs.[" + i + "]",
                }
                const newValueItem = {
                    id: (prefix ? prefix + "." : "") + input.id,
                    name: input.name,
                    formPath: (formPath ? formPath + "\." : "") + "inputs.[" + i + "]",
                    type: input.type,
                    value: inputValue
                }
                switch (input.type) {
                    case "divider":
                        return newSchemeItem;
                    case "select":
                        if (input.idSelect) {
                            if (Array.isArray(inputValue)) {
                                const valueRelations = inputValue as unknown as TDataItem[];
                                newValues.push({ ...newValueItem, value: valueRelations?.map(item => item.id) });
                                return newSchemeItem;
                            } else {
                                const valueRelation = inputValue as unknown as TDataItem;
                                newValues.push({ ...newValueItem, value: valueRelation?.id });
                                return newSchemeItem;
                            }
                        } else {
                            newValues.push(newValueItem);
                            return newSchemeItem;
                        }
                    default:
                        newValues.push(newValueItem);
                        return newSchemeItem
                }
            }),
            ...(formGroups || []).map((subGroup, i) => {
                const objectGroupValue = _.get(object, (objPath ? objPath + "." : "") + subGroup.field);
                const groupFormPath = (formPath ? formPath + "\." : "") + "formGroups.[" + i + "]";
                return {
                    id: (prefix ? prefix + "." : "") + subGroup.id,
                    name: subGroup.field,
                    type: "group",
                    asArray: subGroup.asArray,
                    formPath: groupFormPath,
                    items: (() => {
                        if ((Array.isArray(objectGroupValue) || !objectGroupValue) && subGroup.asArray) {
                            const count = objectGroupValue?.length || subGroup.minNum || 0;
                            return Array(count).fill("").map((_, j) => j + 1)
                                .map((_, j) => getGroup(
                                    subGroup.inputs,
                                    subGroup.formGroups,
                                    `${prefix ? prefix + "." : ""}${j}`,
                                    `${objPath ? objPath + "." : ""}${subGroup.field}.[${j}]`,
                                    groupFormPath
                                ))
                        } else if ((typeof objectGroupValue === "object" || !objectGroupValue) && !subGroup.asArray) {
                            return getGroup(
                                subGroup.inputs,
                                subGroup.formGroups,
                                undefined,
                                `${objPath ? objPath + "." : ""}${subGroup.field}`,
                                groupFormPath
                            );
                        }
                    })()
                }
            }) as TFormSchemeItem[]
        ].sort((a, b) => a.weight > b.weight ? 1 : a.weight < b.weight ? -1 : 0)
    }

    return {
        scheme: getGroup(
            formInputs,
            formGroups,
            schemePrefix,
            schemeObjPath,
            schemeFormPath,
            schemePath
        ),
        values: newValues,
    }

}
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _, { compact } from "lodash";
import { denormalize } from "normalizr";
import { validate } from "../../library/Form/validate";
import { TModal, TToast, TToastStatus } from "../../template/typesTemplate";
import { TFetchStatus } from "../../types/typesBackendData";
import { TValidation } from "../../types/typesBase";
import { TForm, TFormGroup, TFormInput, TFormInputValue, TFormNormalized } from "../../types/typesForm";
import { TDataItem } from "../../types/typesStoreData";
import { TUser } from "../../types/typesUser";
import { dataUpdateSucceed, ifSettingsFetchingPending, ifSettingsFetchingRejected, ifSettingsFetchingSucceed, setInitialState } from "../actions/actionsExtra";
import { constructFormResult } from "../constructors/constructFormResult";
import { constructFormSchemeGroup } from "../constructors/constructFormSchemeGroup";
import { getFormInputValue } from "../constructors/getFormInputValue";
import { setFormInputValue } from "../constructors/setFormInputValue";
import { schemaNormalize } from "../normalizers/schemaNormalize";
import { RootState } from "../store";
import { getActiveDataItemIDs, getActiveDataItems, getActives, getDataItem, TActiveStoreItems } from "../storeGetters";

export type TLocalizedIfSettings = {
    showCookieAlert: boolean,
    locale: string
}

type TToastsStore = {
    toasts: TToast[],
    toastsStatus: TToastStatus
}

export type TFormSchemeItem = {
    id: string,
    name: string,
    type: string,
    weight: number,
    asArray?: boolean,
    items?: TFormSchemeItem[] & TFormSchemeItem[][],
    formPath: string,
}

export type TFormSchemeValue = {
    id: string,
    name: string,
    type: string,
    formPath: string,
    value?: TFormInputValue,
    validation?: TValidation,
    feedback?: string,
}

export type TFormSchemeSet = {
    scheme: TFormSchemeItem[],
    object?: { apiName: string, id: string }
    values: TFormSchemeValue[],
    validation?: boolean,
}

export type TInterfaceStore = {
    jwt?: string,
    locale: string,
    modal?: TModal,
    showModal: boolean,
    showSidebar: boolean,
    showBurgerMenu: boolean,
    showUserMenu: { [a: string]: boolean },
    showHints: boolean,
    status: TFetchStatus,
    localized: (TLocalizedIfSettings & {
        // alerts: TAlertPage[],
        // toasts: TAlertBase[],
    })[],
    toasts: TToast[],
    toastsStatus: TToastStatus,
    formSchemes: {
        [a: string]: TFormSchemeSet,
    }
}

const initialState: TInterfaceStore = {
    status: "init",
    modal: undefined,
    showModal: false,
    locale: "ru",
    showSidebar: true,
    showBurgerMenu: false,
    showUserMenu: {},
    showHints: true,
    localized: [],
    toasts: [],
    toastsStatus: "notReceived",
    formSchemes: {},
}

const sliceIfSettings = createSlice({
    name: "interface",
    initialState,
    reducers: {
        setShowSidebar: (state: TInterfaceStore, { payload }: PayloadAction<boolean>) => { state.showSidebar = payload; saveSettingsToLS(state) },
        setShowBurgerMenu: (state: TInterfaceStore, { payload }: PayloadAction<boolean>) => { state.showBurgerMenu = payload },
        setShowUserMenu: (state: TInterfaceStore, { payload }: PayloadAction<{ ID?: string, state: boolean }>) => {
            if (payload.ID) {
                state.showUserMenu = { ...state.showUserMenu, [payload.ID]: payload.state }
            } else {
                state.showUserMenu = _.reduce(state.showUserMenu, (res, val, key) => ({ ...res, [key]: false }), {})
            }
        },

        setShowHints: (state: TInterfaceStore, { payload }: PayloadAction<boolean>) => { state.showHints = payload; saveSettingsToLS(state) },
        setShowModal: (state: TInterfaceStore, { payload }: PayloadAction<boolean>) => { state.showModal = payload },
        setModal: (state: TInterfaceStore, { payload }: PayloadAction<TModal | undefined>) => { state.modal = payload },
        setSiteLocale: (state: TInterfaceStore, { payload }: PayloadAction<string>) => {
            if (state.localized.find(item => item.locale === payload)) {
                state.locale = payload;
                saveSettingsToLS(state);
            } else {
                state.localized.push({
                    showCookieAlert: true,
                    locale: payload
                })
                saveSettingsToLS(state);
            }
        },

        setShowCookieAlert: (state: TInterfaceStore, { payload }: PayloadAction<boolean>) => {
            const localized = state.localized.find(item => item.locale === state.locale);
            if (localized) localized.showCookieAlert = payload;
            saveSettingsToLS(state)
        },

        clearJWT: (state: TInterfaceStore) => { state.jwt = undefined; saveSettingsToLS(state) },

        setToasts: (state, { payload }: PayloadAction<TToast[]>) => {
            state.toasts = payload
        },

        setToastsStatus: (state, { payload }: PayloadAction<"notReceived" | "received">) => {
            state.toastsStatus = payload
        },

        initFormSchemeSet: (state, { payload }: PayloadAction<{ form: TForm, actives: TActiveStoreItems }>) => {
            const srcObject = payload.form.useActive
                ? payload.form.parentName && payload.form.parentField
                    ? (() => {
                        const parentField = _.get(payload.actives.object, `${payload.form.parentName}.${payload.form.parentField}`);
                        if (typeof parentField === "object" && !Array.isArray(parentField)) return parentField;
                    })()
                    : payload.actives.object[payload.form.responseApiName]
                : undefined
            const schemeSet = {
                ...constructFormSchemeGroup(payload.form.inputs, payload.form.formGroups, srcObject),
                object: srcObject ? { id: srcObject.id, apiName: payload.form.responseApiName } : undefined
            }
            state.formSchemes = { ...state.formSchemes, [payload.form.id]: schemeSet };
        },
        setFormSchemeValueItem: (state, { payload }: PayloadAction<{ formID: string, valueItem: TFormSchemeValue }>) => {
            const formSchemeSet = state.formSchemes[payload.formID];
            if (!formSchemeSet) return;
            const itemKey = formSchemeSet.values.findIndex(item => item.id === payload.valueItem.id && item.type === payload.valueItem.type);
            formSchemeSet.values.splice(itemKey, 1, payload.valueItem);
        },
        setFormSchemeValue: (state, { payload }: PayloadAction<{ formID: string, inputID: string, inputType: string, value?: TFormInputValue }>) => {
            const formSchemeSet = state.formSchemes[payload.formID];
            if (!formSchemeSet) return;
            const valueItem = formSchemeSet.values.find(item => item.id === payload.inputID && item.type === payload.inputType);
            if (!valueItem) return;
            valueItem.value = payload.value;
        },
        addFormSchemeGroup: (state, { payload }: PayloadAction<{ formID: string, formGroup: TFormGroup, path: string }>) => {
            const formSchemeSet = state.formSchemes[payload.formID];
            if (!formSchemeSet) return;
            const schemeGroupSet = _.get(formSchemeSet.scheme, payload.path) as TFormSchemeItem | undefined;
            if (!schemeGroupSet) return;
            const newGroupIndex = Number(schemeGroupSet.items?.length)
            const newGroupSet = constructFormSchemeGroup(
                payload.formGroup.inputs,
                payload.formGroup.formGroups,
                undefined,
                newGroupIndex.toString(),
                undefined,
                schemeGroupSet.formPath,
                payload.path
            )
            if (schemeGroupSet.asArray) {
                (schemeGroupSet.items as TFormSchemeItem[][]) = [...(schemeGroupSet.items as TFormSchemeItem[][]), newGroupSet.scheme]
            } else {
                (schemeGroupSet.items as TFormSchemeItem[]) = newGroupSet.scheme;
            }
            formSchemeSet.values = [...formSchemeSet.values, ...newGroupSet.values]
        },
        removeSchemeGroup: (state, { payload }: PayloadAction<{ formID: string, path: string }>) => {
            const formSchemeSet = state.formSchemes[payload.formID];
            if (!formSchemeSet) return;
            const schemeGroupSet = _.get(formSchemeSet.scheme, payload.path) as TFormSchemeItem | undefined;
            if (!schemeGroupSet) return;
            let inputIDs: string[] = [];
            if (schemeGroupSet.asArray && schemeGroupSet.items?.length) {
                inputIDs = (schemeGroupSet.items[schemeGroupSet.items.length - 1] as TFormSchemeItem[]).map(item => item.id);
                schemeGroupSet.items.splice(-1, 1);
            } else if (schemeGroupSet.items) {
                inputIDs = (schemeGroupSet.items as TFormSchemeItem[]).map(item => item.id);
                schemeGroupSet.items = undefined
            }
            formSchemeSet.values = formSchemeSet.values.filter(item => !inputIDs.find(id => id === item.id));
        },
        setInputValidate: (state, { payload }: PayloadAction<{ formID: string, inputID: string, validation?: TValidation, feedback?: string }>) => {
            const formSchemeSet = state.formSchemes[payload.formID];
            if (!formSchemeSet) return;
            const schemeValue = formSchemeSet.values.find(item => item.id === payload.inputID);
            if (!schemeValue) return;
            schemeValue.validation = payload.validation;
            schemeValue.feedback = payload.feedback;

        },
        cleanupValidates: (state, { payload }: PayloadAction<{ formID: string }>) => {
            const formSchemeSet = state.formSchemes[payload.formID];
            if (!formSchemeSet) return;
            formSchemeSet.values = formSchemeSet.values.map(item => ({ ...item, validation: item.value ? item.validation : undefined }));
        }
    },

    extraReducers: (builder) => {
        builder.addCase(setInitialState, () => initialState);
        builder.addCase(ifSettingsFetchingPending, (state) => { state.status = "progress" });
        builder.addCase(ifSettingsFetchingRejected, (state) => { state.status = "error" });
        builder.addCase(ifSettingsFetchingSucceed, (state, { payload }) => {
            state.locale = payload.locale;
            state.showBurgerMenu = payload.showBurgerMenu;
            state.showSidebar = payload.showSidebar;
            state.showHints = payload.showHints;
            state.jwt = payload.jwt;
            state.localized = payload.localized;
            state.status = "success"
        });

        builder.addCase(dataUpdateSucceed, (state, { payload }) => {
            if (payload.apiName === "login") {
                const user = Object.values(payload.collections.users || {})[0] as unknown as TUser & { jwt: string }
                if (user) {
                    state.jwt = user.jwt
                    saveSettingsToLS(state)
                }
            }
        });
    }
})

export const getShowSidebar = (state: RootState) => state.sliceIfSettings.showSidebar;
export const getShowBurgerMenu = (state: RootState) => state.sliceIfSettings.showBurgerMenu;
export const getShowUserMenu = (state: RootState, ID: string) => state.sliceIfSettings.showUserMenu[ID];
export const getShowHints = (state: RootState) => state.sliceIfSettings.showHints;
export const getShowModal = (state: RootState) => state.sliceIfSettings.showModal;
export const getModal = (state: RootState) => state.sliceIfSettings.modal;
export const getShowCookieAlert = (state: RootState) => state.sliceIfSettings.localized.find(item => item.locale === state.sliceIfSettings.locale)?.showCookieAlert
export const getCurrentLocale = (state: RootState) => state.sliceIfSettings.locale;

export const saveSettingsToLS = (state: TInterfaceStore) => {
    const settingsForSave = _.pick(state, ["locale", "showHints", "localized", "jwt", "showSidebar"]);
    localStorage.setItem("interfaceSettings", JSON.stringify(settingsForSave));
};
export const getIfSettingsFetchStatus = (state: RootState) => state.sliceIfSettings.status;
export const getJWT = (state: RootState) => state.sliceIfSettings.jwt;
export const toastSelector = (state: RootState): TToastsStore => ({ toasts: state.sliceIfSettings.toasts, toastsStatus: state.sliceIfSettings.toastsStatus });
export const getFormScheme = (state: RootState, formID: string): TFormSchemeItem[] | undefined => state.sliceIfSettings.formSchemes[formID]?.scheme;

export const getFormSchemeValue = (state: RootState, formID: string, inputD: string, inputType: string) => {
    const schemeSet = state.sliceIfSettings.formSchemes[formID];
    if (!schemeSet) return;
    return schemeSet.values.find(item => item.id === inputD && item.type === inputType);
}

export const getFormSchemeValues = (state: RootState, formID: string) => {
    const schemeSet = state.sliceIfSettings.formSchemes[formID];
    if (!schemeSet) return;
    return schemeSet.values;
}

export const getFormInput = (state: RootState, formID: string, path: string): TFormInput | undefined => {
    const formNorm = state.sliceData.collections.forms?.[formID];
    if (!formNorm) return
    const schema = schemaNormalize(`formsSingle`);
    const form = schema ? denormalize(formNorm, schema(), state.sliceData.collections) as TDataItem : undefined;
    return _.get(form, path) as TFormInput | undefined
}

export const getFormResult = (state: RootState, actives: TActiveStoreItems, form?: TForm,): object | undefined => {
    const formSet = form ? state.sliceIfSettings.formSchemes[form.id] : undefined;
    if (!formSet || !form) return;

    const srcObject = form.useActive
        ? form.parentName && form.parentField
            ? (() => {
                const parentField = _.get(actives.object, `${form.parentName}.${form.parentField}`);
                if (typeof parentField === "object" && !Array.isArray(parentField)) return parentField;
            })()
            : actives.object[form.responseApiName]
        : undefined
    return constructFormResult(form, actives, srcObject)
}

export const getFormState = (state: RootState, formID: string) => {
    const formSet = state.sliceIfSettings.formSchemes[formID];
    if (!formSet) return;
    const getGroup = (group: TFormSchemeItem[]): object => {
        return group.reduce((gr, gv, gk) => {
            switch (gv.type) {
                case "group":
                    return {
                        ...gr, [gv.name]: (() => {
                            if (gv.asArray) {
                                return (gv.items as TFormSchemeItem[][])
                                    .map((item, i) => getGroup(item))
                            } else {
                                return getGroup(gv.items as TFormSchemeItem[])
                            }
                        })()
                    }
                case "divider": return { ...gr }
                default:
                    const value = (formSet.values.find(item => item.id === gv.id && item.type === gv.type) as TFormSchemeValue | undefined)?.value;
                    return {
                        ...gr,
                        [gv.name]: value
                    }
            }
        }, {})
    }
    return getGroup(formSet.scheme)
}


export const {
    setShowSidebar,
    setShowBurgerMenu,
    setShowUserMenu,
    setShowHints,
    setShowModal,
    setModal,
    setShowCookieAlert,
    setSiteLocale,
    clearJWT,
    setToasts,
    setToastsStatus,
    initFormSchemeSet,
    setFormSchemeValue,
    addFormSchemeGroup,
    removeSchemeGroup,
    setInputValidate,
    cleanupValidates,
    setFormSchemeValueItem,
} = sliceIfSettings.actions;
export default sliceIfSettings.reducer;